import React from 'react';
import {itemReavealDuration} from "../../variables";
import Fade from "react-reveal/Fade";

export const Page404 = () => (
  <main className="main">
    <div className="container flex-nowrap main__theme_margin_top">
      <section className="grid__sidebar grid__sidebar-main">
      </section>
      <section className="grid__content grid__content-main">
        <Fade duration={itemReavealDuration} bottom>
          <h1>404</h1>
        </Fade>
      </section>
    </div>
  </main>
);

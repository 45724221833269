import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { Currency } from '../../partials/Currency'
import Carousel from '../../Carousel'
import Fade from 'react-reveal/Fade'
import { itemReavealDuration } from '../../variables'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useTranslation } from 'react-i18next'

export const AccessoryIntro = ({ data, partners, seo, locale }) => {
    const [isDownloading, setDownloading] = useState(false)
    const [t] = useTranslation();

    return (
        <section className="section-screen">
            <div className="section-screen-center">
                <Fade duration={itemReavealDuration} bottom>
                    <nav
                        className="breadcrumbs"
                        itemScope
                        itemType="https://schema.org/BreadcrumbList"
                    >
                        <Link
                            to="/"
                            className="breadcrumb-link"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemprop="name">{t('breadcrumbs.main')}</span>
                        </Link>
                        <Link
                            to="/products"
                            className="breadcrumb-link"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemprop="name">{t('breadcrumbs.products')}</span>
                        </Link>
                        <span
                            className="breadcrumb__current"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemprop="name">{data.name}</span>
                        </span>
                    </nav>
                    <h1 className="h1 h1__theme__inner">
                        {seo.h1 ? seo.h1 : data.name}
                    </h1>
                </Fade>
                {data.images && data.images.length ? (
                    <Images images={data.images} />
                ) : null}
                <div className="product-text-wrap">
                    <Fade duration={itemReavealDuration} bottom>
                        <p className="product-text__anons">
                            {data.description}
                        </p>

                        <span className="product-text__price">
                            <Currency
                                usdPrice={data.usdPrice}
                                rubPrice={data.rubPrice}
                                locale={locale}
                            />
                        </span>

                        <div className="product-btn-wrap">
                            <AnchorLink
                                href="#callbackSection"
                                className="btn btn__theme__link product-btn is-blue"
                            >
                                {t('requestQeote')}
                            </AnchorLink>

                            <button
                                className="btn btn__theme__link product-btn"
                                onClick={() => {}}
                            >
                                {isDownloading
                                    ? 'Downloading process'
                                    : t('accessory.catalog')}
                            </button>
                        </div>
                        <span className="product-team-anons">
                          {t('accessory.trustedBy')}
                        </span>
                        <div className="product-team-wrap">
                            {partners.map(PartnerIcon)}
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

const Images = ({ images }) => {
    return (
        <Fade duration={itemReavealDuration}>
            <Carousel
                className="carousel__theme__product"
                images={images}
                previews={true}
            />
        </Fade>
    )
}

const PartnerIcon = (item, index) => (
    <div className="product-team-logos" key={`partner-${index}`}>
        <ReactSVG
            src={item.icon}
            beforeInjection={(svg) =>
                svg.classList.add('product-team-logos__icon')
            }
        />
    </div>
)

import React from "react";

export const Logo = () => (
  <svg
    className="icon-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="111"
    height="20"
    viewBox="0 0 111 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#1E2A36">
        <path d="M31.218 5.467l-2.396 8.652a.848.848 0 0 1-.31.502.892.892 0 0 1-.568.188h-2.26a.903.903 0 0 1-.567-.188.848.848 0 0 1-.31-.502L22.41 5.467h2.613l1.435 6.377c.072.342.135.694.188 1.055h.34c.018-.18.082-.533.188-1.055l1.422-6.377h2.62zM34.886 14.809h-2.517V5.467h2.517z" />
        <path
          fillRule="nonzero"
          d="M42.202 9.923v.082c.703.064 1.21.297 1.517.703.308.406.461.998.461 1.775 0 .93-.252 1.567-.758 1.915-.507.347-1.35.52-2.53.52-1.868 0-3.282-.037-4.24-.109V5.467c.868-.072 2.136-.109 3.804-.109 1.281 0 2.17.17 2.669.507.496.34.745.981.745 1.928 0 .676-.13 1.186-.393 1.53-.265.345-.69.544-1.275.6zm-3.059-.732h1.247c.414 0 .695-.069.838-.21.143-.14.218-.416.218-.832 0-.406-.077-.674-.231-.807-.154-.13-.451-.196-.894-.196h-1.178V9.19zm0 3.926l1.3.013c.477 0 .796-.077.955-.23.156-.155.236-.462.236-.921 0-.496-.082-.825-.244-.99-.162-.161-.488-.244-.974-.244h-1.273v2.372zM53.485 14.809h-2.668l-.663-2.817c-.064-.27-.16-.456-.292-.554-.13-.098-.326-.149-.589-.149h-1.151v3.52h-2.504V5.467c.883-.09 2.143-.135 3.777-.135 1.345 0 2.303.204 2.87.615.568.411.854 1.143.854 2.199 0 1.371-.612 2.125-1.84 2.26v.082c.785.127 1.262.605 1.435 1.435l.771 2.886zm-5.36-5.374h1.217c.478 0 .801-.077.968-.23.167-.155.25-.452.25-.895s-.083-.74-.25-.894c-.167-.153-.49-.23-.968-.23h-1.217v2.249zM58.71 5.279c1.065 0 1.892.143 2.478.427.587.283 1.019.777 1.3 1.474.279.7.42 1.685.42 2.958 0 1.273-.141 2.26-.42 2.957-.281.7-.713 1.191-1.3 1.475-.586.284-1.413.427-2.477.427-1.57 0-2.66-.34-3.27-1.02-.61-.683-.916-1.961-.916-3.84 0-1.877.305-3.156.915-3.837.61-.682 1.7-1.021 3.27-1.021zm1.205 2.543c-.207-.398-.61-.597-1.204-.597-.597 0-.997.2-1.204.597-.207.398-.31 1.17-.31 2.316s.103 1.918.31 2.316c.207.397.61.596 1.204.596.597 0 .997-.199 1.204-.596.207-.398.31-1.17.31-2.316.003-1.146-.1-1.918-.31-2.316z"
        />
        <path d="M70.496 5.467v6.73c0 .387.212.58.637.58H74.3l.122 1.91c-.886.109-2.316.162-4.292.162-.695 0-1.23-.189-1.61-.563-.38-.374-.568-.872-.568-1.496V5.467h2.544z" />
        <path
          fillRule="nonzero"
          d="M81.7 12.589h-2.87l-.554 2.22h-2.6l2.56-8.679a.81.81 0 0 1 .305-.48.867.867 0 0 1 .55-.183h2.355c.207 0 .39.06.549.183a.87.87 0 0 1 .318.48l2.546 8.679h-2.6l-.559-2.22zm-.475-1.841l-.596-2.395c-.098-.47-.16-.793-.175-.976h-.366c-.064.342-.128.668-.189.976l-.597 2.395h1.923z"
        />
        <path d="M89.75 11.48l-1.867-.745c-.732-.297-1.249-.66-1.557-1.09-.307-.43-.461-.99-.461-1.685 0-1.048.265-1.753.798-2.12.533-.365 1.48-.548 2.844-.548 1.307 0 2.392.14 3.249.419l-.162 1.666c-1.597-.027-2.636-.04-3.114-.04-.432 0-.724.037-.873.109-.148.071-.222.23-.222.474 0 .207.063.37.188.48.127.115.36.229.703.345l1.719.663c.748.298 1.276.664 1.583 1.096.308.432.462 1.003.462 1.706 0 1.055-.265 1.782-.793 2.18-.528.398-1.483.597-2.865.597-1.191 0-2.334-.136-3.424-.406l.148-1.801c1.852.045 2.94.069 3.263.069.45 0 .75-.037.899-.114.148-.077.223-.234.223-.467 0-.218-.053-.374-.157-.475-.098-.1-.294-.207-.583-.313zM101.22 12.873l.122 1.827c-.886.098-2.427.149-4.631.149-.642 0-1.157-.183-1.544-.55-.387-.365-.58-.85-.58-1.455V7.43c0-.605.195-1.09.588-1.457.393-.366.905-.549 1.536-.549 2.201 0 3.745.05 4.631.149L101.22 7.4h-3.506c-.388 0-.581.193-.581.58V9.12h3.588v1.746h-3.588v1.421c0 .388.193.581.58.581h3.507v.006z" />
        <path
          fillRule="nonzero"
          d="M111.019 14.809h-2.669l-.663-2.817c-.064-.27-.16-.456-.292-.554-.13-.098-.326-.149-.589-.149h-1.15v3.52h-2.505V5.467c.883-.09 2.143-.135 3.777-.135 1.345 0 2.303.204 2.87.615.568.411.855 1.143.855 2.199 0 1.371-.613 2.125-1.841 2.26v.082c.785.127 1.262.605 1.435 1.435l.772 2.886zm-5.364-5.374h1.218c.477 0 .8-.077.968-.23.167-.155.25-.452.25-.895s-.083-.74-.25-.894c-.167-.153-.49-.23-.968-.23h-1.218v2.249z"
        />
      </g>
      <g fill="#2D67DF">
        <circle cx="10.289" cy="9.642" r="3.674" />
        <path d="M15.48 15.576a7.764 7.764 0 0 1-5.278 2.06 7.787 7.787 0 0 1-7.79-7.79c0-3.589 2.426-6.607 5.726-7.514L7.178 0C3.018 1.3 0 5.18 0 9.767 0 15.419 4.58 20 10.233 20c2.64 0 5.046-1 6.86-2.64l-1.613-1.784z" />
        <path d="M10.042 14.528a4.83 4.83 0 1 1 4.107-7.822l1.19-.918A6.342 6.342 0 1 0 9.945 15.99l.098-1.462z" />
      </g>
    </g>
  </svg>
);

export const LogoWhite = () => (
  <svg
    className="icon-logo__white"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 3340.7 366.5"
  >
    <g>
      <path d="M332.3,7.1l-90.3,326.2c-1.7,7.8-5.6,14.1-11.7,18.9c-6.1,4.8-13.3,7.1-21.4,7.1h-85.2 c-8.2,0-15.3-2.4-21.4-7.1c-6.1-4.8-10-11.1-11.7-18.9L0,7.1h98.5l54.1,240.4c2.7,12.9,5.1,26.2,7.1,39.8h12.8 c0.7-6.8,3.1-20.1,7.1-39.8L233.3,7.1H332.3z" />
      <path d="M470.5,359.3h-94.9V7.1h94.9V359.3z" />
      <path d="M746.4,175.1v3.1c26.5,2.4,45.6,11.2,57.2,26.5c11.6,15.3,17.4,37.6,17.4,66.9c0,35.1-9.5,59.1-28.6,72.2 c-19.1,13.1-50.9,19.6-95.4,19.6c-70.4,0-123.7-1.4-159.8-4.1V7.1c32.7-2.7,80.5-4.1,143.4-4.1c48.3,0,81.8,6.4,100.6,19.1 c18.7,12.8,28.1,37,28.1,72.7c0,25.5-4.9,44.7-14.8,57.7C784.5,165.5,768.5,173,746.4,175.1z M631,147.5h47 c15.6,0,26.2-2.6,31.6-7.9c5.4-5.3,8.2-15.7,8.2-31.4c0-15.3-2.9-25.4-8.7-30.4c-5.8-4.9-17-7.4-33.7-7.4H631V147.5z M631,295.5 l49,0.5c18,0,30-2.9,36-8.7c5.9-5.8,8.9-17.4,8.9-34.7c0-18.7-3.1-31.1-9.2-37.3c-6.1-6.1-18.4-9.2-36.7-9.2h-48V295.5z" />
      <path d="M1171.8,359.3h-100.6l-25-106.2c-2.4-10.2-6-17.2-11-20.9c-4.9-3.7-12.3-5.6-22.2-5.6h-43.4v132.7h-94.4V7.1 C908.6,3.7,956,2,1017.6,2c50.7,0,86.8,7.7,108.2,23.2c21.4,15.5,32.2,43.1,32.2,82.9c0,51.7-23.1,80.1-69.4,85.2v3.1 c29.6,4.8,47.6,22.8,54.1,54.1L1171.8,359.3z M969.7,156.7h45.9c18,0,30.2-2.9,36.5-8.7c6.3-5.8,9.4-17,9.4-33.7 c0-16.7-3.1-27.9-9.4-33.7c-6.3-5.8-18.5-8.7-36.5-8.7h-45.9V156.7z" />
      <path d="M1368.7,0c40.1,0,71.3,5.4,93.4,16.1c22.1,10.7,38.4,29.3,49,55.6c10.5,26.4,15.8,63.5,15.8,111.5 s-5.3,85.2-15.8,111.5c-10.6,26.4-26.9,44.9-49,55.6c-22.1,10.7-53.3,16.1-93.4,16.1c-59.2,0-100.3-12.8-123.3-38.5 c-23-25.7-34.5-73.9-34.5-144.7c0-70.8,11.5-119,34.5-144.7C1268.4,12.8,1309.5,0,1368.7,0z M1414.2,96c-7.8-15-23-22.5-45.4-22.5 s-37.6,7.5-45.4,22.5c-7.8,15-11.7,44.1-11.7,87.3c0,43.2,3.9,72.3,11.7,87.3c7.8,15,23,22.5,45.4,22.5s37.6-7.5,45.4-22.5 c7.8-15,11.7-44.1,11.7-87.3C1425.9,140,1422,110.9,1414.2,96z" />
      <path d="M1813,7.1v253.7c0,14.6,8,21.9,24,21.9h119.4l4.6,72c-33.4,4.1-87.3,6.1-161.8,6.1c-26.2,0-46.4-7.1-60.7-21.2 c-14.3-14.1-21.4-32.9-21.4-56.4V7.1H1813z" />
      <path d="M1813,7.1v253.7c0,14.6,8,21.9,24,21.9h119.4l4.6,72c-33.4,4.1-87.3,6.1-161.8,6.1c-26.2,0-46.4-7.1-60.7-21.2 c-14.3-14.1-21.4-32.9-21.4-56.4V7.1H1813z" />
      <path d="M2235.4,275.6h-108.2l-20.9,83.7h-98l96.5-327.2c1.7-7.5,5.5-13.5,11.5-18.1c5.9-4.6,12.8-6.9,20.7-6.9h88.8 c7.8,0,14.7,2.3,20.7,6.9c6,4.6,10,10.6,12,18.1l96,327.2h-98L2235.4,275.6z M2217.5,206.2l-22.5-90.3c-3.7-17.7-6-29.9-6.6-36.8 h-13.8c-2.4,12.9-4.8,25.2-7.1,36.8l-22.5,90.3H2217.5z" />
      <path d="M2539,233.8l-70.4-28.1c-27.6-11.2-47.1-24.9-58.7-41.1c-11.6-16.2-17.4-37.3-17.4-63.5 c0-39.5,10-66.1,30.1-79.9c20.1-13.8,55.8-20.7,107.2-20.7c49.3,0,90.2,5.3,122.5,15.8l-6.1,62.8c-60.2-1-99.4-1.5-117.4-1.5 c-16.3,0-27.3,1.4-32.9,4.1c-5.6,2.7-8.4,8.7-8.4,17.9c0,7.8,2.4,13.9,7.1,18.1c4.8,4.3,13.6,8.6,26.5,13l64.8,25 c28.2,11.2,48.1,25,59.7,41.3c11.6,16.3,17.4,37.8,17.4,64.3c0,39.8-10,67.2-29.9,82.2c-19.9,15-55.9,22.5-108,22.5 c-44.9,0-88-5.1-129.1-15.3l5.6-67.9c69.8,1.7,110.8,2.6,123,2.6c17,0,28.3-1.4,33.9-4.3c5.6-2.9,8.4-8.8,8.4-17.6 c0-8.2-2-14.1-5.9-17.9C2557.3,241.8,2549.9,237.9,2539,233.8z" />
      <path d="M2971.4,286.3l4.6,68.9c-33.4,3.7-91.5,5.6-174.6,5.6c-24.2,0-43.6-6.9-58.2-20.7 c-14.6-13.8-21.9-32.1-21.9-54.9V81.2c0-22.8,7.4-41.1,22.2-54.9c14.8-13.8,34.1-20.7,57.9-20.7c83,0,141.2,1.9,174.6,5.6 l-4.6,68.9h-132.2c-14.6,0-21.9,7.3-21.9,21.9V145h135.3v65.8h-135.3v53.6c0,14.6,7.3,21.9,21.9,21.9H2971.4z" />
      <path d="M3340.7,359.3h-100.6l-25-106.2c-2.4-10.2-6-17.2-11-20.9c-4.9-3.7-12.3-5.6-22.2-5.6h-43.4v132.7h-94.4V7.1 C3077.5,3.7,3125,2,3186.6,2c50.7,0,86.8,7.7,108.2,23.2c21.4,15.5,32.2,43.1,32.2,82.9c0,51.7-23.1,80.1-69.4,85.2v3.1 c29.6,4.8,47.6,22.8,54.1,54.1L3340.7,359.3z M3138.6,156.7h45.9c18,0,30.2-2.9,36.5-8.7c6.3-5.8,9.4-17,9.4-33.7 c0-16.7-3.1-27.9-9.4-33.7c-6.3-5.8-18.5-8.7-36.5-8.7h-45.9V156.7z" />
    </g>
  </svg>
);

export const Burger = () => (
  <svg
    className="icon-burger"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="11"
    viewBox="0 0 22 11"
  >
    <line className="icon-burger__line1" x1="0" y1="0" x2="22" y2="0" />
    <line className="icon-burger__line2" x1="0" y1="11" x2="22" y2="11" />
    <line className="icon-burger__line3" x1="0" y1="0" x2="22" y2="0" />
    <line className="icon-burger__line4" x1="0" y1="11" x2="22" y2="11" />
  </svg>
);

export const Marker = () => (
  <svg
    className="icon-marker"
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="8"
    viewBox="0 0 6 8"
  >
    <path
      fill="#000"
      fillRule="nonzero"
      d="M3 0C1.346 0 0 1.3 0 2.897 0 4.88 2.685 7.791 2.799 7.913a.277.277 0 0 0 .402 0C3.315 7.791 6 4.88 6 2.897 6 1.3 4.654 0 3 0zm0 4.355c-.832 0-1.51-.654-1.51-1.458 0-.803.678-1.457 1.51-1.457s1.51.654 1.51 1.457c0 .804-.678 1.458-1.51 1.458z"
    />
  </svg>
);

export const Bag = () => (
  <svg
    className="icon-bag"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
  >
    <path
      fill="#1E2A36"
      fillRule="nonzero"
      d="M14.991 17.248L13.888 5.05a.649.649 0 0 0-.647-.588h-2.275V3.443C10.966 1.545 9.411 0 7.5 0 5.588 0 4.033 1.545 4.033 3.443v1.019H1.758a.649.649 0 0 0-.648.588L.003 17.296c-.017.18.044.36.167.494.124.134.298.21.48.21H14.35a.648.648 0 0 0 .641-.752zM5.334 3.443A2.16 2.16 0 0 1 7.5 1.293a2.16 2.16 0 0 1 2.165 2.15v1.019h-4.33V3.443zM1.362 16.708l.99-10.954h1.681V6.91c0 .357.292.647.65.647.36 0 .651-.29.651-.647V5.754h4.331V6.91c0 .357.292.647.65.647.36 0 .651-.29.651-.647V5.754h1.68l.99 10.954H1.362z"
    />
  </svg>
);

export const Plus = () => (
  <svg
    className="icon-plus"
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <path d="M19 11h1.5v17H19z" />
      <path d="M28.25 18.75v1.5h-17v-1.5z" />
      <circle cx="19.5" cy="19.5" r="19.5" />
    </g>
  </svg>
);

export const Triangle = () => (
  <svg
    className="icon-dropdown"
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="3"
    viewBox="0 0 5 3"
  >
    <path fillRule="evenodd" d="M2.5 3L0 0h5z" />
  </svg>
);

export const Facebook = () => (
  <svg
    className="icon-fb"
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="12"
    viewBox="0 0 6 12"
  >
    <path
      fillRule="evenodd"
      d="M5.774.002L4.334 0c-1.616 0-2.66 1.095-2.66 2.79v1.285H.225A.229.229 0 0 0 0 4.306V6.17c0 .127.101.23.226.23h1.447v4.702c0 .128.102.231.227.231h1.887a.229.229 0 0 0 .227-.23V6.4h1.692a.229.229 0 0 0 .226-.231V4.306a.234.234 0 0 0-.066-.163.224.224 0 0 0-.16-.068H4.014v-1.09c0-.524.122-.79.79-.79h.97A.229.229 0 0 0 6 1.964V.234a.229.229 0 0 0-.226-.232z"
    />
  </svg>
);

export const LinkedIn = () => (
  <svg
    className="icon-linkedin"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="11"
    viewBox="0 0 12 11"
  >
    <path
      fillRule="nonzero"
      d="M12 6.744V11H9.428V7.03c0-.998-.372-1.68-1.303-1.68-.71 0-1.134.46-1.32.903-.067.16-.085.38-.085.602V11H4.147s.035-6.725 0-7.422H6.72V4.63l-.017.024h.017V4.63c.342-.505.952-1.227 2.318-1.227C10.731 3.403 12 4.464 12 6.744zM1.456 0C.576 0 0 .554 0 1.282c0 .713.559 1.283 1.422 1.283h.017c.897 0 1.455-.57 1.455-1.283C2.877.554 2.336 0 1.456 0zM.153 11h2.572V3.578H.153V11z"
    />
  </svg>
);

export const Youtube = () => (
  <svg
    className="icon-youtube"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M7.557 9A.554.554 0 0 1 7 8.444V4.556a.555.555 0 0 1 .806-.497l3.887 1.945a.554.554 0 0 1 0 .992L7.806 8.941a.553.553 0 0 1-.25.059z"
      />
      <rect
        width="16.5"
        height="11.5"
        x=".75"
        y=".75"
        strokeWidth="1.5"
        rx="3"
      />
    </g>
  </svg>
);

export const Twitter = () => (
  <svg
    className="icon-tw"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 13 11"
  >
    <path
      fillRule="evenodd"
      d="M13 1.259a5.326 5.326 0 0 1-1.531.42c.55-.33.972-.853 1.172-1.475a5.376 5.376 0 0 1-1.695.648 2.667 2.667 0 0 0-4.544 2.431A7.571 7.571 0 0 1 .906.497c-.23.393-.36.851-.36 1.34 0 .925.47 1.742 1.185 2.22a2.667 2.667 0 0 1-1.208-.335v.033c0 1.292.92 2.37 2.14 2.615-.224.06-.46.093-.704.093a2.56 2.56 0 0 1-.502-.05 2.668 2.668 0 0 0 2.491 1.853 5.35 5.35 0 0 1-3.312 1.14c-.215 0-.427-.013-.636-.037a7.538 7.538 0 0 0 4.088 1.2c4.906 0 7.587-4.063 7.587-7.587l-.009-.345A5.325 5.325 0 0 0 13 1.259z"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    className="icon-instagram"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g fill="none" fillRule="evenodd">
      <rect
        width="11.5"
        height="11.5"
        x=".75"
        y=".75"
        strokeWidth="1.5"
        rx="3"
      />
      <circle cx="6.5" cy="6.5" r="2.5" strokeWidth="1.4" />
    </g>
  </svg>
);

export const Eye = () => (
  <svg
    className="icon-eye"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="9"
    viewBox="0 0 13 9"
  >
    <g fill="#000" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M6.5 0C3.633 0 1.161 1.79.026 4.375a.311.311 0 0 0 0 .25C1.161 7.209 3.633 9 6.5 9s5.339-1.79 6.474-4.375a.311.311 0 0 0 0-.25C11.839 1.791 9.367 0 6.5 0zm0 7.605c-1.62 0-2.93-1.39-2.93-3.104 0-1.716 1.312-3.105 2.93-3.105 1.62 0 2.93 1.39 2.93 3.105 0 1.714-1.312 3.104-2.93 3.104z"
      />
      <ellipse cx="6.5" cy="4.5" rx="2.167" ry="2.25" />
    </g>
  </svg>
);

export const Star = () => (
  <svg
    className="icon-star"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
  >
    <path
      fill="#1E2A36"
      fillRule="evenodd"
      d="M12.718 5.438a.87.87 0 0 0 .236-.92.918.918 0 0 0-.753-.612l-3.148-.44a.41.41 0 0 1-.31-.218L7.336.501A.93.93 0 0 0 6.5 0a.93.93 0 0 0-.836.5L4.257 3.25a.41.41 0 0 1-.31.217l-3.148.44a.918.918 0 0 0-.753.612.87.87 0 0 0 .236.92l2.278 2.14a.388.388 0 0 1 .118.35l-.537 3.02a.857.857 0 0 0 .203.729.967.967 0 0 0 1.15.218l2.815-1.426a.436.436 0 0 1 .383 0l2.815 1.426a.95.95 0 0 0 1.149-.218.856.856 0 0 0 .203-.728l-.537-3.02a.388.388 0 0 1 .118-.352l2.278-2.139z"
    />
  </svg>
);

export const Play = () => (
  <svg
    className="icon-play"
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="19.5"
        cy="19.5"
        r="19.5"
        stroke="#FFF"
        transform="translate(1 1)"
      />
      <path fill="#FFF" d="M27.538 21l-10 6.538V14.462z" />
    </g>
  </svg>
);

export const Close = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <g
      id="01-02-02-video-pop-up"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-1393.000000, -33.000000)"
    >
      <g id="Group-9" transform="translate(1376.000000, 16.000000)">
        <circle
          id="Oval"
          fill="#262626"
          opacity="0"
          cx="24"
          cy="24"
          r="24"
        ></circle>
        <g
          id="ic_close_black_24px-(3)"
          transform="translate(12.000000, 12.000000)"
        >
          <polygon id="Shape" points="0 0 24 0 24 25 0 25"></polygon>
          <polygon
            id="Shape"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export const Download = () => (
  <svg
    className="icon-download"
    width="13px"
    height="14px"
    viewBox="0 0 13 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="03.01_product-card_1400"
        transform="translate(-989.000000, -2072.000000)"
        fill="#2D67DF"
      >
        <g id="download" transform="translate(989.000000, 2072.000000)">
          <path
            d="M10.3147827,6.13042711 L6.53370858,10.0391422 L2.74270868,6.125 L1.74107143,7.01464747 L5.56978922,10.9676993 C6.09732735,11.5123727 6.97261928,11.5104717 7.50150219,10.963744 L11.3169643,7.01949201 L10.3147827,6.13042711 Z"
            id="Path"
          ></path>
          <rect
            id="Rectangle"
            x="5.57142857"
            y="0"
            width="1.74107143"
            height="10.5583333"
          ></rect>
          <rect
            id="Rectangle"
            x="0"
            y="12.25"
            width="12.1875"
            height="1.75"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
);

export const SoftwareIconVA = () => (
  <svg
    className="icon-software icon-software-va"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <g fill="#4263DE">
        <path d="M11.534 18.693h11.733v2.983H11.534z" />
        <path d="M18.892 20.185v7.159a1.492 1.492 0 0 1-2.983 0v-7.16a1.492 1.492 0 0 1 2.983 0z" />
        <g>
          <path d="M23.188 17.545l-11.555-2.037.518-2.938 11.555 2.037z" />
          <path d="M16.201 14.797l1.243-7.05a1.491 1.491 0 1 1 2.937.519l-1.242 7.05a1.491 1.491 0 1 1-2.937-.519z" />
        </g>
      </g>
    </g>
  </svg>
);
export const SoftwareIconHA = () => (
  <svg
    className="icon-software icon-software-ha"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <g fill="#4263DE">
        <path d="M16.307 11.534v11.733h-2.983V11.534z" />
        <path d="M14.815 18.892H7.656a1.492 1.492 0 0 1 0-2.983h7.16a1.492 1.492 0 0 1 0 2.983z" />
        <g>
          <path d="M17.454 23.188l2.037-11.555 2.937.518-2.036 11.555z" />
          <path d="M20.203 16.201l7.05 1.243a1.491 1.491 0 1 1-.519 2.937l-7.05-1.242a1.491 1.491 0 1 1 .519-2.937z" />
        </g>
      </g>
    </g>
  </svg>
);
export const SoftwareIconSoftfoot = () => (
  <svg
    className="icon-software icon-software-sf"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#4263DE"
        d="M13.883 15.997h3.58v1.997h-3.58zM14.12 25.256v-2.387h1.988v2.387zM5.17 27.25v-1.99h23.466v1.99zM12.602 16.001h1v1.989h-1z"
      />
      <g fill="#4263DE">
        <path d="M9.96 18.495h12.3v3.58H9.96z" />
        <path d="M26.308 7.897l-7.151 12.387 3.1 1.79 6.157-10.665a1.99 1.99 0 0 0-.728-2.717l-1.378-.795z" />
      </g>
    </g>
  </svg>
);
export const SoftwareIconMT = () => (
  <svg
    className="icon-software icon-software-mt"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <g fill="#4263DE">
        <path d="M23.781 14.082v6.785h-1.726v-6.785z" />
        <path d="M22.949 18.337h-4.202a.83.83 0 0 1-.831-.831v-.062a.83.83 0 0 1 .83-.83h4.203a.83.83 0 0 1 .831.83v.062a.832.832 0 0 1-.831.831z" />
        <g>
          <path d="M24.443 20.82l1.178-6.682 1.7.3-1.178 6.681z" />
          <path d="M26.003 16.774l4.139.73c.453.08.753.511.674.964l-.01.06a.831.831 0 0 1-.965.674l-4.138-.73a.831.831 0 0 1-.674-.964l.01-.06a.833.833 0 0 1 .964-.674z" />
        </g>
        <g>
          <path d="M11.045 20.878v-6.785h1.73v6.785z" />
          <path d="M11.876 16.623h4.222a.83.83 0 0 1 .831.831v.062a.83.83 0 0 1-.831.831h-4.222a.83.83 0 0 1-.831-.831v-.062a.83.83 0 0 1 .831-.831z" />
          <g>
            <path d="M7.491 20.523l1.181-6.684 1.705.3-1.182 6.682z" />
            <path d="M8.784 18.18l-4.09-.72a.862.862 0 0 1-.703-.998.866.866 0 0 1 1.002-.7l4.091.72a.862.862 0 0 1 .702.998.866.866 0 0 1-1.002.7z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const SoftwareIconCM = () => (
  <svg
    className="icon-software icon-software-cm"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <circle cx="17.5" cy="17.45" r="9.51" fill="#4263DE" />
      <path
        className="icon-software__white-path"
        d="M7.99 17.325h19.019v1H7.99z"
      />
      <path
        className="icon-software__white-path"
        d="M17.375 26.96V7.94h.25v19.02z"
      />
      <g transform="translate(15.71 6.165)">
        <circle cx="1.79" cy="1.776" r="1.627" fill="#4263DE" />
        <circle
          cx="1.79"
          cy="1.776"
          r="1.126"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(15.71 25.256)">
        <circle cx="1.79" cy="1.704" r="1.627" fill="#4263DE" />
        <circle
          cx="1.79"
          cy="1.704"
          r="1.126"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(6.364 15.71)">
        <circle cx="1.627" cy="1.74" r="1.627" fill="#4263DE" />
        <circle
          cx="1.627"
          cy="1.74"
          r="1.126"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(25.256 15.71)">
        <circle cx="1.754" cy="1.74" r="1.627" fill="#4263DE" />
        <circle
          cx="1.754"
          cy="1.74"
          r="1.126"
          className="icon-software__white-path"
        />
      </g>
    </g>
  </svg>
);
export const SoftwareIconPM = () => (
  <svg
    className="icon-software icon-software-pm"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <circle cx="17.5" cy="17.5" r="9.555" fill="#4263DE" />
      <g transform="translate(6.563 16.108)">
        <circle cx="1.382" cy="1.392" r="1.382" fill="#4263DE" />
        <circle
          cx="1.382"
          cy="1.392"
          r="1"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(25.653 16.108)">
        <circle cx="1.402" cy="1.392" r="1.382" fill="#4263DE" />
        <circle
          cx="1.402"
          cy="1.392"
          r="1"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(16.108 6.563)">
        <circle cx="1.392" cy="1.382" r="1.382" fill="#4263DE" />
        <circle
          cx="1.392"
          cy="1.382"
          r="1"
          className="icon-software__white-path"
        />
      </g>
      <g transform="translate(16.108 25.653)">
        <circle cx="1.392" cy="1.402" r="1.382" fill="#4263DE" />
        <circle
          cx="1.392"
          cy="1.402"
          r="1"
          className="icon-software__white-path"
        />
      </g>
      <g>
        <g transform="translate(9.347 22.87)">
          <circle cx="1.398" cy="1.386" r="1.382" fill="#4263DE" />
          <circle
            cx="1.398"
            cy="1.386"
            r="1"
            className="icon-software__white-path"
          />
        </g>
        <g transform="translate(22.87 9.347)">
          <circle cx="1.386" cy="1.398" r="1.382" fill="#4263DE" />
          <circle
            cx="1.386"
            cy="1.398"
            r="1"
            className="icon-software__white-path"
          />
        </g>
        <g transform="translate(9.347 9.347)">
          <circle cx="1.398" cy="1.398" r="1.382" fill="#4263DE" />
          <circle
            cx="1.398"
            cy="1.398"
            r="1"
            className="icon-software__white-path"
          />
        </g>
        <g transform="translate(22.87 22.87)">
          <circle cx="1.386" cy="1.386" r="1.382" fill="#4263DE" />
          <circle
            cx="1.386"
            cy="1.386"
            r="1"
            className="icon-software__white-path"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const SoftwareIconCutangle = () => (
  <svg
    className="icon-software icon-software-cut"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <circle cx="17.5" cy="17.5" r="9.545" fill="#FFA324" />
      <path
        className="icon-software__yellow-path"
        d="M17.5 17.75l5.934-6.39A8.518 8.518 0 0 0 17.5 8.958c-2.366 0-4.51.964-6.055 2.52L17.5 17.75z"
      />
    </g>
  </svg>
);
export const SoftwareIconTGC = () => (
  <svg
    className="icon-software icon-software-tgc"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g fill="none" fillRule="nonzero">
      <path
        className="icon-software__white-path"
        d="M27.153 33.011H7.847a5.859 5.859 0 0 1-5.858-5.858V7.847A5.859 5.859 0 0 1 7.847 1.99h19.306a5.859 5.859 0 0 1 5.858 5.858v19.306a5.859 5.859 0 0 1-5.858 5.858z"
      />
      <circle cx="17.5" cy="17.5" r="9.545" fill="#8A99A3" />
      <path
        className="icon-software__gray-path"
        d="M17.5 17.75l5.934-6.39A8.518 8.518 0 0 0 17.5 8.958c-2.366 0-4.51.964-6.055 2.52L17.5 17.75z"
      />
    </g>
  </svg>
);
export const VLSATSystem = () => (
  <svg
    className="icon-system"
    xmlns="http://www.w3.org/2000/svg"
    width="295"
    height="197"
    viewBox="0 0 295 197"
  >
    <g fill="none" fillRule="evenodd">
      <path stroke="#333" d="M42 186h252v29H42z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M81.979 131.782l-46-14.782L0 149.177l10.104 47.041 46 14.782L92 178.741l-10.021-46.96zm-43.536 55.534c-12.979-4.165-20.043-17.967-15.854-30.87 4.19-12.904 18.072-19.927 31.05-15.762 12.979 4.165 20.043 17.967 15.854 30.87-4.107 12.904-18.072 19.927-31.05 15.762z"
      />
      <path
        stroke="#333"
        d="M83.896 130.782l-46-14.782L2 148.177l10.104 47.041 46 14.782L94 177.741l-10.104-46.96zm-43.453 55.534c-12.979-4.165-20.043-17.967-15.854-30.87 4.19-12.904 18.072-19.927 31.05-15.762 12.979 4.165 20.043 17.967 15.854 30.87-4.19 12.904-18.072 19.927-31.05 15.762z"
      />
      <path fill="#FFF" d="M128 42h80v80h-80z" />
      <path stroke="#333" d="M125 39h80v80h-80z" />
      <path stroke="#505050" d="M228.89 43H107.11L100 20h136z" />
      <path
        fill="#FFF"
        d="M269 6.267C269 13.852 262.644 20 254.801 20H81.2C73.439 20 67 13.852 67 6.267V-6.267C67-13.772 73.356-20 81.199-20H254.8C262.561-20 269-13.852 269-6.267V6.267z"
      />
      <path
        stroke="#333"
        d="M269 4.267C269 11.852 262.644 18 254.801 18H81.2C73.439 18 67 11.852 67 4.267V-8.267C67-15.772 73.356-22 81.199-22H254.8C262.561-22 269-15.852 269-8.267V4.267z"
      />
      <path fill="#FFF" d="M175.027 176h-13.973L147 159h42z" />
      <path
        stroke="#333"
        d="M174.946 177h-13.973L147 158h42zM193.844 157h-51.77L128 122h80z"
      />
      <path fill="#FFF" stroke="#333" d="M162 186h11v29h-11z" />
      <path
        stroke="#333"
        d="M271.954-79.419h-23.037v-38.27h47.23V-166H168v339h7.018l12.468-16.296h6.44l14.202-35.307v-80.08h20.643l6.357-21.233h19.652c7.761 0 14.202-6.337 14.202-14.156V-6.993c0-7.737-6.358-14.156-14.202-14.156h-5.945v-42.88h23.12v20.74H303v-56.952h-31.046v20.822z"
        opacity=".15"
      />
    </g>
  </svg>
);

export const Step = () => (
  <svg
    className="icon-distributors icon-step"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="49"
    viewBox="0 0 40 49"
  >
    <path
      fill="#000"
      fillRule="nonzero"
      d="M39.292 19.946H27.944c-1.225 0-2.221 1.01-2.221 2.253v6.953h-2.367v-3.885c0-1.242-.997-2.252-2.221-2.252h-3.066a.798.798 0 0 1-.623-.3l-1.58-1.956 12.67-19.648a.724.724 0 0 0-.205-.994.702.702 0 0 0-.98.208L19.623 12.31 14.701 8.07l3.29-7.044a.722.722 0 0 0-.336-.956.704.704 0 0 0-.943.342L9.956 14.874 5.06 19.84a2.277 2.277 0 0 0 0 3.185l5.923 6.005a5.206 5.206 0 0 0 2.14 1.317 2.26 2.26 0 0 0-.262 1.058v6.955H2.221C.996 38.359 0 39.369 0 40.61v7.671c0 .396.317.718.708.718.39 0 .708-.322.708-.718v-7.671c0-.45.361-.817.805-.817h11.348c.391 0 .708-.321.708-.718v-7.672c0-.45.361-.816.805-.816h11.349c.39 0 .708-.322.708-.718V22.2c0-.45.36-.817.805-.817h11.348c.39 0 .708-.321.708-.718a.713.713 0 0 0-.708-.718zm-27.918-4.755l1.342-2.871 4.423 3.844-2.212 3.43-3.553-4.403zm7.468-1.67l-.922 1.43-4.576-3.977.73-1.562 4.768 4.11zm-4.149 15.631a3.78 3.78 0 0 1-2.71-1.138L6.062 22.01a.825.825 0 0 1 0-1.155l.7-.709 6.621 6.715c.42.425.977.66 1.57.66h3.156a.713.713 0 0 0 .708-.718.713.713 0 0 0-.708-.718h-3.155a.796.796 0 0 1-.57-.24L7.76 19.13l2.73-2.767 5.86 7.262a2.2 2.2 0 0 0 1.718.826h3.066c.444 0 .805.366.805.816v.817h-.806a.713.713 0 0 0-.707.718c0 .396.316.718.707.718h.806v1.632h-7.247z"
    />
  </svg>
);

export const Solar = () => (
  <svg
    className="icon-distributors icon-solar"
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="49"
    viewBox="0 0 49 49"
  >
    <g fill="#000" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M25.22 41.794c-9.138 0-16.573-7.435-16.573-16.573 0-9.139 7.435-16.574 16.574-16.574 9.138 0 16.573 7.435 16.573 16.574 0 9.138-7.435 16.573-16.573 16.573zm0-31.49c-8.224 0-14.916 6.692-14.916 14.917 0 8.224 6.692 14.916 14.917 14.916 8.224 0 14.916-6.692 14.916-14.916 0-8.225-6.692-14.917-14.916-14.917z"
      />
      <path d="M25.22 49c-.397 0-.72-.404-.72-.9v-5.405c0-.498.323-.9.72-.9.398 0 .721.402.721.9v5.404c0 .497-.323.901-.72.901zM25.22 7.206c-.397 0-.72-.403-.72-.9V.9c0-.497.323-.901.72-.901.398 0 .721.404.721.9v5.405c0 .498-.323.9-.72.9zM6.305 25.941H.901c-.497 0-.901-.323-.901-.72 0-.398.404-.721.9-.721h5.405c.498 0 .9.323.9.72 0 .398-.402.721-.9.721zM48.1 25.941h-5.405c-.498 0-.9-.323-.9-.72 0-.398.402-.721.9-.721h5.404c.497 0 .901.323.901.72 0 .398-.404.721-.9.721z" />
      <path
        fillRule="nonzero"
        d="M20.497 37.47a.784.784 0 0 1-.363-.088.823.823 0 0 1-.417-.912l2.175-9.616h-3.797a.8.8 0 0 1-.728-.477.828.828 0 0 1 .123-.874l10.408-12.25a.79.79 0 0 1 .968-.194c.328.17.5.547.417.912l-2.175 9.616h3.797a.8.8 0 0 1 .728.477c.13.29.083.632-.123.874l-10.408 12.25a.791.791 0 0 1-.605.283zm-.648-12.25h3.05c.244 0 .474.114.626.308a.83.83 0 0 1 .154.693L22 33.636l7.15-8.416h-3.05a.796.796 0 0 1-.627-.308.833.833 0 0 1-.153-.692l1.678-7.416-7.15 8.416z"
      />
      <path d="M40.87 41.794a.922.922 0 0 1-.652-.27L36.3 37.606a.923.923 0 1 1 1.306-1.306l3.917 3.918a.923.923 0 0 1-.653 1.576zM12.047 12.97a.921.921 0 0 1-.653-.27L7.477 8.782a.923.923 0 1 1 1.305-1.305l3.918 3.917a.922.922 0 0 1-.653 1.577zM8.13 41.794a.923.923 0 0 1-.653-1.576l3.917-3.918a.922.922 0 1 1 1.306 1.306l-3.918 3.918a.922.922 0 0 1-.652.27zM36.953 12.97a.923.923 0 0 1-.653-1.576l3.918-3.917a.923.923 0 1 1 1.305 1.305L37.606 12.7a.92.92 0 0 1-.653.27z" />
    </g>
  </svg>
);

export const Chart = () => (
  <svg
    className="icon-distributors icon-chart"
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="41"
    viewBox="0 0 39 41"
  >
    <g fill="#000" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M38.196 39.395H36.43v-25.55c0-1.19-.97-2.157-2.162-2.157h-3.944a2.162 2.162 0 0 0-2.163 2.157v25.55h-4.48V25.421c0-1.19-.97-2.157-2.162-2.157h-3.944a2.162 2.162 0 0 0-2.163 2.157v13.974h-4.48v-7.237c0-1.19-.97-2.157-2.163-2.157H4.827a2.162 2.162 0 0 0-2.163 2.157v7.237H.804a.803.803 0 1 0 0 1.605h37.392a.803.803 0 1 0 0-1.605zm-28.872 0H4.273v-7.237c0-.304.248-.552.554-.552H8.77c.306 0 .554.248.554.552v7.237zm12.75 0h-5.052V25.421c0-.304.248-.552.554-.552h3.943c.306 0 .554.248.554.552v13.974zm12.748 0h-5.05v-25.55c0-.305.248-.553.553-.553h3.944c.305 0 .553.248.553.553v25.55z"
      />
      <path d="M36.386.84a.792.792 0 0 0-.235-.606.81.81 0 0 0-.613-.232L35.498 0H30.61a.804.804 0 0 0-.808.8c0 .441.362.8.808.8h3.02l-7.99 7.904-3.013-2.981a.813.813 0 0 0-1.143 0L6.954 20.898a.794.794 0 0 0 0 1.13.81.81 0 0 0 1.143 0L22.054 8.22l3.013 2.981a.814.814 0 0 0 1.143 0l8.561-8.47v2.704c0 .441.362.8.808.8a.804.804 0 0 0 .809-.8V.88l-.002-.04z" />
      <ellipse cx="4.945" cy="24.21" rx="1" ry="1" />
    </g>
  </svg>
);

export const AboutIcon = () => (
  <svg
    className="icon-about"
    xmlns="http://www.w3.org/2000/svg"
    width="459"
    height="197"
    viewBox="0 0 459 197"
  >
    <defs>
      <linearGradient id="a" x1="81.843%" x2="36.26%" y1="96.754%" y2="15.043%">
        <stop offset="0%" stopColor="gray" stopOpacity=".25" />
        <stop offset="54%" stopColor="gray" stopOpacity=".12" />
        <stop offset="100%" stopColor="gray" stopOpacity=".1" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#033"
        d="M105.249-88.635l281.25 2.63-2.883 389.351-281.25-2.631z"
      />
      <path
        fill="#ECF2F8"
        stroke="#333"
        d="M119.266 94.813l55.832.522-.413 55.738-55.831-.522z"
      />
      <path
        fill="#ECF2F8"
        d="M212.604-5.144l76.473.715-.04 5.356-76.472-.715zM212.56 2.813l126.303 1.182-.04 5.355L212.52 8.17zM212.413 10.856l117.392 1.098-.04 5.356-117.391-1.098z"
      />
      <path
        fill="#E0E0E0"
        d="M211.667 112.894l76.473.715-.04 5.356-76.473-.715zM211.624 120.85l126.304 1.182-.04 5.356-126.303-1.182zM211.567 128.895l117.392 1.098-.04 5.356-117.392-1.098z"
      />
      <path
        fill="url(#a)"
        d="M140.37 187.501l13.503 4.025 4.533-23.72 6.61 1.712-7.46 29.287-20.49-8.135z"
        transform="rotate(25 556.643 79.481)"
      />
      <path
        fill="#FFF"
        d="M134.53-.066l9.933 8.568L157.26-9.144l4.98 3.896-17.545 21.139-14.08-14.732z"
      />
      <path
        fill="#2D67DF"
        d="M131.45 123.897l9.895 8.648 12.835-17.727 4.942 3.978-17.508 21.057-14.116-14.65z"
      />
      <path
        stroke="#333"
        d="M129.454 120.9l9.896 8.648 12.926-17.685 4.851 3.935-17.416 21.1-14.208-14.693z"
      />
      <path
        fill="#ECF2F8"
        stroke="#333"
        d="M361.454 158.556l-63.461 19.228-4.475-81.394 88.963 17.074z"
      />
      <path
        fill="#FFF"
        d="M142.34-5.954s14.078 23.432 109.327 26.238l48.203 87.488 33.532 44.795c5.213 7.07 4.709 16.994-1.562 23.06-3.79 3.746-9.036 5.37-15.507.184 0 0-2.407 27.516-29.119 14.124 0 0-13.05 21.058-34.245 8.15 0 0-.968 11.06-17.119 4.029-16.15-7.031-98.599-86.37-98.599-86.37l-36.386-30.91L142.34-5.954z"
      />
      <path
        stroke="#333"
        d="M145.422-13.218s14.085 23.431 109.37 26.24l48.223 87.49 33.545 44.797c5.216 7.071 4.712 16.994-1.561 23.06-3.792 3.746-9.039 5.37-15.513.183 0 0-2.407 27.516-29.129 14.124 0 0-13.054 21.057-34.258 8.148 0 0-.967 11.06-17.125 4.029-16.157-7.032-98.548-86.366-98.548-86.366l-36.4-30.911 41.396-90.794z"
      />
      <path
        fill="#FFF"
        d="M61.436 84.011l48.052 26.393L169.977-6.387l-42.919-20.42z"
      />
      <path
        stroke="#333"
        d="M60.745 73.11l48.052 26.407 60.495-116.87-42.926-20.34zM52.02-63.71l67.774 26.156-69.738 124.33L1.387 48.413z"
      />
      <path
        fill="#ECF2F8"
        stroke="#333"
        d="M363.568 74.877s-21.883-10.204-38.945-29.886c-15.578-17.906-38.98-27.44-62.507-24.418-19.16 2.496-39.168 11.986-50.846 37.677 0 0 10.407 28.994 49.71-.96l15.194 7.085s-8.518 56.43 34.863 70.55c43.344 14.2 55.263 13.649 55.263 13.649l-2.732-73.697zM184.051 95.666l.859 2.365a12.268 12.268 0 0 1-7.338 15.736l-16.783 6.126a12.216 12.216 0 0 1-15.708-7.325l-.858-2.365a12.268 12.268 0 0 1 7.338-15.737l16.782-6.125c6.382-2.33 13.47.976 15.708 7.325zM177.106 68.624l.857 2.368a12.304 12.304 0 0 1-7.35 15.764L153.82 92.9a12.201 12.201 0 0 1-15.708-7.324l-.858-2.368a12.304 12.304 0 0 1 7.35-15.764l16.792-6.145c6.348-2.255 13.356 1.013 15.709 7.325zM204.474 117.707l.884 2.435a12.633 12.633 0 0 1-7.556 16.204l-21.053 7.684a12.578 12.578 0 0 1-16.174-7.543l-.884-2.435a12.633 12.633 0 0 1 7.555-16.203l21.053-7.684a12.578 12.578 0 0 1 16.175 7.542zM206.129 143.447l.596 1.65c1.62 4.48-.632 9.309-5.104 10.947l-12.83 4.7c-4.472 1.638-9.282-.604-10.902-5.084l-.596-1.65c-1.62-4.48.632-9.31 5.104-10.948l12.83-4.7a8.571 8.571 0 0 1 10.902 5.085z"
      />
      <path
        fill="#FFF"
        d="M397.146 193.094l-54.603-1.11 6.123-131.124 47.195 3.72z"
      />
      <path
        stroke="#333"
        d="M405.143 189.077l-54.568-1.079 6.116-131.214 47.198 3.808z"
      />
      <path
        fill="#FFF"
        stroke="#333"
        d="M529.59 68.306l-122.012-7.611-4.436 180.356 42.136 1.386z"
      />
    </g>
  </svg>
);

export const Arrow = () => (
  <svg
    className="accessory-item__icon icon-arrow"
    width="41px"
    height="41px"
    viewBox="0 0 41 41"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="arrow-g" transform="translate(-299.000000, -4602.000000)">
        <g id="плюс2-copy-7" transform="translate(300.000000, 4603.000000)">
          <polygon
            id="plus2"
            fill="#1E2A36"
            transform="translate(25.939490, 22.315813) rotate(45.000000) translate(-25.939490, -22.315813) "
            points="25.1574218 17.6073479 26.7215577 17.551584 26.68574 27.0800424 25.18574 27.0800424"
          />
          <rect
            id="plus2"
            fill="#1E2A36"
            transform="translate(25.750000, 16.500000) rotate(-45.000000) translate(-25.750000, -16.500000) "
            x="25"
            y="12"
            width="1.5"
            height="9"
          />
          <polygon
            id="plus2"
            fill="#1E2A36"
            transform="translate(19.750000, 19.500000) rotate(90.000000) translate(-19.750000, -19.500000) "
            points="19 11 20.5 11 20.5 28 19 28"
          />
          <circle id="oval2" stroke="#1E2A36" cx="19.5" cy="19.5" r="19.5" />
        </g>
      </g>
    </g>
  </svg>
);

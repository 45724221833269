import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Fade from "react-reveal/Fade";

import Accordion from "../../Accordion";
import { itemReavealDuration } from "../../variables";
import { withTranslation } from "react-i18next";

class ServiceQuestions extends PureComponent {
  state = {
    selectedIndex: -2,
    faqs: []
  }

  componentDidMount() {
    this.faqsArray = this.props.t('service.faq.items', { returnObjects: true })

    this.setState({ faqs: this.faqsArray });
  }

  renderFaqs = (index) => {
    if (!this.state?.faqs?.length) return null

    return this.state.faqs[index].map(({ question, answer }, index) => (
      <div
        data-header={question}
        className="accordion-content" key={`subtitle-${index}`}>
        <p>{answer}</p>
      </div>
    ))
  }

  render() {
    return (
      <React.Fragment>
        <Fade duration={itemReavealDuration}>
          <Tabs className="service-tab">
            <TabList>
              <Tab>{this.props.t('service.faq.tabs.tab1')}</Tab>
              <Tab>{this.props.t('service.faq.tabs.tab2')}</Tab>
              <Tab>{this.props.t('service.faq.tabs.tab3')}</Tab>
            </TabList>

            <TabPanel>
              <Accordion
                className="accordion"
                selectedIndex={this.state.selectedIndex}>
                {this.renderFaqs(0)}
              </Accordion>
            </TabPanel>
            <TabPanel>
              <Accordion
                className="accordion"
                selectedIndex={this.state.selectedIndex}>
                {this.renderFaqs(1)}
              </Accordion>
            </TabPanel>
            <TabPanel>
              <Accordion
                className="accordion"
                selectedIndex={this.state.selectedIndex}>
                {this.renderFaqs(2)}
              </Accordion>
            </TabPanel>
          </Tabs>
        </Fade>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ServiceQuestions);

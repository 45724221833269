import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { AboutIntro } from './AboutIntro'
import { AboutWeDo } from './AboutWeDo'
import AboutValues from './AboutValues'
import AboutFaces from './AboutFaces'
import { AboutCommunity } from './AboutCommunity'
import { ServiceSetificates } from '../service/ServicePartners'
import { itemReavealDuration, laptop } from '../../variables'
import { AboutIcon } from '../../partials/Icons'
import { Quote } from '../../partials/QuoteTemplate'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next';

const Reveal = new ItemReveal()

// TODO
const quote1 = {
  en: {
    name: 'Michael and Megh Howard',
    avatar: './about/mike-megh.jpg',
    text:
        'Every day we focus on creating innovative solutions that will make the future better for us ' +
        'and our children through reduced energy consumption and improving asset reliability.',
    experienceDate:
        'Dr. Michael Howard, President & Megh Howard, Chief Marketing Officer',
    link: 'https://www.linkedin.com/in/drmichaeldhoward',
  },
  es: {
    name: 'Michael y Megh Howard, director ejecutivo',
    avatar: './about/mike-megh.jpg',
    text:
        'Todos los días nos enfocamos en crear soluciones innovadoras que mejorarán el futuro ' +
        'para nosotros y nuestros hijos mediante la reducción del consumo de energía y la mejora de la confiabilidad de los activos.',
    experienceDate:
        'Dr. Michael Howard, President & Megh Howard, Chief Marketing Officer',
    link: 'https://www.linkedin.com/in/drmichaeldhoward',
  },
  ru: {
    name: 'Michael and Megh Howard',
    avatar: './about/mike-megh.jpg',
    text:
        'Every day we focus on creating innovative solutions that will make the future better for us ' +
        'and our children through reduced energy consumption and improving asset reliability.',
    experienceDate:
        'Dr. Michael Howard, President & Megh Howard, Chief Marketing Officer',
    link: 'https://www.linkedin.com/in/drmichaeldhoward',
  }
}

// TODO fetch from server
const facesArray = {
  en: [
    {
        image: './values/klim-kiforenko.jpg',
        name: 'Klim Kiforenko',
        post: 'Chairman and CEO',
    },
    {
        image: './values/fedor-semenov.jpg',
        name: 'Fedor Semenov',
        post: 'Chief Technology Officer',
    },
    {
        image: './values/michael-semenov.jpg',
        name: 'Michael Semenov',
        post: 'Chief Production Officer',
    },
    {
        image: './values/vlidimir-poleschuk.jpg',
        name: 'Vladimir Poleschuk',
        post: 'Chief Development Officer',
    },
    {
        image: './values/anton-poleschuk.jpg',
        name: 'Anton Poleschuk',
        post: 'Development Engineer',
    },
    {
        image: './values/hanna-rekets.jpg',
        name: 'Hanna Rekets',
        post: 'Executive Assistant',
    },
    {
        image: './values/olga-falko.jpg',
        name: 'Angelika Stone',
        post: 'Chief Designer',
    },
  ],
  es: [
    {
        image: './values/klim-kiforenko.jpg',
        name: 'Klim Kiforenko',
        post: 'Chairman y CEO',
    },
    {
        image: './values/fedor-semenov.jpg',
        name: 'Fedor Semenov',
        post: 'Director de tecnología',
    },
    {
        image: './values/michael-semenov.jpg',
        name: 'Michael Semenov',
        post: 'Director de Producción',
    },
    {
        image: './values/vlidimir-poleschuk.jpg',
        name: 'Vladimir Poleschuk',
        post: 'Director de desarrollo',
    },
    {
        image: './values/anton-poleschuk.jpg',
        name: 'Anton Poleschuk',
        post: 'Ingeniero de Desarrollo',
    },
    {
        image: './values/hanna-rekets.jpg',
        name: 'Hanna Rekets',
        post: 'Asistente ejecutiva',
    },
    {
        image: './values/olga-falko.jpg',
        name: 'Angelika Stone',
        post: 'Diseñadora jefe',
    },
  ],
  ru: [
    {
      image: './values/klim-kiforenko.jpg',
      name: 'Klim Kiforenko',
      post: 'Chairman and CEO',
    },
    {
        image: './values/fedor-semenov.jpg',
        name: 'Fedor Semenov',
        post: 'Chief Technology Officer',
    },
    {
        image: './values/michael-semenov.jpg',
        name: 'Michael Semenov',
        post: 'Chief Production Officer',
    },
    {
        image: './values/vlidimir-poleschuk.jpg',
        name: 'Vladimir Poleschuk',
        post: 'Chief Development Officer',
    },
    {
        image: './values/anton-poleschuk.jpg',
        name: 'Anton Poleschuk',
        post: 'Development Engineer',
    },
    {
        image: './values/hanna-rekets.jpg',
        name: 'Hanna Rekets',
        post: 'Executive Assistant',
    },
    {
        image: './values/olga-falko.jpg',
        name: 'Angelika Stone',
        post: 'Chief Designer',
    },
  ],
}

const partnersArray = [
    {
        src: './about/barneys-pumps.png',
    },
    {
        src: './about/jd-irving.png',
    },
    {
        src: './about/usaa.png',
    },
    {
        src: './about/tawinc.jpg',
    },
    {
        src: './about/enerflex.png',
    },
    {
        src: './about/gti.png',
    },
]

const certificatesArray = {
  en: [
    {
        logo: './logos/smrp.png',
        title: 'The Society for Maintenance & Reliability',
        text:
            '(SMRP) is a nonprofit professional society formed by practitioners ' +
            'to advance the maintenance, reliability and physical asset management profession',
        link: 'https://www.smrp.org/',
    },
    {
        logo: './logos/vibration-institute.png',
        title: 'The Vibration Institute',
        text:
            'The mission of the Vibration Institute is to disseminate practical information ' +
            'on evaluating machinery behavior and condition without commercial interest',
        link: 'https://www.vi-institute.org/',
    },
    {
        logo: './logos/mfpt.png',
        title: 'Machinery Failure Prevention Technology Society',
        text: 'Where theory meets practice',
        link: 'https://www.mfpt.org/',
    },
  ],
  es: [
    {
        logo: './logos/smrp.png',
        title: 'La sociedad para el mantenimiento y la confiabilidad',
        text: '(SMRP) es una sociedad profesional sin fines de lucro formada por profesionales para promover la profesión de mantenimiento, confiabilidad y administración de activos físicos. Enlace',
        link: 'https://www.smrp.org/',
    },
    {
        logo: './logos/vibration-institute.png',
        title: 'El Instituto de Vibraciones',
        text: 'La misión del Vibration Institute es difundir información práctica sobre la evaluación del comportamiento y el estado de la maquinaria sin interés comercial.',
        link: 'https://www.vi-institute.org/',
    },
    {
        logo: './logos/mfpt.png',
        title: 'Sociedad de tecnología de prevención de fallas de maquinaria',
        text: 'Donde la teoría se encuentra con la práctica',
        link: 'https://www.mfpt.org/',
    },
  ],
  ru: [
    {
        logo: './logos/smrp.png',
        title: 'The Society for Maintenance & Reliability',
        text:
            '(SMRP) is a nonprofit professional society formed by practitioners ' +
            'to advance the maintenance, reliability and physical asset management profession',
        link: 'https://www.smrp.org/',
    },
    {
        logo: './logos/vibration-institute.png',
        title: 'The Vibration Institute',
        text:
            'The mission of the Vibration Institute is to disseminate practical information ' +
            'on evaluating machinery behavior and condition without commercial interest',
        link: 'https://www.vi-institute.org/',
    },
    {
        logo: './logos/mfpt.png',
        title: 'Machinery Failure Prevention Technology Society',
        text: 'Where theory meets practice',
        link: 'https://www.mfpt.org/',
    },
  ],
}

const imagesContext = require.context('../../../media', true)

class About extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            facesArray: [],
            partnersArray: [],
        }
    }

    componentDidMount() {
      let locale_facesArray = facesArray[this.props.i18n.language];

      this.setState({ facesArray: locale_facesArray, partnersArray })

      const TouchEvents = require('../../TouchEvents').default

      if (window.innerWidth < laptop) {
          this.touches = new TouchEvents()
      }

      Reveal.init()
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    renderPartnerLogo = () => {
        return this.state.partnersArray.map((logo, index) => {
            return (
                <div
                    className="about-partner-logo__container"
                    key={`logo-${index}`}
                >
                    <img
                        className="about-partner-logo"
                        src={imagesContext(logo.src)}
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:url" content={`/about`} />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <section className="grid__sidebar grid__sidebar-main"></section>
                        <section className="grid__content grid__content-main">
                            <AboutIntro />
                            <AboutWeDo seo={this.props.seo} />
                            <Quote data={quote1[this.props.i18n.language]} />
                            <AboutValues />
                            <AboutFaces faces={this.state.facesArray} />
                            <section className="section-screen section-screen_min-height">

                                <h2 className="h1">{this.props.t('about.partners')}</h2>
                                <Fade duration={itemReavealDuration} bottom>
                                    <div className="about-partners-wrap">
                                        {this.renderPartnerLogo()}
                                    </div>
                                </Fade>
                            </section>
                            <AboutCommunity />
                            <h2 className="h1 h1__theme__mb__big">
                                {this.props.t('about.VibroLaserIsember')}
                            </h2>
                            <div className="content-columns">
                                {certificatesArray[this.props.i18n.language].map((item, index) => (
                                    <ServiceSetificates
                                        data={item}
                                        key={`sertif-${index}`}
                                        withLink={true}
                                    />
                                ))}
                            </div>
                            <p className="about-seotext">
                                {this.props.seo.seoText &&
                                    this.props.seo.seoText}
                            </p>
                        </section>
                    </div>

                    <Fade duration={itemReavealDuration}>
                        <div className="huge-link-wrap">
                            <Link
                                to="/contacts"
                                className="link btn__theme__link"
                            >
                                {this.props.t('about.contacts_link')}
                            </Link>
                            <AboutIcon />
                        </div>
                    </Fade>
                </main>
                <Footer settings={this.props.data.settings} />
            </React.Fragment>
        )
    }
}

export default withTranslation()(About);

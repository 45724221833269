import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { itemReavealDuration, itemReavealDelay } from '../../variables'
class ProductSet extends PureComponent {
    renderPoints = (item, index) => {
        return (
            <Points
                text={item.text}
                topPosition={item.top}
                leftPosition={item.left}
                key={`pointItem-${index}`}
            />
        )
    }

    render() {
        const { product, image } = this.props

        if (!product) return null

        return (
            <section id="set" className="section-screen j-anchor-container">
                <Fade
                    duration={itemReavealDuration}
                    delay={itemReavealDelay}
                    bottom
                >
                    <h2 className="h1">{this.props.t('products.sections.whatInCase.h1')}</h2>
                    <div className="product-set-wrap">
                        <img
                            src={image}
                            alt=""
                            className="product-set__img"
                        />
                    </div>
                </Fade>
            </section>
        )
    }
}

export const Points = (props) => (
    <div
        className="product-set__point"
        style={{ top: props.topPosition + '%', left: props.leftPosition + '%' }}
    >
        <div className="product-set__point-popup">{props.text}</div>
    </div>
)
export default withTranslation()(ProductSet)

import React, { Component } from 'react'
import logo from 'media/logos/logo.svg'

let gsap = null

export default class Loader extends Component {
    cap: any
    loader: any
    constructor(props) {
        super(props)

        if (window !== undefined) {
            gsap = require('gsap')
        }
    }

    componentDidMount() {
        const capAnimationDuration = 0.4
        const easeFunction = gsap && gsap.Power2.easeInOut

        setTimeout(() => {
            gsap.TweenLite.to(this.cap, capAnimationDuration, {
                ease: easeFunction,
                xPercent: 100,
                onComplete: () => {
                    gsap.TweenLite.to('.loader-logo svg', 0.1, {
                        ease: easeFunction,
                        opacity: 1,
                    })
                    gsap.TweenLite.to(this.cap, capAnimationDuration, {
                        ease: easeFunction,
                        xPercent: 200,
                        delay: 0.2,
                        onComplete: () => {
                            gsap.TweenLite.to(
                                this.loader,
                                capAnimationDuration,
                                {
                                    ease: easeFunction,
                                    xPercent: 100,
                                    delay: 0.5,
                                }
                            )
                        },
                    })
                },
            })
        }, 500)
    }

    getLoaderRef = (node) => {
        this.loader = node
    }
    getLoaderCapRef = (node) => {
        this.cap = node
    }

    render() {
        return (
            <div className="loader" ref={this.getLoaderRef}>
                <div className="loader-logo">
                    <div
                        className="loader-logo-cap"
                        ref={this.getLoaderCapRef}
                    ></div>
                    <img className="icon-logo__white" src={logo} />
                </div>
            </div>
        )
    }
}

import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";

import VideoPopup from "../../VideoPopup";
import { itemReavealDuration, itemReavealDelay } from "../../variables";
import { withTranslation } from "react-i18next";
const imagesContext = require.context("../../../media", true);

class DistributorsPersons extends PureComponent {
  renderPerson = () => {
    this.personsArray = this.props.t('distributors.sections.persons', { returnObjects: true });

    return this.personsArray.map((person, index) => {
      const isEven = index % 2 === 1;

      return (
        <PersonTemplate data={person} even={isEven} key={`person-${index}`} />
      );
    });
  };

  render() {
    return <React.Fragment>{this.renderPerson()}</React.Fragment>;
  }
}

export const PersonTemplate = (props) => {
  const colorClass = props.even ? "wide-block__theme__bg__gray" : "";

  return (
    <div className={`wide-block ${colorClass}`}>
      <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
        <div>
          {props.data.name ? (
            <span className="distributors-person__title">
              {props.data.name}
            </span>
          ) : null}
          {props.data.videoContent.src !== "" && (
            <VideoPopup content={props.data.videoContent} />
          )}
        </div>
      </Fade>
      <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
        <div className="distributors-person__wrap">
          <div className="distributors-person__image-container">
            <img
              className="distributors-person__image"
              src={imagesContext(props.data.avatar)}
              alt={props.data.experienceDate}
            />
          </div>
          <div className="distributors-person__text-container">
            <div className="distributors-person__text">“{props.data.text}”</div>
            <span className="distributors-person__experience">
              {props.data.experienceDate}
            </span>
            <a
              href={props.data.facebook || props.data.link}
              className="distributors-person__link"
              target="_blank"
            >
              {props.data.facebook ? "Facebook" : "LinkedIn"}
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withTranslation()(DistributorsPersons);

import { url } from 'inspector'
import React, { PureComponent } from 'react'
import { Triangle } from './partials/Icons'
import { animationDuration } from './variables'

type Props = {
    images: any[]
    seo: any
    previews: any[]
    amount: any
    className: any
}

type State = {
    images: any
    activeImageIndex: any
}

let gsap = null
export default class Carousel extends PureComponent<Props, State> {
    width: any
    imageWrap: any
    constructor(props) {
        super(props)

        if (typeof window !== 'undefined') {
            gsap = require('gsap')
        }

        this.state = {
            activeImageIndex: 0,
            images: [],
        }
    }

    componentDidMount() {
        this.setState({ images: this.props.images })
        this.width = this.imageWrap.offsetWidth
    }

    amountTextRefs = []
    getImageWrapRef = (node) => (this.imageWrap = node)
    getAmountRef = (ref) => {
        this.amountTextRefs.push(ref)
    }

    renderImages(image, index) {
        if (index === 0) {
            image.alt = this.props.seo?.h1
        } else {
            image.alt = `${this.props.seo?.h1}, photo ${index + 1}`
        }
        return (
            <div
                className="carousel__img-container"
                key={`carouselItem-${index}`}
            >
                <img src={image.src} alt={image.alt} />
            </div>
        )
    }

    renderPreviews = () => {
        if (this.props.previews) {
            return (
                <ul className="carousel__preview-wrap">
                    {this.state.images.map((image, index) => {
                        return (
                            <li
                                className="carousel__preview"
                                key={`carouselPreview-${index}`}
                                style={{ backgroundImage: `url(${image.src})` }}
                                onClick={this.moveSlide.bind(this, index)}
                            ></li>
                        )
                    })}
                </ul>
            )
        }
    }

    renderAmount = () => {
        if (this.props.amount) {
            return (
                <div className="carousel__amount-wrap">
                    <span className="carousel__amount">
                        {this.state.activeImageIndex + 1} of{' '}
                        {this.state.images.length}
                    </span>
                    <div className="carousel__amount-text-container">
                        {this.state.images.map((item, index) => {
                            const isFirstElement =
                                index === 0 ? 'is-active' : ''

                            return (
                                <span
                                    className={`carousel__amount-text ${isFirstElement}`}
                                    ref={this.getAmountRef}
                                    key={`carouselAmount-${index}`}
                                >
                                    {item.text}
                                </span>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    moveSlide(slide) {
        if (slide <= 0) {
            gsap.TweenLite.to('.j-carousel-tape', animationDuration, {
                x: 0,
                onComplete: () => this.showAmount(),
            })
            this.setState({ activeImageIndex: 0 })

            return
        }

        if (slide >= this.state.images.length - 1) {
            gsap.TweenLite.to('.j-carousel-tape', animationDuration, {
                x: -this.width * (this.state.images.length - 1),
                onComplete: () => this.showAmount(),
            })
            this.setState({ activeImageIndex: this.state.images.length - 1 })

            return
        }

        gsap.TweenLite.to('.j-carousel-tape', animationDuration, {
            x: -this.width * slide,
            onComplete: () => this.showAmount(),
        })

        this.setState({ activeImageIndex: slide })
    }

    showAmount = () => {
        if (!this.props.amount) return

        this.amountTextRefs.forEach((element) => {
            element.classList.remove('is-active')
        })
        this.amountTextRefs[this.state.activeImageIndex].classList.add(
            'is-active'
        )
    }

    render() {
        return (
            <div
                className={`carousel ${this.props.className}`}
                ref={this.getImageWrapRef}
            >
                <div className="carousel__img-wrap j-carousel-tape">
                    {this.state.images.map((image, index) =>
                        this.renderImages(image, index)
                    )}
                </div>
                {this.renderPreviews()}
                {this.renderAmount()}
                <button
                    className="carousel__control-btn is-prev"
                    onClick={this.moveSlide.bind(
                        this,
                        this.state.activeImageIndex - 1
                    )}
                >
                    <Triangle />
                </button>
                <button
                    className="carousel__control-btn is-next"
                    onClick={this.moveSlide.bind(
                        this,
                        this.state.activeImageIndex + 1
                    )}
                >
                    <Triangle />
                </button>
            </div>
        )
    }
}

import React, { PureComponent } from "react";
import Modal from "react-modal";
import { laptop } from "./variables";
import { Play, Close } from "./partials/Icons";

const modalStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.8)",
  },
};

Modal.setAppElement("#App");

export default class VideoPopup extends PureComponent {
  state = {
    showModal: false,
    src: null,
  };

  componentDidMount() {
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.formatSrc();
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  formatSrc = () => {
    const dataSrc = this.props.content.src.split("/");

    this.setState({ src: dataSrc[dataSrc.length - 1] });
  };

  renderText = () => {
    if (this.props.content.text) {
      return <span className="video-text">{this.props.content.text}</span>;
    }
  };

  render() {
    const { preview } = this.props.content;
    const { showModal, src } = this.state;

    return (
      <div className="video-wrap">
        <picture className="video-preview">
          <source srcSet={preview} media={`(min-width: ${laptop}px)`} />
          <img src={preview} alt="video" />
          {this.renderText()}
          <button className="video-btn" onClick={this.handleOpenModal}>
            <Play />
            <span className="video-btn__title">Watch</span>
          </button>
        </picture>
        <Modal isOpen={showModal} style={modalStyles} className="video-popup">
          <iframe
            className="video-popup__video"
            src={`https://www.youtube.com/embed/${src}?autoplay=1`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <button
            className="video-popup__close"
            onClick={this.handleCloseModal}
          >
            <Close />
          </button>
        </Modal>
      </div>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { itemReavealDuration, itemReavealDelay } from "../../variables";
// import img7 from "../../../media/home/img7.png";
// import img8 from "../../../media/home/img8.png";
// import img9 from "../../../media/home/img9.png";
import img10 from "../../../media/home/img10.png";
import img11 from "../../../media/home/img11.png";
import img12 from "../../../media/home/img12.png";
import { Triangle } from "../../partials/Icons";
import { useTranslation } from "react-i18next";

export const CasesCardsExplore = () => {
  const [t] = useTranslation();

  return (
    <section
      id="case-more"
      className="section-screen section-screen_padding-top"
    >
      <div className="section-screen_bottom">
        <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
          <h2 className="h1">Who already use it</h2>
          <p className="useit-anons">
            We ask our clients to say some words if our equipment helped them to
            reduce costs or make life better. How it reduces clients’costs? What
            for people usually use that equipment?
          </p>
          <div className="home-sections-wrap">
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img10})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">Enerflex</h3>
                <span className="home-section__anons">
                  I never need to worry if my team needs service or support
                  because the team is always ready to help
                </span>
              </div>
            </Link>
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img11})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">Barney Pumps</h3>
                <span className="home-section__anons">
                  I love multi-platform philosophy their system <br />
                  can be sent out to any of my technicians anywhere
                </span>
              </div>
            </Link>
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img12})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">USAA Bank</h3>
                <span className="home-section__anons">
                  The VLSAT takes on the most complex Cardan and Machine train
                  alignments with easy
                </span>
              </div>
            </Link>
            <Link to="/products" className="home-section__see-more">
              {t('see-more')}
              <Triangle />
            </Link>
          </div>
          {/* <div className="home-sections-wrap home-sections__theme__cases">
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img7})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">General Electric</h3>
                <span className="home-section__anons">
                  Use Laser shaft alignment (VLSAT) to improve their equipment
                  and save energy
                </span>
              </div>
            </Link>
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img8})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">Crysler</h3>
                <span className="home-section__anons">
                  Use Laser shaft alignment (VLSAT) to improve their equipment
                  and save energy
                </span>
              </div>
            </Link>
            <Link to="#" className="home-section cap-parent">
              <div className="cap"></div>
              <div className="home-section__bg-container">
                <div
                  className="home-section__bg"
                  style={{ backgroundImage: `url(${img9})` }}
                ></div>
              </div>
              <div className="home-section__content-container">
                <h3 className="home-section__title">USAA</h3>
                <span className="home-section__anons">
                  Use Laser shaft alignment (VLSAT) to improve their equipment
                  and save energy
                </span>
              </div>
            </Link>
          </div> */}
          <div className="long-link-wrap">
            <Link to="/products" className="link link__theme__home">
              {t('see-more')}
            </Link>
          </div>
        </Fade>
      </div>
    </section>
  );
};

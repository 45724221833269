import React, { PureComponent } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Footer } from '../../partials/Footer'
import { ProductIntro } from './ProductIntro'
import { ProductHowItWorks } from './ProductHowItWorks'
import ItemReveal from '../../ItemReveal'
import { laptop } from '../../variables'
import ProductSoftware from './ProductSoftware'
import ProductSpecification from './ProductSpecification'
import ProductSet from './ProductSet'
import { ProductAccessory } from './ProductAccessory'
import MapAddresses from '../../partials/MapAddresses'
import HighlightAnchor from '../../HighlightAnchor'
import { DistributorsForm } from '../distributors/DistributorsForm'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const Anchors = new HighlightAnchor()
const Reveal = new ItemReveal()

// TODO Refactor types
interface Props extends WithTranslation {
    data: {
        name: string
        kits: any[]
        distributors: any[]
        settings: any
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

interface State {
    data: any
    accessories: any[]
    partners: any[]
    options: any[]
    parent: any
    pdfData: any
}

let TouchEvents = null

class ProductCard extends PureComponent<Props, State> {
    touches: any
    constructor(props) {
        super(props)

        const { slug } = this.props.match.params
        const kit = this.props.data.kits.find((el) => el.slug === slug)

        console.log("Kit::",kit);

        this.state = {
            data: kit,
            accessories: kit.accessories,
            partners: kit.partners,
            options: kit.options,
            parent: kit.product,
            pdfData: kit,
        }

        if (typeof window !== 'undefined') {
            TouchEvents = require('../../TouchEvents').default
        }
    }

    componentDidMount() {
        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
        }

        Anchors.init()
        Reveal.init()
    }

    componentWillUnmount() {
        Anchors.removeListener()
        Reveal.removeListener()
    }

    render() {
        const { data, accessories, partners, options, parent, pdfData } =
            this.state

        if (Object.keys(data).length == 0) {
            return <></>
        }

        return data ? (
            <>
                <Helmet>
                    <script
                        data-vmid="ldjson-schema"
                        type="application/ld+json"
                    >{`{"@context": "http://schema.org","@type": "Product","name": "${this.props.seo.h1 ? this.props.seo.h1 : "Product"}", "description": "${this.props.seo.description ? this.props.seo.description : "Product description"}", "image": "${data.images[0].src ? data.images[0].src : "/logo-8aeda6192a98b855e9445b65b394a9a7.png"}", "brand": "VIBRO-LASER", "url": "vibro-laser.com/kit/${data.slug}"}`}</script>
                    <meta property="og:image" content={data.images[0].src} />
                    <meta property="og:url" content={`/kit/${data.slug}`} />
                    <meta property="og:type" content="product" />
                </Helmet>
                <main
                    className="main container flex-nowrap main__theme_margin_top"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    {console.log(this.state.data.configuration)}
                    <section className="grid__sidebar grid__sidebar-main">
                        <ul className="sidebar-list">
                            <li>
                                <AnchorLink
                                    offset="200"
                                    href="#howItWorks"
                                    className="sidebar-link j-anchor-link"
                                >
                                    {this.props.t('products.left_links.intro_video')}
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    href="#software"
                                    className="sidebar-link j-anchor-link"
                                >
                                    {this.props.t('products.left_links.software')}
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    offset="200"
                                    href="#specification"
                                    className="sidebar-link j-anchor-link"
                                >

                                    {this.props.t('products.left_links.technicalData')}
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    offset="200"
                                    href="#set"
                                    className="sidebar-link j-anchor-link"
                                >
                                    {this.props.t('products.left_links.set')}
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    href="#whereToBuy"
                                    className="sidebar-link j-anchor-link"
                                >

                                    {this.props.t('products.left_links.whereToBuy')}
                                </AnchorLink>
                            </li>
                        </ul>
                    </section>
                    <section className="grid__content grid__content-main">
                        <ProductIntro
                            data={data}
                            pdfData={pdfData}
                            partners={partners}
                            seo={this.props.seo}
                        />
                        {this.props.seo.seoText && (
                            <p className="product-seotext">
                                {this.props.seo.seoText}
                            </p>
                        )}
                        {data.youtube && data.youtube.src ? (
                            <ProductHowItWorks data={data.youtube} />
                        ) : null}
                        {options.length ? (
                            <ProductSoftware data={options} />
                        ) : null}
                        {parent ? <ProductSpecification data={parent} /> : null}

                        <ProductSet product={data.slug} image={data.configuration} />

                        {accessories.length ? (
                            <ProductAccessory
                                data={accessories}
                                pdfData={pdfData}
                            />
                        ) : null}
                        <section
                            id="whereToBuy"
                            className="section-screen j-anchor-container"
                        >
                            <div className="section-screen-center">
                                <MapAddresses
                                    distributors={this.props.data.distributors}
                                />
                            </div>
                        </section>
                        <section
                            className="section-screen"
                            id="callbackSection"
                        >
                            <div className="section-screen-center">
                            <DistributorsForm options={{
                                              title: this.props.t('products.formTitle'),
                                              additionalClassName: null }
                                            }
                            />
                            </div>
                        </section>
                        <section>
                            <p className="about-seotext">
                                {this.props.seo.seoText &&
                                    this.props.seo.seoText}
                            </p>
                        </section>
                    </section>
                </main>
                <Footer settings={this.props.data.settings} />
            </>
        ) : null
    }
}

export default withTranslation()(ProductCard);

import React, {useEffect, useState} from "react";
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from "./Header";
import ContentHome from "../pages/home/index";
import Shop from "../pages/shop";
import ProductCard from "../pages/product-card";
import AccessoryTemplate from "../pages/product-card/AccessoryTemplate";
import Distributors from "../pages/distributors";
import Service from "../pages/service";
import Cases from "../pages/cases";
import CaseCard from "../pages/cases/CaseCard";
import About from "../pages/about";
import Contacts from "../pages/contacts";
import MetaTags from "./MetaTags";

const Container = ({ locale, location, seo_tags, distributors, articles, products, accessories, kits, settings}) => {
  const pathname = typeof window === 'undefined' ? location : window.location.pathname

  const routes = [
    { path: "/", name: "ContentHome", Component: ContentHome, exact: true, data: { kits: kits, distributors: distributors, settings: settings } },
    { path: "/accessory/:slug*", name: "Accessory", Component: AccessoryTemplate, data: { accessories: accessories, locale: locale, settings: settings } },
    { path: "/kit/:slug*", name: "Product card", Component: ProductCard, data: { locale: locale, kits: kits, distributors: distributors, settings: settings } },
    { path: "/products", name: "Products", Component: Shop, data: { products: products, settings: settings } },
    { path: "/for-distributors", name: "Distributors", Component: Distributors, data: { settings: settings } },
    { path: "/service", name: "Service", Component: Service, data: { settings: settings } },
    { path: "/cases", name: "Cases", Component: Cases, exact: true, data: { articles: articles, settings: settings } },
    { path: "/cases/:slug", name: "Case", Component: CaseCard, data: { articles: articles, settings: settings } },
    { path: "/about", name: "About", Component: About, data: { settings: settings } },
    { path: "/contacts", name: "Contacts", Component: Contacts, data: { distributors: distributors, settings: settings } },
  ];

  const [seoData, setSeoData] = useState({
    path: "",
    title: "",
    description: "",
    keywords: "",
    h1: "",
    seotext: "",
  });

  useEffect(() => {
    setSeoTags();
  }, [pathname]);

  const setSeoTags = () => {
    const seoData = seo_tags.find(
      (tags) => tags.path === pathname
    );
    seoData && setSeoData(seoData);
  };

  const handlePageChange = () => {
    if (typeof window === 'undefined') { return }

    window.scrollTo(0, 0);
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
  };

  return (
    <>
      <MetaTags tags={seoData} />
      <Header />
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 600, exit: 600 }}
          classNames="slidepage"
        >
          <section className="route-section">
            <Switch location={location}>
              {routes.map(({ path, Component, exact, data }) => (
                <Route
                  exact={exact}
                  key={path}
                  path={path}
                  onEnter={handlePageChange()}
                >
                  {(props) => <Component {...props} data={data} seo={seoData} />}
                </Route>
              ))}
              <Route
                render={() => <div>go to 404</div>}
              />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};


export default withRouter(Container);

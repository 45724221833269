import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import ItemReveal from "../../ItemReveal";
import { itemReavealDuration, itemReavealDelay, laptop } from "../../variables";
import { AccessoryIntro } from "./AccessoryIntro";
import { Footer } from "../../partials/Footer";
import img2 from "../../../media/home/img2.png";
import { DistributorsForm } from "../distributors/DistributorsForm";
import { withTranslation } from "react-i18next";

const Reveal = new ItemReveal();
class AccessoryTemplate extends PureComponent {
  constructor(props) {
    super(props)

    const { slug } = this.props.match.params;
    let currentAccessory = this.props.data.accessories.find((el) => el.slug === slug);

    this.state = {
      currentAccessory: currentAccessory,
      partners: currentAccessory.partners
    };
  }

  componentDidMount() {
    const TouchEvents = require("../../TouchEvents").default;

    if (window.innerWidth < laptop) {
      this.touches = new TouchEvents();
    }
    Reveal.init();
  }

  componentWillUnmount() {
    Reveal.removeListener();
  }

  render() {
    return (
      <React.Fragment>
        <main
          className="main"
          onTouchStart={(event) => this.touches.handleTouchStart(event)}
          onTouchMove={(event) => this.touches.handleTouchMove(event)}
        >
          <div className="container flex-nowrap main__theme_margin_top">
            <section className="grid__sidebar grid__sidebar-main"></section>
            <section className="grid__content grid__content-main">
              <AccessoryIntro
                data={this.state.currentAccessory}
                partners={this.state.partners}
                seo={this.props.seo}
                locale={this.props.data.locale}
              />

              <section className="section-screen" id="callbackSection">
                <div className="section-screen-center">
                  <DistributorsForm options={{
                                              title: this.props.t('accessory.formTitle'),
                                              additionalClassName: null }
                                            }
                  />
                </div>
              </section>
              {this.props.seo.seoText && (
                <p className="accessory-seotext">{this.props.seo.seoText}</p>
              )}
            </section>
          </div>
          <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
            <div className="huge-link-wrap">
              <Link to="/cases" className="link btn__theme__link">
                WHAT IS VLSAT SYSTEM
              </Link>
              <img src={img2} />
            </div>
          </Fade>
        </main>
        <Footer settings={this.props.data.settings} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(AccessoryTemplate);

import React, { useState, useEffect } from "react";
import SuggestionsList from "./SuggestionsList";
import { useTranslation } from 'react-i18next';

const AutoComplete = ({ suggestions, changed }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    changed(inputValue);
  }, [inputValue]);

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };

  const onSelectSuggestion = (index) => {
    setSelectedSuggestion(index);
    setInputValue(filteredSuggestions[index]);
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  return (
    <div className="autocomplete">
      <input
        type="text"
        onChange={onChange}
        value={inputValue}
        className="autocomplete__input"
      />
      <span
        className={`autocomplete__input__placeholder ${
          inputValue.length && "rolled"
        }`}
      >
        {t('map.autoComplete.label')}
      </span>

      <SuggestionsList
        suggestions={suggestions}
        inputValue={inputValue}
        selectedSuggestion={selectedSuggestion}
        onSelectSuggestion={onSelectSuggestion}
        displaySuggestions={displaySuggestions}
        suggestions={filteredSuggestions}
      />
    </div>
  );
};

export default AutoComplete;

import React from "react";

const imagesContext = require.context("../../media", true);

export const Quote = ({ data }) => {
  return (
    <section className="wide-block wide-block__theme__bg__blue wide-block__theme__margin wide-block__theme__padding_top">
      <div className="one-quote">
        <div className="one-quote__img-container">
          <img className="one-quote__img" src={data.url} alt={data.position} />
        </div>
        <div className="one-quote__text-container">
          <div className="one-quote__text">“{data.text}”</div>
          <div className="one-quote__experience">{data.name}</div>
          <span className="one-quote__experience">{data.position}</span>
          {data.link && (
            <a
              href={data.link}
              className="link link__theme__color__white"
              target="_blank"
            >
              LinkedIn
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { ServiceIntro } from './ServiceIntro'
import ServiceQuestions from './ServiceQuestions'
import { PersonTemplate } from '../distributors/DistributorsPersons'
import { ServicePartners, ServiceSetificates } from './ServicePartners'
import { DistributorsForm } from '../distributors/DistributorsForm'
import { AboutIcon } from '../../partials/Icons'
import { itemReavealDuration, itemReavealDelay, laptop } from '../../variables'
import { Quote } from '../../partials/QuoteTemplate'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'

const Reveal = new ItemReveal()

interface State {}
interface Props extends WithTranslation {
    data: {
        settings: any
    }
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

class Service extends PureComponent<Props, State> {
  touches: any
  personsArray: any
  partnersArray: any
  certificatesArray: any
  quote: any

    constructor(props) {
      super(props);

      this.partnersArray = this.props.t('service.partners', { returnObjects: true });
      this.personsArray = this.props.t('service.persons', { returnObjects: true });
      this.certificatesArray = this.props.t('service.certificates', { returnObjects: true });
      this.quote = this.props.t('service.quote', { returnObjects: true });
    }

    componentDidMount() {
        const TouchEvents = require('../../TouchEvents').default

        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
        }

        Reveal.init()
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    renderPerson = () => {
        return this.personsArray.map((person, index) => {
            return <PersonTemplate data={person} key={`person-${index}`} />
        })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:url" content="/service" />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <section className="grid__sidebar grid__sidebar-main"></section>
                        <section className="grid__content grid__content-main">
                            <ServiceIntro seo={this.props.seo} />
                            {this.renderPerson()}
                            <section className="wide-block wide-block__theme__bg__blue wide-block__theme__margin">
                                <Fade
                                    duration={itemReavealDuration}
                                    delay={700}
                                    bottom
                                >
                                    <div className="distributors__text-wrap is-mb-30">
                                        <p className="distributors__big-text">
                                          {this.props.t('service.text')}
                                        </p>
                                    </div>
                                    <a
                                        className="btn btn__theme__link is-white"
                                        href="https://www.youtube.com/playlist?list=PLw19gnSwlTE5inVxZ72MekLcf3bfnveTK"
                                        rel="noindex, nofollow"
                                        target="_blank"
                                    >
                                      {this.props.t('service.watchVideo')}
                                    </a>
                                </Fade>
                            </section>
                            <section className="wide-block wide-block__theme__margin">
                                <ServiceQuestions />
                            </section>
                            <Quote data={this.quote} />
                            <div className="content-columns">
                                {this.partnersArray.map((item, index) => (
                                    <ServicePartners
                                        data={item}
                                        key={`partner-${index}`}
                                    />
                                ))}
                            </div>
                            <h2 className="h1 h1__theme__mb__big">
                                {this.props.t('service.serviceCertifications')}
                            </h2>
                            <div className="content-columns">
                                {this.certificatesArray.map((item, index) => (
                                    <ServiceSetificates
                                        data={item}
                                        key={`sertif-${index}`}
                                        withLink={false}
                                    />
                                ))}
                            </div>
                            <section className="section-screen" id="contacts">
                                <div className="section-screen-center">
                                <DistributorsForm options={{
                                              title: this.props.t('service.formTitle'),
                                              additionalClassName: null }
                                            }
                                />
                                </div>
                            </section>
                            <p className="about-seotext">
                                {this.props.seo.seoText &&
                                    this.props.seo.seoText}
                            </p>
                        </section>
                    </div>
                    <Fade
                        duration={itemReavealDuration}
                        delay={itemReavealDelay}
                        bottom
                    >
                        <div className="huge-link-wrap">
                            <Link
                                to="/products"
                                className="link btn__theme__link"
                            >
                              {this.props.t('service.products')}
                            </Link>
                            <AboutIcon />
                        </div>
                    </Fade>
                </main>
                <Footer settings={this.props.data.settings} />
            </React.Fragment>
        )
    }
}

export default withTranslation()(Service);

import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import VideoPopup from '../../VideoPopup'
import { itemReavealDuration, itemReavealDelay } from '../../variables'
import TrainingAndSupport from 'media/distributors/training_and_support.png'
import InternationalTeam from 'media/distributors/international_team.png'
import FiveYears from 'media/distributors/five_years.png'
import InnovativeSolutions from 'media/distributors/innovative_solutions.png'
import BeneficialConditions from 'media/distributors/beneficial_conditions.png'
import BestOffer from 'media/distributors/best_offer.png'
import { useTranslation } from 'react-i18next'

const videoContent = {
    previewSmall: './product-card/video_prev-small.jpg',
    preview: '/images/distributors/steve-chwyl.jpg',
    src: 'https://youtu.be/J4zsfdcd4tI',
    text: 'The story from our distributors',
}

export const DistributorsIntro = ({ seo }) => {
  const [t] = useTranslation();

  return (
      <React.Fragment>
          <section className="section-screen">
              <div className="section-screen-center">
                  <Fade duration={itemReavealDuration} bottom>
                      <nav
                          className="breadcrumbs"
                          itemScope
                          itemType="https://schema.org/BreadcrumbList"
                      >
                          <Link
                              to="/"
                              className="breadcrumb-link"
                              itemProp="itemListElement"
                              itemScope
                              itemType="https://schema.org/ListItem"
                              itemprop="name"
                          >
                              <span itemprop="name">{t('breadcrumbs.main')}</span>
                          </Link>
                          <span
                              className="breadcrumb__current"
                              itemProp="itemListElement"
                              itemScope
                              itemType="https://schema.org/ListItem"
                              itemprop="name"
                          >
                              <span itemprop="name">{t('breadcrumbs.forDistributors')}</span>
                          </span>
                      </nav>
                      <h1 className="h1 h1__theme__inner">
                          {seo.h1 ? seo.h1 : t('distributorsIntro.h1')}
                      </h1>
                      <span className="distributors-intro">
                          {t('distributorsIntro.intro')}
                      </span>
                      <div className="distributors-cubes-wrap">
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card1.title')}
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card1.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={FiveYears}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card2.title')}
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card2.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={InternationalTeam}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card3.title')}
                              </span>

                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card3.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={TrainingAndSupport}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card4.title')}
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card4.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={BestOffer}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card5.title')}
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card5.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={BeneficialConditions}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                {t('distributorsIntro.cards.card6.title')}
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                    {t('distributorsIntro.cards.card6.text')}
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={InnovativeSolutions}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </Fade>
              </div>
          </section>
          <section className="section-screen" id="the-story">
              <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
                  <VideoPopup content={videoContent} />
              </Fade>
          </section>
      </React.Fragment>
  );
}

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { CasesItem } from './CasesItemTemplate'
import { DistributorsForm } from '../distributors/DistributorsForm'
import { itemReavealDuration, itemReavealDelay, laptop } from '../../variables'
import { AboutIcon } from '../../partials/Icons'
import { Helmet } from 'react-helmet'
import CasesFilter from './CasesFilter'
import { withTranslation, WithTranslation } from 'react-i18next'

let TouchEvents = null
const Reveal = new ItemReveal()
interface Props extends WithTranslation {
    data: {
        name: string
        kits: any[]
        distributors: any[]
        settings: any
        articles: Case[]
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

export type Case = {
    body: DataBlock[]
    client: string
    description: string
    equipment: string
    id: number
    image: string
    industry: string
    name: string
    slug: string
}

type DataBlock = {
    data: {
        settings: {
            color: 'string'
            level: 'string'
            text_color: 'string'
            title_color: 'string'
        }
        text: 'string'
        title: 'string'
    }
    type: 'title_and_text' | 'quote' | 'result_card'
}

interface State {
    data: Case[]
    filtered: Case[]
}
class Cases extends PureComponent<Props, State> {
    touches: any
    constructor(props) {
        super(props)

        if (typeof window !== 'undefined') {
            TouchEvents = require('../../TouchEvents').default
        }

        this.state = {
            data: this.props.data.articles,
            filtered: [],
        }

        this.handleFilter = this.handleFilter.bind(this)
    }

    componentDidMount() {
        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
        }

        Reveal.init()
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    handleFilter(industry, product) {
        let data = JSON.parse(JSON.stringify(this.state.data))

        if (industry.id !== 0) {
            data = data.filter((item) => item.industry === industry.value)
        }

        if (product.id !== 0) {
            data = data.filter((item) => item.equipment === product.value)
        }

        const filtered = data

        this.setState({ filtered })
    }

    handleReset() {}

    renderCases = () => {
        return this.state.filtered.map((caseItem, index) => {
            return <CasesItem data={caseItem} key={`case-${index}`} />
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta property="og:url" content="/cases" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <section className="grid__sidebar grid__sidebar-main"></section>
                        <section className="grid__content grid__content-main">
                            <section className="section-screen">
                                <Fade duration={itemReavealDuration} bottom>
                                    <nav
                                        className="breadcrumbs"
                                        itemScope
                                        itemType="https://schema.org/BreadcrumbList"
                                    >
                                        <Link
                                            to="/"
                                            className="breadcrumb-link"
                                            itemProp="itemListElement"
                                            itemScope
                                            itemType="https://schema.org/ListItem"
                                        >
                                            <span itemProp="name">{this.props.t('breadcrumbs.main')}</span>
                                        </Link>
                                        <span
                                            className="breadcrumb__current"
                                            itemProp="itemListElement"
                                            itemScope
                                            itemType="https://schema.org/ListItem"
                                        >
                                            <span itemProp="name">{this.props.t('breadcrumbs.cases')}</span>
                                        </span>
                                    </nav>
                                    <h1 className="h1 h1__theme__inner">
                                        {this.props.seo.h1
                                            ? this.props.seo.h1
                                            : this.props.t('cases.h1')}
                                    </h1>
                                    <span className="content__anons-text">
                                      {this.props.t('cases.text')}
                                    </span>
                                </Fade>
                                <CasesFilter
                                    cases={this.state.data}
                                    filter={this.handleFilter}
                                />
                                {this.renderCases()}
                                <section
                                    className="section-screen"
                                    id="contacts"
                                >
                                    <div className="section-screen-center">
                                        <DistributorsForm
                                            options={{
                                              title: this.props.t('cases.formTitle'),
                                              additionalClassName: null }
                                            }
                                        />
                                    </div>
                                </section>
                            </section>
                            <p className="cases-seotext">
                                {this.props.seo.seoText &&
                                    this.props.seo.seoText}
                            </p>
                        </section>
                    </div>
                    <Fade
                        duration={itemReavealDuration}
                        delay={itemReavealDelay}
                        bottom
                    >
                        <div className="huge-link-wrap">
                            <Link
                                to="/products"
                                className="link btn__theme__link"
                            >
                                {this.props.t('cases.products')}
                            </Link>
                            <AboutIcon />
                        </div>
                    </Fade>
                </main>
                <Footer settings={this.props.data.settings} />
            </>
        )
    }
}

export default withTranslation()(Cases);

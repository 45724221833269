import React, { PureComponent } from 'react'
import Fade from 'react-reveal/Fade'
import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { CaseCardIntro } from './CaseCardIntro'
import { CaseCardResults } from './CaseCardResults'
import { CasesCardsExplore } from './CasesCardsExplore'
import { DistributorsForm } from '../distributors/DistributorsForm'
import { Quote } from '../../partials/QuoteTemplate'
import TitleAndText from './CasesTitleAndText'
import { itemReavealDuration, itemReavealDelay, laptop } from '../../variables'
import SideBar from './CasesSideBar'
import { Helmet } from 'react-helmet'

const Reveal = new ItemReveal()
let TouchEvents = null

const FORM_OPTIONS = {
    title: 'Would like to make your manufacturing better?',
    additionalClassName: 'form__theme__blue',
}

type Props = {
    data: {
        name: string
        kits: any[]
        distributors: any[]
        settings: any
        articles: any[]
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

type State = {
    data: any
}

export default class CaseCard extends PureComponent<Props, State> {
    touches: any
    constructor(props) {
        super(props)

        if (typeof window !== 'undefined') {
            TouchEvents = require('../../TouchEvents').default
        }

        const { slug } = this.props.match.params

        const data = this.props.data.articles.find((el) => {
            return el.slug === slug
        })

        this.state = {
            data: data,
        }
    }

    componentDidMount() {
        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
        }

        Reveal.init()
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    render() {
        const { data } = this.state
        return (
            <>
                <Helmet>
                    <meta property="og:image" content={data.image} />
                    <meta property="og:url" content={`/cases/${data.slug}`} />
                    <meta property="og:type" content="website" />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <SideBar />
                        <section className="grid__content grid__content-main">
                            <section id="case-about" className="section-screen">
                                <Fade
                                    duration={itemReavealDuration}
                                    delay={itemReavealDelay}
                                    bottom
                                >
                                    <CaseCardIntro
                                        case={data}
                                        seo={this.props.seo}
                                    />
                                </Fade>
                            </section>
                            {data.body &&
                                data.body.map((block, index) => {
                                    switch (block.type) {
                                        case 'quote':
                                            return <Quote data={block.data} key={index}/>
                                        case 'title_and_text':
                                            return (
                                                <TitleAndText
                                                    data={block.data}
                                                    key={index}
                                                />
                                            )
                                        case 'result_card':
                                            return (
                                                <CaseCardResults
                                                    data={block.data}
                                                    key={index}
                                                />
                                            )
                                        default:
                                            break
                                    }
                                })}
                            <section className="wide-block wide-block__theme__bg__blue wide-block__theme__margin_bottom">
                                <DistributorsForm options={FORM_OPTIONS} />
                            </section>
                            <CasesCardsExplore />
                        </section>
                    </div>
                </main>
                <Footer settings={this.props.data.settings} />
            </>
        )
    }
}

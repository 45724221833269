import {
    parseOnlyLetterAndSpace,
    checkAtLeastLength,
    checkEmailPattern,
    checkIsfilled,
    checkIsTrue,
    checkPhonePattern,
} from './inputValidator'

const registrationModel = [
    {
        name: 'name',
        // label: 'Name',
        type: 'text',
        formTypeIndex: [0, 1, 2],
        parseFun: parseOnlyLetterAndSpace,
        validators: [
            {
                id: 'text-length',
                isValidFun: (expression) => checkAtLeastLength(expression, 3),
                // alert: 'Name is too short'
            },
        ],
    },
    {
        name: 'country',
        // label: 'Country',
        type: 'text',
        formTypeIndex: [0, 1, 2],
        parseFun: parseOnlyLetterAndSpace,
        validators: [
            {
                id: 'text-length',
                isValidFun: (expression) => checkAtLeastLength(expression, 3),
                // alert: 'Сountry name is too short'
            },
        ],
    },
    {
        name: 'city',
        // label: 'City',
        type: 'text',
        formTypeIndex: [0, 1, 2],
    },
    {
        name: 'company',
        // label: 'Company\'s name',
        type: 'text',
        formTypeIndex: [0, 1, 2],
        validators: [
            {
                id: 'text-length',
                isValidFun: (expression) => checkAtLeastLength(expression, 3),
                // alert: 'Commapany\'s name is too short'
            },
        ],
    },
    {
        name: 'phone',
        // label: 'Contact phone',
        type: 'tel',
        formTypeIndex: [0, 1, 2],
        validators: [
            {
                id: 'phone-pattern',
                isValidFun: checkPhonePattern,
                // alert: 'Phone number is not valid'
            },
        ],
    },
    {
        name: 'email',
        // label: 'Email',
        type: 'text',
        formTypeIndex: [0, 1, 2],
        validators: [
            {
                id: 'email-pattern',
                isValidFun: checkEmailPattern,
                // alert: 'Email is not valid'
            },
            {
                id: 'email-required',
                isValidFun: checkIsfilled,
                // alert: 'Email is empty'
            },
        ],
    },
    {
        name: 'os',
        // label: 'OS',
        type: 'text',
        formTypeIndex: [2],
    },
    {
        name: 'software',
        // label: 'Software version of VLSAT',
        type: 'text',
        formTypeIndex: [2],
        tooltip: {
            img: 'version.jpg',
        },
    },
    {
        name: 'sensors',
        // label: 'Sensors version of VLMID, VLSID',
        type: 'text',
        formTypeIndex: [2],
        tooltip: {
            img: 'version.jpg',
        },
    },
    {
        name: 'message',
        // label: 'Description',
        type: 'textarea',
        formTypeIndex: [2],
    },
    {
        name: 'files',
        // label: 'Video and photo of issue',
        type: 'file',
        formTypeIndex: [2],
    },
    {
        name: 'agreement',
        // label: 'Yes, I do. I agree to the',
        type: 'checkbox',
        formTypeIndex: [0, 1, 2],
        validators: [
            {
                id: 'terms-required',
                isValidFun: checkIsTrue,
                // alert: 'You must agree before submitting'
            },
        ],
    },
]

export default registrationModel

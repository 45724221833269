import React, { Component } from "react";
import * as THREE from "three";

const shader1 = `
  #define PI 3.1415926535897932384626433832795
  attribute float size;
  void main() {
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_PointSize = 2.0;
    gl_Position = projectionMatrix * mvPosition;
  }
`;
const shader2 = `
  uniform sampler2D texture;
  void main(){
    vec4 textureColor = texture2D( texture, gl_PointCoord );
    if ( textureColor.a < 0.3 ) discard;
    vec4 dotColor = vec4(0.06, 0.18, 0.36, 0.4);
    vec4 color = dotColor * textureColor;
    gl_FragColor = color;
  }
`;

let TouchEvents = null;
let TweenMax = null;
export default class Particles extends Component {
  constructor(props) {
    super(props);

    // For SSR
    if (typeof window !== 'undefined') {
      TouchEvents = require('./TouchEvents').default;
      TweenMax = require('gsap').TweenMax;
    }
  }

  componentDidMount() {
    const canvas = document.querySelector('#scene');
    let width = canvas.offsetWidth;
    let height = canvas.offsetHeight;
    let resizeTm;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 2000);
    const loader = new THREE.TextureLoader();
    const dotTexture = loader.load('/particles/dotTexture.png');
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true
    });
    const radius = 50;
    const sphereGeom = new THREE.IcosahedronGeometry(radius, 4);
    const dotsGeom = new THREE.Geometry();
    const bufferDotsGeom = new THREE.BufferGeometry();
    const positions = new Float32Array(sphereGeom.vertices.length * 3);
    const attributePositions = new THREE.BufferAttribute(positions, 3);
    renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2 : 1);
    renderer.setSize(width, height);
    renderer.setClearColor(0xffffff);
    camera.position.set(40, 0, 80);
    loader.crossOrigin = "Anonymous";

    for (let i = 0; i < sphereGeom.vertices.length; i++) {
      const vector = sphereGeom.vertices[i];
      animateDot(i, vector);
      dotsGeom.vertices.push(vector);
      vector.toArray(positions, i * 3);
    }

    function animateDot( index, vector ) {
      TweenMax.to(vector, 4, {
        x: 0,
        z: 0,
        ease:Back.easeOut,
        delay: Math.abs(vector.x/radius) * 2,
        repeat: -1,
        yoyo: true,
        yoyoEase:Back.easeOut,
        onUpdate: function () {
          updateDot(index, vector);
        }
      });
    }

    function updateDot( index, vector ) {
      positions[ index * 3 ] = vector.x;
      positions[ index * 3 + 2 ] = vector.z;
    }

    bufferDotsGeom.addAttribute('position', attributePositions);

    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        texture: {
          value: dotTexture
        }
      },
      vertexShader: shader1,
      fragmentShader: shader2,
      transparent:true
    });
    const dots = new THREE.Points(bufferDotsGeom, shaderMaterial);

    function render(a) {
      dots.geometry.verticesNeedUpdate = true;
      dots.geometry.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    }

    function onResize() {
      canvas.style.width = '';
      canvas.style.height = '';
      width = canvas.offsetWidth;
      height = canvas.offsetHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    }

    const mouse = new THREE.Vector2(0.8, 0.5);

    function onMouseMove(e) {
      mouse.x = (e.clientX / window.innerWidth) - 0.5;
      mouse.y = (e.clientY / window.innerHeight) - 0.5;
      TweenMax.to(dots.rotation, 3, {
        x : (mouse.y * Math.PI * 0.2),
        z : (mouse.x * Math.PI * 0.1),
        ease:Power1.easeOut
      });
    }

    TweenMax.ticker.addEventListener("tick", render);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("resize", function(){
      resizeTm = clearTimeout(resizeTm);
      resizeTm = setTimeout(onResize, 200);
    });

    scene.add(dots);
  }

  render() {
    return(
      <canvas className="scene scene__full" id="scene"></canvas>
    );
  }
}


import React from "react";

const TitleAndText = ({ data }) => {
  return (
    <section id={data.title.toLowerCase()} className="cases-about">
      <h2 className="h1">{data.title}</h2>
      <p className="text__theme__color__gray cases-paragraph">{data.text}</p>
    </section>
  );
};

export default TitleAndText;

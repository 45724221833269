import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { DistributorsIntro } from './DistributorsIntro'
import { DistributorsHowItWorks } from './DistributorsHowItWorks'
import { DistributorsEnterpreneur } from './DistributorsEnterpreneur'
import { DistributorsForm } from './DistributorsForm'
import DistributorsPersons from './DistributorsPersons'
import { AboutIcon } from '../../partials/Icons'
import { itemReavealDuration, itemReavealDelay, laptop } from '../../variables'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'

const Reveal = new ItemReveal()

interface Props extends WithTranslation {
    data: {
        settings: any
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

interface State {}
class Distributors extends PureComponent<Props, State> {
    touches: any

    componentDidMount() {
        const TouchEvents = require('../../TouchEvents').default

        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
        }

        Reveal.init()
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta property="og:url" content="/for-distributors" />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <section className="grid__sidebar grid__sidebar-main">
                            <ul className="sidebar-list">
                                <li>
                                    <AnchorLink
                                        offset="200"
                                        href="#the-story"
                                        className="sidebar-link j-anchor-link"
                                    >
                                      {this.props.t('distributors.leftLinks.link1')}
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        href="#easy-to-start"
                                        className="sidebar-link j-anchor-link"
                                    >
                                      {this.props.t('distributors.leftLinks.link2')}
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset="200"
                                        href="#tips"
                                        className="sidebar-link j-anchor-link"
                                    >
                                        {this.props.t('distributors.leftLinks.link3')}
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        href="#contacts"
                                        className="sidebar-link j-anchor-link"
                                    >
                                      {this.props.t('distributors.leftLinks.link4')}
                                    </AnchorLink>
                                </li>
                            </ul>
                        </section>
                        <section className="grid__content grid__content-main">
                            <DistributorsIntro seo={this.props.seo} />
                            <div className="wide-block wide-block__theme__bg__blue wide-block__theme__padding_top">
                                <Fade
                                    duration={itemReavealDuration}
                                    delay={700}
                                    bottom
                                >
                                    <div className="distributors__text-wrap is-mb-30">
                                        <p className="distributors__big-text">
                                          {this.props.t('distributors.text1')}
                                          <br />
                                          {this.props.t('distributors.text2')}
                                        </p>
                                    </div>
                                    <AnchorLink
                                        href="#contacts"
                                        className="link link__theme__home link__theme__color__white"
                                    >
                                      {this.props.t('distributors.requestQeote')}
                                    </AnchorLink>
                                </Fade>
                            </div>
                            <DistributorsHowItWorks />
                            <DistributorsPersons />
                            <div className="wide-block wide-block__theme__bg__blue wide-block__theme__margin wide-block__theme__margin_top">
                                <Fade
                                    duration={itemReavealDuration}
                                    delay={700}
                                    bottom
                                >
                                    <DistributorsEnterpreneur />
                                </Fade>
                            </div>
                            <section className="section-screen" id="contacts">
                                <div className="section-screen-center">
                                    <DistributorsForm options={{
                                              title: this.props.t('distributors.formTitle'),
                                              additionalClassName: null }
                                            }
                                    />

                                </div>
                            </section>
                            <p className="distributors-seotext">
                                {this.props.seo.seoText &&
                                    this.props.seo.seoText}
                            </p>
                        </section>
                    </div>
                    <Fade
                        duration={itemReavealDuration}
                        delay={itemReavealDelay}
                        bottom
                    >
                        <div className="huge-link-wrap">
                            <Link to="/about" className="link btn__theme__link">
                                {this.props.t('distributors.aboutCompany')}
                            </Link>
                            <AboutIcon />
                        </div>
                    </Fade>
                </main>
                <Footer settings={this.props.data.settings} />
            </>
        )
    }
}

export default withTranslation()(Distributors);

import React from "react";
import Fade from "react-reveal/Fade";

import { itemReavealDuration, itemReavealDelay } from "../../variables";
import { Step, Solar, Chart } from "../../partials/Icons";
import { useTranslation } from "react-i18next";

export const DistributorsHowItWorks = () => {
  const [t] = useTranslation('translation', { 'keyPrefix': 'distributors.sections.howItWork' });

  return (
    <section
      className="section-screen section-screen_margin_bottom"
      id="easy-to-start"
    >
      <div className="section-screen-center">
        <Fade duration={itemReavealDuration} bottom>
          <h2 className="h1 distributros-how-it-works-subtitle">
            {t('h1')}
          </h2>
        </Fade>
        <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
          <div className="distributors-columns-wrap">
            <div className="distributors-column">
              <Step />
              <span className="distributros-column__title">
                {t('item1.label')}
              </span>
              <p className="distributors-column__text is-gray">
                {t('item1.text')}
              </p>
            </div>
            <div className="distributors-column">
              <Solar />
              <span className="distributros-column__title">
              {t('item2.label')}
              </span>
              <p className="distributors-column__text is-gray">
                {t('item2.text')}
              </p>
            </div>
            <div className="distributors-column">
              <Chart />
              <span className="distributros-column__title">
                {t('item3.label')}
              </span>
              <p className="distributors-column__text is-gray">
                {t('item3.text')}
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
}

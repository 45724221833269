import { TweenLite } from "gsap";

export default class TouchEvents {
  constructor() {
    this.touchX = null;
    this.touchY = null;
  }

  handleTouchStart(event) {
    const firstTouch = event.touches[0];

    this.touchX = firstTouch.clientX;
    this.touchY = firstTouch.clientY;
  }

  handleTouchMove(event) {
    if ( ! this.touchX || ! this.touchY ) {
      return;
    }

    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY;
    const xDiff = this.touchX - xUp;
    const yDiff = this.touchY - yUp;

    if ( Math.abs(xDiff) > Math.abs(yDiff) ) {
      if ( xDiff > 0 ) {
        /* left swipe */
      } else {
        /* right swipe */
      }
    } else {
      if ( yDiff > 0 ) {
        TweenLite.to(
          ".header",
          0.2,
          { y: -50 }
        );
      } else {
        TweenLite.to(
          ".header",
          0.2,
          { y: 0 }
        );
      }
    }

    /* reset values */
    this.touchX = null;
    this.touchY = null;
  }
}

import Throttle from "lodash/throttle";
import { easeName, animationDuration } from "./variables";
const menuBias = 900;

export default class ItemReveal {
  init() {
    if (typeof window === 'undefined') { return }

    this.caps = document.querySelectorAll(".cap");
    this.wideBlocks = document.querySelectorAll(".wide-block");

    if (this.caps.length) {
      window.addEventListener("scroll", this.handleCapsScroll);
      this.handleCapsScroll();
    }

    if (this.wideBlocks.length) {
      window.addEventListener("scroll", this.handleBlocksScroll);
      this.handleBlocksScroll();
    }
  }

  handleCapsScroll = Throttle(() => {
    const gsap = require('gsap');

    this.caps.forEach(item => {
      const rect = item.getBoundingClientRect();

      if (rect.top <= window.pageYOffset + menuBias) {
        gsap.TweenLite.to(
          item,
          animationDuration,
          { width: 0, delay: 0.2, ease: easeName() }
        );
      }
    });
  }, 500);

  handleBlocksScroll = Throttle(() => {
    this.wideBlocks.forEach(block => {
      const rect = block.getBoundingClientRect();

      if (rect.top <= window.pageYOffset + menuBias) {
        block.classList.add("is-visible");
      }
    });
  }, 500);

  removeListener() {
    window.removeEventListener("scroll", this.handleCapsScroll);
    window.removeEventListener("scroll", this.handleBlocksScroll);
  }
}

import React, { useEffect, useState } from 'react'
import { Select } from '../../Select'
import { Option } from '../../../components/Select'
import { Case } from './index'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props extends WithTranslation {
    cases: Case[]
    filter: (industry: Option, product: Option) => void
}

const CasesFilter = (props: Props) => {
    const { cases, filter } = props
    const [industries, setIndustries] = useState<Option[]>()
    const [products, setProducts] = useState<Option[]>()
    const [industryActive, setIndustryActive] = useState<Option>()
    const [productActive, setProductActive] = useState<Option>()

    useEffect(() => {
        const options = getOptions(cases)
        setIndustries(options.industries)
        setProducts(options.products)
        const industryActive = options.industries[0]
        const productActive = options.products[0]
        setIndustryActive(industryActive)
        setProductActive(productActive)
        filter(industryActive, productActive)
    }, [])

    function getOptions(cases: Case[]): {
        industries: any
        products: any
    } {
        let industries = new Set<string>()
        let products = new Set<string>()

        const allIndustries = JSON.stringify(
            Object.assign(
                {},
                {
                    value: props.t('cases.filters.industries'),
                    label: props.t('cases.filters.industries'),
                    active: true,
                }
            )
        )
        industries.add(allIndustries)

        const allProducts = JSON.stringify(
            Object.assign(
                {},
                {
                    value: props.t('cases.filters.products'),
                    label: props.t('cases.filters.products'),
                    active: true,
                }
            )
        )
        products.add(allProducts)

        for (let i = 0; i < cases.length; i++) {
            const industry = JSON.stringify(
                Object.assign(
                    {},
                    {
                        value: cases[i].industry,
                        label: cases[i].industry,
                        active: i === 0 ? true : false,
                    }
                )
            )
            industries.add(industry)

            const product = JSON.stringify(
                Object.assign(
                    {},
                    {
                        value: cases[i].equipment,
                        label: cases[i].equipment,
                        active: i === 0 ? true : false,
                    }
                )
            )
            products.add(product)
        }

        const optionsUnique = {
            industries: Array.from(industries).map((industry, i) =>
                Object.assign({ id: i }, JSON.parse(industry))
            ),
            products: Array.from(products).map((product, j) =>
                Object.assign({ id: j }, JSON.parse(product))
            ),
        }

        return optionsUnique
    }

    function handleIndustrySelect(industry) {
        setIndustryActive(industry)
        filter(industry, productActive)
    }

    function handleProductSelect(product) {
        setProductActive(product)
        filter(industryActive, product)
    }

    function resetHandler() {
        setIndustryActive(industries[0])
        setProductActive(products[0])
        filter(industries[0], products[0])
    }

    return (
        <div className="cases-filter">
            <div className="cases-filter__select">
                {industries && (
                    <Select
                        name="test"
                        options={industries}
                        onSelect={handleIndustrySelect}
                        label=""
                        value={industryActive.value}
                        defaultValue="All industries"
                    />
                )}
            </div>
            <div className="cases-filter__select">
                {products && (
                    <Select
                        name="test"
                        options={products}
                        onSelect={handleProductSelect}
                        label=""
                        value={productActive.value}
                        defaultValue="All products"
                    />
                )}
            </div>
            <div className="cases-filter__reset" onClick={resetHandler}>
                Reset filter
            </div>
        </div>
    )
}

export default withTranslation()(CasesFilter);

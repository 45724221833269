import axios from 'axios';


export const Api = axios.create({
  baseURL: '/api/v1'
});

export const Language = () => {
  if (typeof window === 'undefined') { return }

  const params = (new URL(document.location)).searchParams;
  const locale = params.get('locale');

  return locale;
};

import React, { PureComponent } from "react";
import Scrollbars from "react-custom-scrollbars";
import { easeName, laptop, gMapsStyle } from "../variables";
import AutoComplete from "./Autocomplete";
import { withTranslation } from 'react-i18next';

let gMaps = null;
let TimelineMax = null;
let TweenLite = null;

class MapAddresses extends PureComponent {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      gMaps = require("google-maps");
      gMaps.KEY = "AIzaSyAFztDppNhshxnK93FJkzUuem8oqKDtu0s";
      TimelineMax = require("gsap").TimelineMax;
      TweenLite = require("gsap").TweenLite;
      isMapOpen = window.innerWidth > laptop
    }

    const distributors = this.props.distributors;
    let countries = [];
    let isMapOpen = true;

    distributors.map((distributor) => {
      if (countries.includes(distributor.country)) return;

      countries.push(distributor.country);
    });

    this.state = {
      isMapOpen: isMapOpen,
      distributors: distributors,
      countries: countries,
      center: { lat: 43.300562, lng: -73.667922 },
      zoom: 2,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.center !== this.state.center) {
      this.setMap();
    }
  }

  componentDidMount() {
    this.setMap();
    this.toggleMap();
  }

  // fetchData = async () => {
  //   const response = await Api.get(`/distributors`);
  //   const distributorsHandler = ({
  //     attributes: { address, country, city, latitude, longitude },
  //   }) => ({
  //     address,
  //     country,
  //     city,
  //     latitude,
  //     longitude,
  //   });
  //   const distributors = response.data.data.map(distributorsHandler);
  //   let countries = [];
  //   distributors.map((distributor) => {
  //     if (countries.includes(distributor.country)) return;

  //     countries.push(distributor.country);
  //   });

  //   this.setState(
  //     { distributors, countries, isMapOpen: window.innerWidth > laptop },
  //     () => {
  //       this.setMap();
  //       this.toggleMap();
  //     }
  //   );
  // };

  setCenter = (center) => {
    this.setState({
      center,
    });
  };

  setZoom = (zoom) => {
    this.setState({
      zoom,
    });
  };

  addressClickHandler = (address) => {
    const center = { lat: address.latitude, lng: address.longitude };
    this.setCenter(center);
    this.setZoom(10);
    this.setMap();
  };

  setMap = () => {
    gMaps.load(async (google) => {
      const map = await new google.maps.Map(document.getElementById("map"), {
        center: this.state.center,
        zoom: this.state.zoom,
        styles: gMapsStyle,
        disableDefaultUI: true,
      });

      this.state.distributors.map((distributor) => {
        const marker = new google.maps.Marker({
          position: { lat: distributor.latitude, lng: distributor.longitude },
          icon: "/map_elements/map-marker.svg",
          title: distributor.address,
        });

        const infowindow = new google.maps.InfoWindow({
          content: distributor.address,
        });

        google.maps.event.addListener(marker, "click", function () {
          infowindow.open(map, marker);
        });

        marker.setMap(map);
      });
    });
  };

  toggleMap = () => {
    TweenLite.to(".home-map__country", 0.1, { opacity: 0 });

    if (this.state.isMapOpen) {
      TweenLite.to(".j-map-list", 0.8, {
        width: window.innerWidth > laptop ? "40%" : "0%",
        ease: easeName,
        onComplete: () => {
          const tlItemAnimation = new TimelineMax({});

          tlItemAnimation.staggerTo(
            ".home-map__country",
            0.5,
            { width: "100%", opacity: 1, ease: easeName },
            0.2
          );
        },
      });
      TweenLite.to(".j-map-container", 0.5, {
        width: window.innerWidth > laptop ? "58%" : "100%",
        opacity: 1,
        ease: easeName,
      });
      this.setState({ isMapOpen: false });
    } else {
      TweenLite.to(".j-map-list", 0.6, {
        width: "100%",
        ease: easeName,
        onComplete: () => {
          const tlItemAnimation = new TimelineMax({});

          tlItemAnimation.staggerFromTo(
            ".home-map__country",
            0.4,
            { xPercent: -100 },
            {
              width: window.innerWidth > laptop ? "33.333%" : "100%",
              opacity: 1,
              xPercent: 0,
              ease: Power2.easeOut,
            },
            0.08
          );
        },
      });
      TweenLite.to(".j-map-container", 0.5, {
        width: 0,
        opacity: 0,
        ease: easeName,
      });
      this.setState({ isMapOpen: true });
    }
  };

  searchCountry = (input) => {
    const { countries, distributors } = this.state;
    const request = input.toString();
    const country = countries.find((c) => c === request);
    const distributor = distributors.find(
      (distributor) => distributor.country === country
    );
    if (distributor) {
      const center = { lat: distributor.latitude, lng: distributor.longitude };
      this.setCenter(center);
      this.setZoom(4);
      this.setMap();
    }
  };

  render() {
    const { distributors, countries, center, zoom } = this.state;
    return (
      <React.Fragment>
        <div className="h1 h1__theme__map__switch">
          <span className="cap-parent is-blue">
            {this.props.t('map.title')}
            <span className="cap"></span>
          </span>
          <div className="map-switch-wrap cap-parent">
            <div className="cap"></div>
            <div className="map-switch">
              <span className="map-switch__label">
                {this.props.t('map.switch_label')}
              </span>
              <input
                type="checkbox"
                className="map-switch__input"
                onChange={this.toggleMap}
                checked={!this.state.isMapOpen}
              />
            </div>
            <div className="map-location-wrap">
              <AutoComplete
                suggestions={countries}
                changed={this.searchCountry}
              />
            </div>
          </div>
        </div>
        <div className="home-map cap-parent">
          <div className="cap"></div>
          <div
            className="home-map__list j-map-list"
            onWheel={(event) => {
              event.stopPropagation();
            }}
          >
            <Scrollbars
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
            >
              {countries.length ? (
                <Address
                  countries={countries}
                  cities={distributors}
                  clicked={this.addressClickHandler}
                />
              ) : null}
            </Scrollbars>
          </div>
          <div
            id="map"
            className="map-container j-map-container"
            onWheel={(event) => {
              event.stopPropagation();
            }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}

export const Address = ({ countries, cities, clicked }) => {
  const cityTemplate = (country) => {
    return cities.map((city, index) => {
      if (city.country === country) {
        return (
          <li
            onClick={() => clicked(city)}
            className="home-map__country-item"
            key={index}
          >
            <span className="home-map__country-city">{city.city}</span>
            <span className="home-map__country-address">{city.address}</span>
          </li>
        );
      }
    });
  };
  const countryTemplate = () => {
    return countries.map((country, index) => {
      return (
        <div className="home-map__country" key={`country-${index}`}>
          <span className="home-map__country-title">{country}</span>
          <ul className="home-map__country-list">{cityTemplate(country)}</ul>
        </div>
      );
    });
  };

  return <div className="home-map__country-wrap">{countryTemplate()}</div>;
};

export default withTranslation()(MapAddresses);

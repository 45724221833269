import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

import { itemReavealDuration } from '../../variables'

import experience from '../../../media/service/experience.png'
import multiplatform from '../../../media/service/multiplatform.png'
import support from '../../../media/service/support.png'
import remoteUpdate from '../../../media/service/remoteUpdate.png'
import training from '../../../media/service/training.png'
import warranty from '../../../media/service/warranty.png'
import { useTranslation } from 'react-i18next'

export const ServiceIntro = ({ seo }) => {
    const [t] = useTranslation();

    return (
      <React.Fragment>
          <section className="section-screen">
              <div className="section-screen-center">
                  <Fade duration={itemReavealDuration} bottom>
                      <nav
                          className="breadcrumbs"
                          itemScope
                          itemType="https://schema.org/BreadcrumbList"
                      >
                          <Link
                              to="/"
                              className="breadcrumb-link"
                              itemProp="itemListElement"
                              itemScope
                              itemType="https://schema.org/ListItem"
                          >
                              <span itemProp="name">{t('breadcrumbs.main')}</span>
                          </Link>
                          <span
                              className="breadcrumb__current"
                              itemProp="itemListElement"
                              itemScope
                              itemType="https://schema.org/ListItem"
                          >
                              <span itemprop="name">{t('breadcrumbs.service')}</span>
                          </span>
                      </nav>
                      <h1 className="h1 h1__theme__inner">
                          With Vibro-Laser you are never alone
                      </h1>
                      <span className="distributors-intro">
                          Just pick up the phone and we are there to support you.
                      </span>
                      <div className="distributors-cubes-wrap">
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  20 years of experience
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      We have been developing the precision laser
                                      equipment since 1990s
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={experience}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  Multiplatform solution
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      We are the first to offer a program that
                                      works with iOS, Android, Windows
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={multiplatform}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  A full two-year warranty
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      We guarantee product quality and offer a
                                      warranty which is valid all over the world
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={warranty}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  We provide training
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      You can learn from us at the New York office
                                      or from our distributors across the globe
                                  </p>
                                  <div className="illustrate-cont">
                                      <div className="illustrate-cont">
                                          <img
                                              className="illustrate"
                                              src={training}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  24 hour technical support
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      Regardless of your time zone, you will
                                      receive any help you need
                                  </p>
                                  <div className="illustrate-cont">
                                      <img className="illustrate" src={support} />
                                  </div>
                              </div>
                          </div>
                          <div className="distributors-cube cap-parent">
                              <div className="cap"></div>
                              <span className="distributors-cube__title">
                                  Remote update
                              </span>
                              <div className="illustrate-wrap">
                                  <p className="distributors-cube__text">
                                      All you need to get the new version of your
                                      software is internet connection
                                  </p>
                                  <div className="illustrate-cont">
                                      <img
                                          className="illustrate"
                                          src={remoteUpdate}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </Fade>
              </div>
          </section>
      </React.Fragment>
    );
}

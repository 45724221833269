import React from 'react'
import QuestionIcon from '../../media/icons/question.png'

const imagesContext = require.context('../../media', true)

type Tooltip = {
    img: string
}
interface TooltipProps {
    tooltip: Tooltip
}

const Tooltip = (props: TooltipProps) => {
    const { tooltip } = props
    const { img } = tooltip

    return (
        <div className="tooltip">
            <div className="tooltip__button">
                <img src={QuestionIcon} />
            </div>
            <div
                className="tooltip__popup"
                style={{
                    backgroundImage: `url(${imagesContext(
                        `./tooltips/${img}`
                    )})`,
                }}
            ></div>
        </div>
    )
}

export default Tooltip

import React, { PureComponent } from "react";
import { itemReavealDuration } from "../../variables";
import Fade from "react-reveal/Fade";
import { withTranslation } from "react-i18next";

const imagesContext = require.context("../../../media", true);

class AboutFaces extends PureComponent {
  render() {
    return (
      <section className="section-screen">
        <Fade duration={itemReavealDuration} bottom>
          <h2 className="h1">{this.props.t('about.faces')}</h2>

          <div className="about-faces-wrap">
            {this.props.faces.map((face, index) => {
              return (
                <div key={index} className="about-face__container">
                  <img
                    src={imagesContext(face.image)}
                    alt=""
                    className="about-face__img"
                  />
                  <p className="about-face__title">{face.name}</p>
                  <p className="about-face__post">{face.post}</p>
                </div>
              );
            })}
          </div>
        </Fade>
      </section>
    );
  }
}

export default withTranslation()(AboutFaces);

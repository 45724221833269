import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import classnames from 'classnames'
import Fade from 'react-reveal/Fade'
import useForm from './useForm'
import formModel from './formModel'
import { itemReavealDuration, itemReavealDelay } from '../../variables'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../partials/Tooltip'

const IconSuccess = require('../../../media/svg/callback-success.svg')

const formTypes = ['customers', 'distributors', 'support']

export const DistributorsForm = ({ options }) => {
    const [formSubmit, setFormSubmit] = useState(false)
    const [t] = useTranslation()

    const handleSubmit = (inputs) => {
        let data = new FormData()

        inputs.map((input) => {
            input.value && data.append(input.name, input.value)
        })

        return new Promise((resolve, reject) => {
            fetch('/api/v1/feedbacks', {
                method: 'POST',
                body: data,
            })
                .then((resp) => {
                    if (resp.status === 200) {
                        setFormSubmit(true)
                        resolve('ok')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    reject(new Error(error.message))
                })
        })
    }

    const FormTemplate = () => {
        const [inputs, setInputs, setSubmit] = useForm(formModel, handleSubmit)
        const [formTypeIndex, setFormTypeIndex] = useState(0)
        const Components = {
            TextInput,
            TelInput,
            FileInput,
            TextareaInput,
            CheckboxInput,
        }
        const capitalize = (expression) =>
            expression.charAt(0).toUpperCase() + expression.slice(1)

        const renderInput = (input) => {
            const placehlderValue = t(
                `distributors_form.inputs.${input.name}.label`
            )

            input.label = placehlderValue

            const Component = Components[capitalize(input.type) + 'Input']
            return <Component setInputs={setInputs} {...input} />
        }

        return (
            <Fade
                duration={itemReavealDuration}
                delay={itemReavealDelay}
                bottom
            >
                <div className="service-tab">
                    <ul className="react-tabs__tab-list" role="tablist">
                        {formTypes.map((type, idx) => {
                            const className = classnames('react-tabs__tab', {
                                'react-tabs__tab--selected':
                                    formTypeIndex === idx,
                            })

                            return (
                                <li
                                    className={className}
                                    onClick={() => setFormTypeIndex(idx)}
                                    role="tab"
                                    key={type}
                                >
                                    {t(`distributors_form.types.${type}`)}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <form id="distributorsForm" className="distributors-form">
                    <div className="form-row">
                        {inputs.map((input) => {
                            const className = classnames('form-column', {
                                'form-column__wide':
                                    input.type === 'textarea' ||
                                    input.type === 'checkbox',
                            })

                            if (!input.formTypeIndex.includes(formTypeIndex))
                                return null

                            return (
                                <div className={className} key={input.name}>
                                    <div className="form-group">
                                        {renderInput(input)}
                                    </div>
                                    {input.tooltip && (
                                        <Tooltip tooltip={input.tooltip} />
                                    )}
                                </div>
                            )
                        })}
                        <input
                            type="hidden"
                            name="url"
                            value={
                                typeof window === 'undefined'
                                    ? '/'
                                    : window.location.pathname
                            }
                        />
                        <input type="hidden" name="city" />
                    </div>
                    <div className="form-row">
                        <button
                            className="btn btn__theme__link"
                            onClick={setSubmit}
                        >
                            {t('distributors_form.send')}
                        </button>
                    </div>
                </form>
            </Fade>
        )
    }
    const ResponseTemplate = () => {
        const [t] = useTranslation()

        return (
            <Fade
                duration={itemReavealDuration}
                delay={itemReavealDelay}
                bottom
            >
                <h2 className="h1">{t('distributors_form.response.h1')}</h2>

                <span className="form__small-text">
                    {t('distributors_form.response.text')}
                </span>
                <ReactSVG
                    src={IconSuccess}
                    beforeInjection={(svg) => svg.classList.add('form__icon')}
                />
            </Fade>
        )
    }

    return (
        <div
            className={
                options.additionalClassName ? options.additionalClassName : null
            }
        >
            <Fade duration={itemReavealDuration} bottom>
                <span className="h1 form__title">{options.title}</span>
            </Fade>
            {formSubmit ? <ResponseTemplate /> : <FormTemplate />}
        </div>
    )
}

export const TextInput = ({ name, label, type, value, alert, setInputs }) => {
    const placeholderClassName = classnames('input-default__placeholder', {
        'input-default__placeholder_active': value,
    })

    const inputClassName = classnames('input-default', {
        'input-default_error': alert,
    })

    return (
        <React.Fragment>
            <input
                id={name}
                name={name}
                type={type}
                value={value || ''}
                onChange={setInputs}
                className={inputClassName}
            />
            <span className={placeholderClassName}>{label}</span>
            {alert && <ValidationAlert content={alert} />}
        </React.Fragment>
    )
}

export const TelInput = ({ name, label, type, value, alert, setInputs }) => {
    const placeholderClassName = classnames('input-default__placeholder', {
        'input-default__placeholder_active': value,
    })

    const inputClassName = classnames('input-default', {
        'input-default_error': alert,
    })

    return (
        <React.Fragment>
            <input
                id={name}
                name={name}
                type={type}
                value={value || ''}
                onChange={setInputs}
                className={inputClassName}
            />
            <span className={placeholderClassName}>{label}</span>
            {alert && <ValidationAlert content={alert} />}
        </React.Fragment>
    )
}

export const FileInput = ({ name, label, type, value, alert, setInputs }) => {
    const inputClassName = classnames('input-file', {
        'input-default_error': alert,
    })
    return (
        <>
            <input
                id={name}
                name={name}
                type={type}
                value={''}
                onChange={setInputs}
                className={inputClassName}
                data-label={label}
            />

            {value && (
                <span className="input-file__filename">
                    File attached: {value.name}
                </span>
            )}
        </>
    )
}

export const TextareaInput = ({
    name,
    label,
    type,
    value,
    alert,
    setInputs,
}) => {
    const placeholderClassName = classnames('input-default__placeholder', {
        'input-default__placeholder_active': value,
    })

    const textareaClassName = classnames('input-default', {
        'input-default_error': alert,
    })

    return (
        <div>
            <textarea
                id={name}
                name={name}
                value={value || ''}
                onChange={setInputs}
                className={textareaClassName}
            />
            <span className={placeholderClassName}>{label}</span>
            {alert && <ValidationAlert content={alert} />}
        </div>
    )
}

export const CheckboxInput = ({
    name,
    label,
    type,
    value,
    alert,
    setInputs,
}) => {
    const [t] = useTranslation()

    return (
        <div>
            <input
                id={name}
                name={name}
                type={type}
                value={value}
                checked={value || false}
                onChange={setInputs}
                className="input-checkbox"
            />
            <span className="input-checkbox-mark" />
            <label htmlFor="agreement" className="checkbox-label">
                {label}{' '}
                <a href="/docs/policy.pdf" className="link link__theme__policy">
                    {t('distributors_form.terms')}
                </a>{' '}
                {t('distributors_form.and')}{' '}
                <a href="/docs/policy.pdf" className="link link__theme__policy">
                    {t('distributors_form.privacy')}
                </a>
                .
            </label>
            {alert && <ValidationAlert content={alert} />}
        </div>
    )
}

export const ValidationAlert = ({ content }) => (
    <Fade duration={itemReavealDuration} top>
        <div className="error-message">{content}</div>
    </Fade>
)

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { itemReavealDuration, itemReavealDelay } from "../../variables";
import img7 from "../../../media/home/img7.png";
import img8 from "../../../media/home/img8.png";
import img9 from "../../../media/home/img9.png";
import { withTranslation } from "react-i18next";

class ProductUseIt extends PureComponent {
  render() {
    return (
      <section id="useIT" className="section-screen j-anchor-container">
        <div className="section-screen-center">
          <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
            <span className="h1">Who already use it</span>
            <p className="useit-anons">
              We ask our clients to say some words if our equipment helped them
              to reduce costs or make life better. How it reduces clients’costs?
              What for people usually use that equipment?
            </p>
            <button className="btn btn__theme__link product-btn btn__theme__mb__big is-blue">
              {this.props.t('requestQeote')}
            </button>
            <button className="btn btn__theme__link product-btn btn__theme__mb__big">
              PRINT VIEW
            </button>
            <div className="home-sections-wrap">
              <Link to="#" className="home-section cap-parent">
                <div className="cap"></div>
                <div className="home-section__bg-container">
                  <div
                    className="home-section__bg"
                    style={{ backgroundImage: `url(${img7})` }}
                  ></div>
                </div>
                <div className="home-section__content-container">
                  <span className="home-section__title">General Electric</span>
                  <span className="home-section__anons">
                    Use Laser shaft alignment (VLSAT) to improve their equipment
                    and save energy
                  </span>
                </div>
              </Link>
              <Link to="#" className="home-section cap-parent">
                <div className="cap"></div>
                <div className="home-section__bg-container">
                  <div
                    className="home-section__bg"
                    style={{ backgroundImage: `url(${img8})` }}
                  ></div>
                </div>
                <div className="home-section__content-container">
                  <span className="home-section__title">Crysler</span>
                  <span className="home-section__anons">
                    Use Laser shaft alignment (VLSAT) to improve their equipment
                    and save energy
                  </span>
                </div>
              </Link>
              <Link to="#" className="home-section cap-parent">
                <div className="cap"></div>
                <div className="home-section__bg-container">
                  <div
                    className="home-section__bg"
                    style={{ backgroundImage: `url(${img9})` }}
                  ></div>
                </div>
                <div className="home-section__content-container">
                  <span className="home-section__title">USAA</span>
                  <span className="home-section__anons">
                    Use Laser shaft alignment (VLSAT) to improve their equipment
                    and save energy
                  </span>
                </div>
              </Link>
            </div>
            <div className="long-link-wrap">
              <Link to="/cases" className="link link__theme__home">
                {this.props.t('see-more')}
              </Link>
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ProductUseIt);

import React from "react";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

import { itemReavealDuration, itemReavealDelay } from "../../variables";


import img1 from "../../../media/about/1.jpg";
import img2 from "../../../media/about/2.jpg";
import img3 from "../../../media/about/3.jpg";
import img4 from "../../../media/about/4.jpg";
import img5 from "../../../media/about/5.jpg";
import img6 from "../../../media/about/6.jpg";

export const AboutCommunity = () => {
  const [t] = useTranslation();

  return (
    <section className="section-screen">
      <Fade duration={itemReavealDuration} bottom>
        <h2 className="h1">{t('about.community.h1')}</h2>
      </Fade>
      <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
        <div className="about-community">
          <div className="about-community__item item-1">
            <img src={img1} />
          </div>
          <div className="about-community__item item-2">
            <img src={img2} />
          </div>
          <div className="about-community__item item-3">
            <p className="text__theme__color__gray">{t('about.community.text1')}</p>
          </div>
          <div className="about-community__item item-4">
            <p className="text__theme__color__gray">{t('about.community.text2')}</p>
          </div>
          <div className="about-community__item item-5">
            <img src={img3} />
          </div>
        </div>
      </Fade>
      <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
        <div className="about-community-carousel">
          <div className="about-community-carousle__item">
            <img src={img4} />
          </div>
          <div className="about-community-carousle__item">
            <img src={img5} />
          </div>
          <div className="about-community-carousle__item">
            <img src={img6} />
          </div>
        </div>
      </Fade>
    </section>
  );
}

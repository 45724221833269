import React from "react";
import { useTranslation } from "react-i18next";
const imagesContext = require.context("../../../media", true);

export const ServicePartners = (props) => {
  return (
    <div className="content-column">
      <div className="content-column__img-container">
        <img
          className="content-column__img"
          src={imagesContext(props.data.logo)}
          alt={props.data.person}
        />
      </div>
      <p className="content-column__text">{props.data.text}</p>
      <p className="content-column__person">{props.data.person}</p>
    </div>
  );
};

export const ServiceSetificates = (props) => {
  const [t] = useTranslation();

  return (
    <div className="content-column">
      <div className="content-column__img-container">
        <img
          className="content-column__img"
          src={imagesContext(props.data.logo)}
          alt={props.data.title}
        />
      </div>
      <p className="content-column__title">{props.data.title}</p>
      <p className="content-column__text">{props.data.text}</p>
      {props.withLink && (
        <div className="link-wrapper">
          <a
            className="link"
            href={props.data.link}
            target="_blank"
            rel="noindex, nofollow"
          >
            {t('link')}
          </a>
        </div>
      )}
    </div>
  );
};

const offset = 200;

export default class HighlightAnchor {
  init() {
    this.sections = document.querySelectorAll('.j-anchor-container');
    this.links = document.querySelectorAll('.j-anchor-link');

    window.addEventListener("scroll", this.setActiveClass);
  }

  setActiveClass = () => {
    const currentScroll = window.scrollY;
    this.current = undefined;

    this.sections.forEach((section) => {
      const position = section.offsetTop;

      if (position - offset < currentScroll) {
        this.current = section;
      }

      if (this.current) {
        const id = this.current.getAttribute('id');

        Array.from(this.links, (link) => {
          link.classList.remove('is-active')
        });

        document.querySelector(`.j-anchor-link[href$=${id}]`).classList.add('is-active');
      }
    })
  }

  removeListener() {
    window.removeEventListener("scroll", this.setActiveClass);
  }
}

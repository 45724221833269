import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useForm = (initModel, submitCallback) => {
    const [t] = useTranslation()
    const [inputs, setInputs] = useState(initModel)

    const handleChange = (e) => {
        inputs.forEach((i) => {
            if (i.name === e.target.name) {
                i.value = e.target.value
                if (i.type === 'checkbox') {
                    i.value = e.target.checked
                }

                if (i.type === 'file') {
                    i.value = e.target.files[0]
                }
                parseInput(i)
                validateInput(i)
            }
        })
        setInputs([...inputs])
    }

    const submitHelper = () => {
        let clonedInputs = Object.assign([], inputs)
        submitCallback(clonedInputs).then((resp) => {
            if (resp === 'ok') {
                // clearInputs()
            }
        })
    }

    const clearInputs = () => {
        const clearInputs = inputs.map((i) => {
            i.value = ''
            return i
        })

        setInputs([...clearInputs])
    }

    const handleSubmit = (e) => {
        console.log('Submit::inputs:: ', inputs)
        e && e.preventDefault()
        inputs.forEach((i) => validateInput(i))
        inputs.some((i) => i.alert) ? setInputs([...inputs]) : submitHelper()
    }

    const parseInput = (input) => {
        return (input.value = input.parseFun
            ? input.parseFun(input.value)
            : input.value)
    }

    const validateInput = (input) => {
        let alert = null

        input.validators &&
            input.validators.forEach((v) => {
                return (alert =
                    v.isValidFun && !v.isValidFun(input.value)
                        ? t(
                              `distributors_form.inputs.${input.name}.validators.${v.id}`
                          )
                        : alert)
            })

        input.alert = alert
    }

    return [inputs, handleChange, handleSubmit]
}

export default useForm

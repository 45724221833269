import React from "react";
import Fade from "react-reveal/Fade";
import { itemReavealDuration } from "../../variables";
import VideoPopup from "../../VideoPopup";

export const CaseCardResults = ({ data }) => {
  const videoContent = {
    previewSmall: data.thumb,
    preview: data.url,
    src: data.youtube_link,
  };

  return (
    <>
      <section
        id="case-results"
        className="wide-block wide-block__theme__margin_bottom"
      >
        <Fade duration={itemReavealDuration} bottom>
          <h2 className="h1">{data.title}</h2>
          <div className="cases-results-intro">
            <div className="cases-results-intro__text">
              <p className="cases-paragraph text__theme__color__gray">
                {data.text}
              </p>
              <VideoPopup content={videoContent} />
              {/* {props.case.resultsImage && (
                <img
                  src={imagesContext(props.case.resultsImage)}
                  alt={props.case.title}
                />
              )} */}
            </div>
            {/* <div className="cases-results-intro__factoid">
              <p className="cases-results-intro__factoid-title">10 000 kWh</p>
              <p className="cases-results-intro__factoid-text text__theme__color__gray">
                Company has saved energy <br />
                for 1 month while using the equipment
              </p>
            </div> */}
          </div>
          {/* <div className="distributors-columns-wrap">
            <div className="distributors-column">
              <Step />
              <span className="distributros-column__title">
                More free time for workers
              </span>
              <p className="distributors-column__text is-gray">
                What we did in short. What was needed and what the results. How
                we helped our client and what he thinks about our services.
              </p>
            </div>
            <div className="distributors-column">
              <Solar />
              <span className="distributros-column__title">
                Life of equipment
              </span>
              <p className="distributors-column__text is-gray">
                You start your business, we give some advice, invite you to the
                community and hold meetings
              </p>
            </div>
            <div className="distributors-column">
              <Chart />
              <span className="distributros-column__title">Best service</span>
              <p className="distributors-column__text is-gray">
                You make the world a better place and get paid for it. We
                improve the product to make your clients happy.
              </p>
            </div>
          </div> */}
        </Fade>
      </section>
    </>
  );
};

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ReactSVG } from 'react-svg'
import Carousel from '../../Carousel'
import { Download, Star } from '../../partials/Icons'
import Fade from 'react-reveal/Fade'
import { itemReavealDuration } from '../../variables'
import Apple from 'media/logos/apple.png'
import Google from 'media/logos/google.png'
import Windows from 'media/logos/windows.png'
import { useTranslation } from 'react-i18next'

export const ProductIntro = ({ data, partners, pdfData, seo }) => {
    const [t] = useTranslation();
    return (
        <section className="section-screen">
            <div className="section-screen-center">
                <Fade duration={itemReavealDuration} bottom>
                    <nav
                        className="breadcrumbs"
                        itemScope
                        itemType="https://schema.org/BreadcrumbList"
                    >
                        <Link
                            to="/"
                            className="breadcrumb-link"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemProp="name">{t('breadcrumbs.main')}</span>
                        </Link>
                        <Link
                            to="/products"
                            className="breadcrumb-link"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemProp="name">{t('breadcrumbs.products')}</span>
                        </Link>
                        <span
                            className="breadcrumb__current"
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            <span itemProp="name">{data.name}</span>
                        </span>
                    </nav>
                    <h1 className="h1 h1__theme__inner h1_half">
                        {seo.h1 ? seo.h1 : data.name}
                    </h1>
                </Fade>
                {data.images && data.images.length ? (
                    <Images data={data.images} seo={seo}/>
                ) : null}
                <div className="product-text-wrap">
                    <Fade duration={itemReavealDuration} bottom>
                        <div>
                            <ul className="product-features">
                                <li>
                                    <Star />{t('products.feature1')}
                                </li>
                                <li>
                                    <Star />{t('products.feature2')}
                                </li>
                            </ul>
                            <p className="product-text">{data.description}</p>
                            <div className="product-btn-wrap">
                                {data.brochure ? (
                                    <a
                                        href={data.brochure}
                                        className="link link__theme__color__blue"
                                        download
                                    >
                                        <Download />{t('accessory.brochure')}
                                    </a>
                                ) : null}

                                <a
                                    className="link link__theme__color__blue"
                                    href="/docs/quickstart.pdf"
                                    target="_blank"
                                >
                                    <Download />{t('accessory.webQuickStart')}
                                </a>

                                {data.manual ? (
                                    <a
                                        href={data.manual}
                                        className="link link__theme__color__blue"
                                        download
                                    >
                                        <Download /> {t('accessory.manual')}
                                    </a>
                                ) : null}
                            </div>
                            <div className="product-btn-wrap">
                                <AnchorLink
                                    href="#callbackSection"
                                    className="btn btn__theme__link product-btn is-black"
                                >
                                    {t('requestQeote')}
                                </AnchorLink>
                            </div>

                            <div className="product-btn-wrap">
                                <div className="product-team-logos">
                                    <a
                                        href="https://apps.apple.com/ru/app/vibro-laser/id1541666124"
                                        target="_blank"
                                    >
                                        <img src={Apple} />
                                    </a>
                                </div>
                                <div className="product-team-logos">
                                    <a
                                        href={
                                            'https://play.google.com/store/apps/details?id=com.VibroLaser.VL5PRO'
                                        }
                                        target={'_blank'}
                                    >
                                        <img src={Google} />
                                    </a>
                                </div>
                                <div className="product-team-logos">
                                    <a
                                        href="/files/VL_setup2.3.4.zip"
                                        target={'_blank'}
                                    >
                                        <img src={Windows} />
                                    </a>
                                </div>
                            </div>

                            <span className="product-team-anons">
                                {t('accessory.trustedBy')}
                            </span>
                            <div className="product-team-wrap">
                                {partners.map(PartnerIcon)}
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

const Images = ({ data, seo }) => (
    <Carousel
        className="carousel__theme__product"
        images={data}
        previews={true}
        seo={seo}
    />
)

const PartnerIcon = (item, index) => (
    <div className="product-team-logos" key={`partner-${index}`}>
        <ReactSVG
            src={item.icon}
            beforeInjection={(svg) =>
                svg.classList.add('product-team-logos__icon')
            }
        />
    </div>
)

const SocialIcon = (item, index) => (
    <div className="product-team-logos" key={`social-${index}`}>
        <a>
            <img src={item} />
        </a>
    </div>
)

import React from "react";
import Fade from "react-reveal/Fade";

import Carousel from "../../Carousel";
import { itemReavealDuration } from "../../variables";
import img1 from "../../../media/cases/cases-step.jpg";

const CAROUSEL_IMAGES = [
  {
    src: "/examples/carousel-example.jpg",
    text: "Company’s machines",
  },
  {
    src: "/examples/carousel-example.jpg",
    text: "First step",
  },
  {
    src: "/examples/carousel-example.jpg",
    text: "Second step",
  },
];

export const CaseCardDone = () => {
  return (
    <React.Fragment>
      <section id="case-done" className="wide-block">
        <h2 className="h1">What we have done</h2>
        <div className="text__theme__color__gray cases-paragraph">
          After a talk with our clients, we came to an agreement that we should
          make three apps instead of just one. We needed a comprehensive toolkit
          to help the trainers work with maximum efficiency and a simple app
          with only essential features for the clients of the service. Also, it
          was rational to make a client app for both iOS.
        </div>
        <Fade duration={itemReavealDuration}>
          <Carousel
            className="carousel__theme__cases"
            images={CAROUSEL_IMAGES}
            amount={true}
          />
        </Fade>
        <Fade duration={itemReavealDuration} bottom>
          <div className="cases-done-step cases-done-step_max-width">
            <div className="cases-done-step__text-container">
              <span className="cases-done-step__title">First step</span>
              <p className="text__theme__color__gray cases-paragraph">
                We built a web application that allows the trainers to guide
                their clients through the training course, closely tracking the
                progress and seamlessly making changes in the program if it’s
                needed. The apps for clients provide diet and progress tracking,
                meal and training plans, and communication features to update
                the trainers on the progress.
              </p>
            </div>
            <div className="cases-done-step__img-container">
              <img src={img1} className="cases-done-step__img" />
            </div>
          </div>
        </Fade>
      </section>
    </React.Fragment>
  );
};

import {Language} from "../../api";

export const Currency = ({rubPrice, usdPrice, locale}) => {
  let price;

  switch (Language() || locale) {
    case "ru":
      price = `${rubPrice}${'\u00A0'}₽`;
      break;
    case "en":
      price = `$${'\u00A0'}${usdPrice}`;
      break;
    default:
      price = `$${'\u00A0'}${usdPrice}`;
      break;
  }

  return price;
}

import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import { itemReavealDuration, itemReavealDelay } from "../../variables";
import VideoPopup from "../../VideoPopup";
import { useTranslation } from "react-i18next";

const videoContent = {
  previewSmall: "./product-card/video_prev-small.jpg",
  preview: "/examples/case-video-example.jpg",
  src: "https://youtu.be/eB-pgWAc8sg",
};

export const AboutIntro = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <section className="section-screen about-first-section">
        <div className="section-screen-center">
          <Fade duration={itemReavealDuration} bottom>
            <nav className="breadcrumbs">
              <Link to="/" className="breadcrumb-link">
                {t('breadcrumbs.main')}
              </Link>
              <span className="breadcrumb__current">{t('breadcrumbs.about')}</span>
            </nav>
            <h1 className="h1 h1__theme__inner">
            {t('about.intro.h1')}
            </h1>
            <span className="distributors-intro">
              {t('about.intro.text')}
            </span>
          </Fade>
          {/* <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
            <VideoPopup content={videoContent} />
          </Fade> */}
        </div>
      </section>
    </React.Fragment>
  )
};

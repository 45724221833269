import React, { PureComponent } from "react";
import { Triangle } from "../../partials/Icons";
import Fade from "react-reveal/Fade";
import { withTranslation } from 'react-i18next';
import {
  easeName,
  laptop,
  itemReavealDuration,
} from "../../variables";

const firstItem = 0;
let gsap = null;
class AboutValues extends PureComponent {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      gsap = require('gsap');
    }
  }

  componentDidMount() {
    if (typeof window === "undefined") { return }

    this.softwareItems = document.querySelectorAll(".j-software-list li");
    this.softwareContainers = document.querySelectorAll(".j-software-content");
    this.softwareLine = document.querySelector(".j-software-line");

    this.setBlockClass();
    this.softwareItems.forEach((item) => {
      item.addEventListener("mouseover", () => {
        this.setActiveItem(item.dataset.softwareItem);
      });
    });
    this.setActiveItem(firstItem);
    window.addEventListener("resize", this.setBlockClass);
  }

  componentWillMount() {
    if (typeof window === "undefined") { return }

    window.removeEventListener("resize", this.setBlockClass);
  }

  setBlockClass = () => {
    const blockClass =
      window.innerWidth < laptop ? "is-initial" : "is-absolute";

    this.softwareContainers.forEach((item) => {
      item.classList.remove("is-initial");
      item.classList.remove("is-absolute");
      item.classList.add(blockClass);
    });
  };

  setActiveItem = (index) => {
    this.softwareItems.forEach((item) => {
      item.classList.remove("is-active");
    });
    this.softwareContainers.forEach((item) => {
      item.classList.remove("is-active");
    });
    this.softwareItems[index].classList.add("is-active");
    this.softwareContainers[index].classList.add("is-active");

    gsap.TweenLite.to(this.softwareLine, 0, {
      y: this.softwareItems[index].offsetTop,
      ease: easeName,
    });
  };

  render() {
    return (
      <section className="section-screen">
        <Fade duration={itemReavealDuration} bottom>
          <div className="section-screen-center">
            <h2 className="h1">{this.props.t('about.aboutValues.h1')}</h2>
            <div className="software-section">
              <div className="software-items-wrap">
                <ul className="software-items j-software-list">
                  <li className="software-item" data-software-item="0">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item1')}
                    </span>
                  </li>
                  <li className="software-item" data-software-item="1">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item2')}
                    </span>
                  </li>
                  <li className="software-item" data-software-item="2">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item3')}
                    </span>
                  </li>
                  <li className="software-item" data-software-item="3">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item4')}
                    </span>
                  </li>
                  <li className="software-item" data-software-item="4">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item5')}
                    </span>
                  </li>
                  <li className="software-item" data-software-item="5">
                    <span className="software-item__title-no-icon">
                      {this.props.t('about.aboutValues.softwareSection.item6')}
                    </span>
                  </li>
                  <div className="section-slider-line j-software-line"></div>
                </ul>
                <div className="software-slider">
                  <Triangle />
                  <Triangle />
                </div>
              </div>
              <div className="software-container">
                {/* item-0 */}
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item1.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item1.text')}
                    </p>
                  </div>
                </div>
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item2.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item2.text')}
                    </p>
                  </div>
                </div>
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item3.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item3.text')}
                    </p>
                  </div>
                </div>
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item4.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item4.text')}
                    </p>
                  </div>
                </div>
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item5.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item5.text')}
                    </p>
                  </div>
                </div>
                <div className="software-content j-software-content">
                  <div className="software-content__left-part">
                    <h3 className="h2 software-content__title">
                      {this.props.t('about.aboutValues.softwareContainer.item6.title')}
                    </h3>
                    <p>
                      {this.props.t('about.aboutValues.softwareContainer.item6.text')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default withTranslation()(AboutValues);

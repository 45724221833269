import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Footer = ({ settings }) => {
    const [t] = useTranslation();

    const [data, setData] = useState({
        attributes: settings,
        relationships: settings.social_networks,
    })

    const renderEmail = () => {
        return (
            <a
                href={`mailto:${data.attributes.email}`}
                className="footer__contacts-link footer__link__theme__last"
            >
                <span className="cap-parent">
                    {data.attributes.contact_email}
                </span>
            </a>
        )
    }

    const renderPhone = () => {
        return (
            <a
                href={`tel:${data.attributes.phone}`}
                className="footer__contacts-link"
            >
                <span className="cap-parent">{data.attributes.phone}</span>
            </a>
        )
    }

    const renderSocials = () => {
        return data.relationships.map((social) => {
            return (
                <li key={`footer-social-${social.id}`}>
                    <a
                        href={social.url}
                        className="footer__icon"
                        rel="noindex, nofollow"
                        target="_blank"
                    >
                        <ReactSVG src={social.icon} />
                    </a>
                </li>
            )
        })
    }

    return (
        <footer className="footer cap__theme__white">
            <div className="footer__row">
                <div className="footer__select-title footer__width-space cap-parent">
                    <span className="cap-parent">
                        {t('footer.letsTolk')}
                        <span className="cap"></span>
                    </span>
                </div>
            </div>
            <div className="footer__row">
                <div className="footer__width-space">
                    {data.attributes.phone ? renderPhone() : null}
                </div>
                {data.attributes.contact_email ? renderEmail() : null}
            </div>

            <div className="footer__row">
                <nav className="footer__navigation">
                    <Link to="/products" className="footer__link">
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.products')}
                        </span>
                    </Link>
                    <Link to="/about" className="footer__link">
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.about')}
                        </span>
                    </Link>
                    <Link to="/cases" className="footer__link">
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.articles')}
                        </span>
                    </Link>
                    <a
                        href="https://ervlusa.synology.me:5001/"
                        target="_blank"
                        className="footer__link"
                        rel="noindex, nofollow"
                    >
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.distributorPortal')}
                        </span>
                    </a>

                    <Link to="/for-distributors" className="footer__link">
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.forDistributors')}
                        </span>
                    </Link>

                    <Link to="/service" className="footer__link">
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.service')}
                        </span>
                    </Link>

                    <a
                        href="/docs/policy.pdf"
                        target="_blank"
                        className="footer__link"
                    >
                        <span className="cap-parent">
                            <span className="cap"></span>
                            {t('footer.privacy')}
                        </span>
                    </a>
                </nav>
            </div>

            <div className="footer__row-copyright">
                <div className="footer__address-wrap">
                    <ul className="footer__socials">
                        {data.relationships.length ? renderSocials() : null}
                    </ul>
                    <address className="footer__address">
                        {data.attributes.address}
                    </address>
                </div>
                <div className="footer__copyrights-wrap">
                    <div className="footer__copyright">{t('footer.vibro')} {new Date().getFullYear()}</div>
                    <div className="footer__copyright">{t('footer.rights')}</div>
                    <div className="footer__copyright">
                        <a
                            href="https://molinos.ru"
                            className="footer__link-away"
                            target="_blank"
                            rel="noindex, nofollow"
                        >
                            {t('footer.madeIn')}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import { Burger, Triangle } from './Icons';
import logo from 'media/logos/logo.png';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Api } from '../../api';

const DropdownIndicator = ({ children, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <Triangle />
    </components.DropdownIndicator>
  );
};

let lastScrollTop = 0;

interface State {
  selectedOption: any;
}
interface Props extends WithTranslation {
}
class Header extends Component<Props, State> {
    submenu: HTMLElement;
    header: HTMLElement;
    burgerDesktop: HTMLElement;
    burgerMobile: HTMLElement;

    state = {
      selectedOption: {
        value: this.props.i18n.language,
        label: this.props.t('localeLabel')
      },
    };

    componentDidMount() {
        window.addEventListener('scroll', this.toggleMenuVisibility);
    }

    componentDidUpdate() {
        if (this.submenu.classList.contains('is-opened')) {
            this.toggleSubmenu();
        }
    }

    getHeaderRef = (node) => (this.header = node);
    getSubmenuRef = (node) => (this.submenu = node);
    getBurgerDesktopRef = (node) => (this.burgerDesktop = node);
    getBurgerMobileRef = (node) => (this.burgerMobile = node);

    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
      // this.props.i18n.changeLanguage(selectedOption.value);
      var csrf_token = document.querySelector('meta[name=csrf-token]').attributes['content'].value;
      var csrf_param = document.querySelector('meta[name=csrf-param]').attributes['content'].value;

      const data = {}
      data['locale'] = selectedOption.value
      data[csrf_param] = csrf_token

      Api.patch('/locales', data).then(() => {
        window.location.reload();
      })
    };

    toggleSubmenu = () => {
        this.submenu.classList.toggle('is-opened');
        this.submenu.classList.toggle('is-closed');
        this.burgerDesktop.classList.toggle('is-opened');
        this.burgerMobile.classList.toggle('is-opened');
    };

    toggleMenuVisibility = () => {
        if (window.location.pathname === '/') return;
        let currentScrollPos =
            window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollPos > lastScrollTop) {
            this.header.classList.add('is-hide');
        } else {
            this.header.classList.remove('is-hide');
        }
        lastScrollTop = currentScrollPos <= 0 ? 0 : currentScrollPos;
    };

    renderLanguage() {
      if (typeof window === 'undefined') return;

      const options = [
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Spain' }
      ];

      const { selectedOption } = this.state;

      return(
        <div className="header-menu__location">
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            components={{ DropdownIndicator }}
            placeholder="English"
            className="header-select"
            classNamePrefix="header-select"
            isSearchable={false}
          />
        </div>
      )
    }

    render() {
        return (
            <header className="header" ref={this.getHeaderRef}>
                <div className="container">
                    <div className="header__flex-wrap">
                        <div className="grid__sidebar flex-align-center">
                            <Link class="icon-link" to="/">
                                <img
                                    className="icon-logo"
                                    src={logo}
                                    width="111"
                                    height="20"
                                />
                            </Link>
                            <div className='mobile-header-select'>
                              {this.renderLanguage()}
                            </div>
                            <div className="j-burger">
                                <button
                                    className="btn btn__theme_burger btn-menu__mobile"
                                    onClick={this.toggleSubmenu}
                                    ref={this.getBurgerMobileRef}
                                >
                                    <Burger />
                                </button>
                            </div>
                        </div>
                        <div className="grid__content">
                            <div className="header-menu-wrap">
                                <div className="header-menu__links is-absolute">
                                    <div className="j-burger">
                                        <button
                                            className="btn btn__theme_burger btn-menu__desktop"
                                            onClick={this.toggleSubmenu}
                                            ref={this.getBurgerDesktopRef}
                                        >
                                            <Burger />
                                        </button>
                                    </div>
                                    <nav
                                        className="header-hidden-menu is-closed"
                                        ref={this.getSubmenuRef}
                                    >
                                        <Link
                                            to="/about"
                                            className="link link-header"
                                        >
                                            {this.props.t('header.about')}
                                        </Link>
                                        <Link
                                            to="/service"
                                            className="link link-header"
                                        >
                                          {this.props.t('header.service')}
                                        </Link>
                                    </nav>
                                    <nav className="header-menu header-menu__theme__home j-top-menu">
                                        <Link
                                            to="/products"
                                            className="link link-header cap-parent"
                                        >
                                              {this.props.t('header.products')}
                                            <span className="cap"></span>
                                        </Link>
                                        <Link
                                            to="/cases"
                                            className="link link-header cap-parent"
                                        >
                                            {this.props.t('header.blog')}
                                            <span className="cap"></span>
                                        </Link>
                                        <Link
                                            to="/for-distributors"
                                            className="link link-header cap-parent"
                                        >
                                            {this.props.t('header.forDistributors')}
                                            <span className="cap"></span>
                                        </Link>
                                        <Link
                                            to="/contacts"
                                            className="link link-header cap-parent"
                                        >
                                            {this.props.t('header.contacts')}
                                            <span className="cap"></span>
                                        </Link>
                                    </nav>
                                </div>
                                <div className="header-menu__icons is-absolute">
                                  {this.renderLanguage()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withTranslation()(Header);

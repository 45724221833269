import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import { Currency } from '../../partials/Currency'
import { Arrow } from '../../partials/Icons'
import { itemReavealDuration, itemReavealDelay } from '../../variables'
import { HashLink } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'

export const ProductAccessory = ({ data }) => {
    const [t] = useTranslation();
    return (
        <section className="section-screen">
            <div className="section-screen-center">
                <Fade
                    duration={itemReavealDuration}
                    delay={itemReavealDelay}
                    bottom
                >
                    <div className="flex-wrap flex-items-baseline">
                        <h2 className="h1">{t('products.featuredAccessories')}</h2>
                    </div>
                    <div className="accessory-items-wrap">
                        {data.map((item, index) => {
                            return (
                                <AccessoryItem
                                    {...item}
                                    key={`accessoryItem-${index}`}
                                />
                            )
                        })}
                    </div>
                    <div className="long-link-wrap">
                        <HashLink
                            to="/products#accessory"
                            className="link link__theme__home"
                        >
                            {t('see-more')}
                        </HashLink>
                    </div>
                </Fade>
            </div>
        </section>
    )
}

const AccessoryItem = ({ mainImage, name, slug, usdPrice, rubPrice }) => {
    return (
        <Link to={`/accessory/${slug}`} className="accessory-item cap-parent">
            <div className="cap" />
            <div className="accessory-item__bg" />
            <div className="accessory-item__img-wrap">
                <img
                    src={mainImage}
                    alt={name}
                    className="accessory-item__img"
                />
            </div>
            <div className="accessory-item__content-container">
                <div className="accessory-item__title-wrap">
                    <span className="accessory-item__title">{name}</span>
                    <span className="accessory-item__price">
                        <Currency
                            usdPrice={usdPrice}
                            rubPrice={rubPrice}
                            locale={null}
                        />
                    </span>
                </div>
                <div className="accessory-item__availability">
                    <span className="accessory-item__status">
                        <Arrow />
                    </span>
                </div>
            </div>
        </Link>
    )
}

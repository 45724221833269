import * as React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
    tags: {
        title: string
        description: string
        keywords: string
    }
}

const MetaTags = (props: Props) => {
    const { tags } = props
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{tags.title}</title>
            <meta name="description" content={tags.description} />
            <meta name="keywords" content={tags.keywords} />
            <meta property="og:title" content={tags.title} />
            <meta property="og:description" content={tags.description} />
            <meta property="og:site_name" content="vibrolaser.com" />
            <meta property="og:type" content="website" />
            <meta
                property="og:image"
                content="/logo-8aeda6192a98b855e9445b65b394a9a7.png"
            />
            <meta property="og:url" content="/" />
        </Helmet>
    )
}

export default MetaTags

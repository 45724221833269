import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const imagesContext = require.context("../../../media", true);

export const CasesItem = (props) => {
  const [t] = useTranslation();

  return (
    <div className="cases-item">
      <div className="cases-item__text-container">
        <span className="cases-item__tag">{props.data.industry}</span>
        <span className="cases-item__title">{props.data.name}</span>
        <p className="cases-item__anons">{props.data.description}</p>
        <Link
          className="link link__theme__home"
          to={`/cases/${props.data.slug}`}
        >
          {t('cases.checkItOut')}
        </Link>
      </div>
      <div className="cases-item__img-container">
        <img
          className="cases-item__img"
          src={props.data.image}
          alt={props.data.title}
        />
      </div>
    </div>
  );
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { debounce } from "lodash";
import MapAddresses from "../../partials/MapAddresses";
import Particles from "../../Particles";
import { Arrow, Triangle } from "../../partials/Icons";
import { laptop } from "../../variables";

import img1 from "../../../media/home/img1.png";
import img2 from "../../../media/home/img2.png";
import img3 from "../../../media/home/img3.png";
import img10 from "../../../media/home/img10.png";
import img11 from "../../../media/home/img11.png";
import img12 from "../../../media/home/img12.png";
import multiPlatformSolution from "media/advantages/multi_platform_solution.png";
import bestOfferOnTheMarket from "media/advantages/best_offer_on_the_market.png";
import internationalTeam from "media/advantages/international_team.png";
import remoteUpdate from "media/advantages/remote_update.png";
import resourceSavings from "media/advantages/resource_savings.png";
import weProvideTraining from "media/advantages/we_provide_training.png";
import warranty from "media/success/warranty.png";
import globe from "media/success/globe.png";
import countries from "media/success/countries.png";
import { withTranslation, Trans } from 'react-i18next';

let TouchEvents = null;
let TweenLite = null;
class ContentHome extends Component {
  constructor(props) {
    super(props);

    // For SSR
    if (typeof window !== 'undefined') {
      TouchEvents = require('../../TouchEvents').default;
      TweenLite = require('gsap').TweenLite;
    }

    this.state = {
      activeScreen: 0,
      screensQuantity: 6,
      isOnSliding: false,
      kits: this.props.data.kits.slice(0, 3),
      socials: this.props.data.settings.social_networks,
      settings: this.props.data.settings,
    };
  }

  componentDidMount() {
    this.menu = document.querySelector(".j-top-menu");

    this.setWindowResizeListeners();
    window.addEventListener("wheel", this.debouncedScrollListener);
    window.addEventListener("resize", this.setWindowResizeListeners);
  }

  componentWillUnmount() {
    TweenLite.to(this.menu, 0.6, { x: 0 });
    TweenLite.to(".j-burger", 0.6, { x: 0 });
    window.removeEventListener("wheel", this.debouncedScrollListener);
    window.removeEventListener("resize", this.setWindowResizeListeners);
  }

  getCarouselRef = (node) => (this.carousel = node);

  setWindowResizeListeners = () => {
    this.width = this.carousel.offsetWidth;
    TweenLite.to(this.carousel, 0.6, {
      x: 0,
      onComplete: () => {
        this.setState((prevState) => {
          return { activeScreen: 0, isOnSliding: false };
        });
        this.setActiveClass();
      },
    });
    TweenLite.to(".j-burger", 0.6, { x: 0 });

    if (window.innerWidth < laptop) {
      this.touches = new TouchEvents();
    }
  };

  carouselAnimation = (event) => {
    if (this.state.isOnSliding) return;

    if (event.deltaY < 0) {
      // скролл вверх
      if (this.state.activeScreen === 0) return;

      this.setState({ isOnSliding: true });

      if (this.state.activeScreen === this.state.screensQuantity) {
        TweenLite.to([this.menu, ".j-burger", ".header-hidden-menu"], 0.6, {
          x: 0,
        });
        // this.languageMenuItem.classList.remove("select_theme_white");
      }

      TweenLite.to(this.carousel, 0.6, {
        x: `+=${this.width}`,
        onComplete: () => {
          this.setState((prevState) => {
            return {
              activeScreen: prevState.activeScreen - 1,
              isOnSliding: false,
            };
          });
          this.setActiveClass();
        },
      });
    } else {
      // скролл вниз
      if (this.state.activeScreen === this.state.screensQuantity) return;

      this.setState({ isOnSliding: true });

      if (this.state.activeScreen === 0) {
        TweenLite.to(this.menu, 0.6, { x: 0 });
      }

      if (this.state.activeScreen === this.state.screensQuantity - 1) {
        TweenLite.to([this.menu, ".j-burger", ".header-hidden-menu"], 0.6, {
          x: -this.width,
        });
        // this.languageMenuItem.classList.add("select_theme_white");
      }

      TweenLite.to(this.carousel, 0.6, {
        x: `-=${this.width}`,
        onComplete: () => {
          this.setState((prevState) => {
            return {
              activeScreen: prevState.activeScreen + 1,
              isOnSliding: false,
            };
          });
          this.setActiveClass();
        },
      });
    }
  };

  moveCarousel = (event) => {
    if (window.innerWidth >= laptop) {
      this.carouselAnimation(event);
    }
  };

  debouncedScrollListener = debounce(this.moveCarousel, 100);

  setActiveClass = () => {
    const menu = document.querySelector(".j-top-menu");
    const activeMenuCaps = menu.querySelectorAll(".cap");
    const screens = document.querySelectorAll(".home-screen-item");
    const activeScreenCaps =
      screens[this.state.activeScreen].querySelectorAll(".cap");

    TweenLite.to(activeScreenCaps, 0.3, { width: 0, delay: 0.1 });
    TweenLite.to(activeMenuCaps, 0.3, { width: 0, delay: 0.1 });
  };

  renderSocials = () => {
    return this.state.socials.map((social) => {
      return (
        <li key={`sidebar-footer-social-${social.id}`}>
          <a
            href={social.url}
            className="sidebar__icon"
            rel="noindex, nofollow"
            target="_blank"
          >
            <ReactSVG src={social.icon} />
          </a>
        </li>
      );
    });
  };

  render() {
    const { settings, kits } = this.state;

    return (
      <React.Fragment>
        <main
          onTouchStart={(event) => this.touches.handleTouchStart(event)}
          onTouchMove={(event) => this.touches.handleTouchMove(event)}
          className="main"
        >
          <Particles />

          <section className="home-sidebar">
            <div className="sidebar__footer">
              <ul className="sidebar__socials">{this.renderSocials()}</ul>
              <address className="sidebar__address">
                {settings.address ? settings.address : null}
              </address>
            </div>
          </section>

          <section className="home-screen-carousel__wrap">
            <div className="home-screen-carousel" ref={this.getCarouselRef}>
              {/*item-1*/}
              <div className="home-screen-item">
                <h1 className="h1 h1__theme__home">
                  {this.props.seo.h1
                    ? this.props.seo.h1
                    : this.props.t('main.h1')}
                </h1>
                <p className="home-anons">
                  {this.props.t('main.home-anons')}
                </p>
                <Link to="/about" className="link link__theme__home">
                  {this.props.t('main.link-about')}
                </Link>
                <div className="home-sections-wrap">
                  <Link to="/products" className="home-section">
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img1})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.products-title')}</h3>
                      <span className="home-section__anons">
                        <Trans i18nKey="main.products-anons"/>
                      </span>
                    </div>
                  </Link>
                  <Link to="/cases" className="home-section" hidden>
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img2})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.cases-title')}</h3>
                      <span className="home-section__anons">
                      <Trans i18nKey="main.cases-anons"/>
                      </span>
                    </div>
                  </Link>
                  <Link to="/for-distributors" className="home-section">
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img3})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.distributors-title')}</h3>
                      <span className="home-section__anons">
                        <Trans i18nKey="main.distributors-anons"/>
                      </span>
                    </div>
                  </Link>
                </div>
              </div>

              {/*item-2*/}
              <div className="home-screen-item products">
                <h2 className="h1 h1__theme__home">
                  <span className="cap-parent is-blue">
                    {this.props.t('main.products.h1')}
                    <div className="cap"></div>
                  </span>
                </h2>
                <p className="home-anons cap-parent">
                  {this.props.t('main.products.anons')}
                  <span className="cap"></span>
                </p>
                <Link
                  to="/products"
                  className="link link__theme__home link__theme__see__more cap-parent"
                >
                  {this.props.t('see-more')}
                  <div className="cap"></div>
                </Link>
                <div className="home-sections-wrap">
                  {kits.map(KitTemplate)}
                  <Link to="/products" className="home-section__see-more">
                    {this.props.t('see-more')}
                    <Triangle />
                  </Link>
                </div>
              </div>

              {/*item-3*/}
              <div className="home-screen-item references">
                <h2 className="h1 h1__theme__home">
                  <span className="cap-parent is-blue">
                    {this.props.t('main.references.h1')}
                    <span className="cap"></span>
                  </span>
                </h2>
                <p className="home-anons cap-parent">
                  {this.props.t('main.references.anons')}
                  <span className="cap"></span>
                </p>
                <Link
                  to="/for-distributors#the-story"
                  className="link link__theme__home link__theme__see__more cap-parent"
                >
                  {this.props.t('see-more')}
                  <div className="cap"></div>
                </Link>
                <div className="home-sections-wrap">
                  <Link to="#" className="home-section cap-parent">
                    <div className="cap"></div>
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img10})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.references.cards.Enerflex.h3')}</h3>
                      <span className="home-section__anons">
                        <Trans i18nKey="main.references.cards.Enerflex.anons"/>
                      </span>
                    </div>
                  </Link>
                  <Link to="#" className="home-section cap-parent">
                    <div className="cap"></div>
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img11})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.references.cards.BarneyPumps.h3')}</h3>
                      <span className="home-section__anons">
                        <Trans i18nKey="main.references.cards.BarneyPumps.anons"/>
                      </span>
                    </div>
                  </Link>
                  <Link to="#" className="home-section cap-parent">
                    <div className="cap"></div>
                    <div className="home-section__bg-container">
                      <div
                        className="home-section__bg"
                        style={{ backgroundImage: `url(${img12})` }}
                      ></div>
                    </div>
                    <div className="home-section__content-container">
                      <h3 className="home-section__title">{this.props.t('main.references.cards.USAABank.h3')}</h3>
                      <span className="home-section__anons">
                        <Trans i18nKey="main.references.cards.USAABank.anons"/>
                      </span>
                    </div>
                  </Link>
                  <Link to="/products" className="home-section__see-more">
                    {this.props.t('see-more')}
                    <Triangle />
                  </Link>
                </div>
              </div>

              {/*item-4*/}
              <div className="home-screen-item">
                <h2 className="h1 h1__theme__home">
                  <span className="cap-parent is-blue">
                    {this.props.t('main.advantages.h2')}
                    <span className="cap"></span>
                  </span>
                </h2>
                <div className="home-cubes-wrap">
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.MultiPlatformSolution.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                      <Trans i18nKey="main.advantages.cards.MultiPlatformSolution.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img
                          className="illustrate"
                          src={multiPlatformSolution}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.WeProvideTraining.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.advantages.cards.WeProvideTraining.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={weProvideTraining} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.RemoteUpdate.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.advantages.cards.RemoteUpdate.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={remoteUpdate} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.ResourceSavings.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.advantages.cards.ResourceSavings.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={resourceSavings} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.InternationalTeam.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <div className="home-cube__text">
                        <Trans i18nKey="main.advantages.cards.InternationalTeam.text"/>
                      </div>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={internationalTeam} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.advantages.cards.BestOfferontheMarket.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.advantages.cards.BestOfferontheMarket.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img
                          className="illustrate"
                          src={bestOfferOnTheMarket}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*item-5*/}
              <div className="home-screen-item">
                <h2 className="h1 h1__theme__home">
                  <span className="cap-parent is-blue">
                    {this.props.t('main.SuccessisMeasurable.h1')}
                    <span className="cap"></span>
                  </span>
                </h2>
                <div className="home-cubes-wrap home-cubes-line">
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.SuccessisMeasurable.cards.IndustryLeading.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.SuccessisMeasurable.cards.IndustryLeading.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={warranty} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.SuccessisMeasurable.cards.NearlyaDecade.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.SuccessisMeasurable.cards.NearlyaDecade.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={globe} />
                      </div>
                    </div>
                  </div>
                  <div className="home-cube cap-parent">
                    <div className="cap"></div>
                    <span className="home-cube__title">
                      {this.props.t('main.SuccessisMeasurable.cards.countries.title')}
                    </span>
                    <div className="illustrate-wrap">
                      <p className="home-cube__text">
                        <Trans i18nKey="main.SuccessisMeasurable.cards.countries.text"/>
                      </p>
                      <div className="illustrate-cont">
                        <img className="illustrate" src={countries} />
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.seo.seoText && (
                  <p className="home-anons home-seotext">
                    {this.props.seo.seoText}
                  </p>
                )}
              </div>

              {/*item-6*/}
              <div className="home-screen-item screen__item__theme__map">
                <MapAddresses distributors={this.props.data.distributors} />
              </div>

              {/*item-7*/}
              <div className="home-screen-item screen__item__theme__footer">
                <footer className="home-screen-footer cap__theme__white">
                  <div className="home-screen-footer__row">
                    <div className="home-screen-footer__select-title home-screen-footer__width-space cap-parent">
                      <span className="cap-parent is-blue">
                        {this.props.t('footer.letsTolk')}
                        <span className="cap"></span>
                      </span>
                    </div>
                  </div>
                  <div className="home-screen-footer__row">
                    <nav className="home-screen-footer__navigation">
                      <Link to="/products" className="home-screen-footer__link">
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.products')}
                        </span>
                      </Link>
                      <Link to="/about" className="home-screen-footer__link">
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.about')}
                        </span>
                      </Link>
                      <Link to="/cases" className="footer__link">
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.articles')}
                        </span>
                      </Link>
                      <a
                        href="https://ervlusa.synology.me:5001/"
                        target="_blank"
                        className="footer__link"
                        rel="noindex, nofollow"
                      >
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.distributorPortal')}
                        </span>
                      </a>
                      <Link
                        to="/for-distributors"
                        className="home-screen-footer__link"
                      >
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.forDistributors')}
                        </span>
                      </Link>
                      <Link to="/service" className="home-screen-footer__link">
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.service')}
                        </span>
                      </Link>
                      <a
                        href="/docs/policy.pdf"
                        target="_blank"
                        className="footer__link"
                      >
                        <span className="cap-parent">
                          <span className="cap"></span>
                          {this.props.t('footer.privacy')}
                        </span>
                      </a>
                    </nav>
                  </div>
                  <div className="footer__row-copyright">
                    <div className="footer__address-wrap">
                      <ul className="footer__socials">
                        {this.state.socials.length
                          ? this.renderSocials()
                          : null}
                      </ul>
                      <address className="footer__address">
                        {settings.address ? settings.address : null}
                      </address>
                    </div>
                    <div className="footer__copyrights-wrap">
                      <div className="footer__copyright"> {this.props.t('footer.vibro')} {new Date().getFullYear()}</div>
                      <div className="footer__copyright">
                      {this.props.t('footer.rights')}
                      </div>
                      <div className="footer__copyright">
                        <a
                          href="http://molinos.ru"
                          className="footer__link-away"
                          target="_blank"
                          rel="noindex, nofollow"
                        >
                          {this.props.t('footer.madeIn')}
                        </a>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

const KitTemplate = ({ id, brief_description, slug, mainImage, name }) => {
  const descriptionParse = () => {
    if (brief_description) {
      return brief_description.split("\n").map((string, index) => {
        return (
          <span className="home-section__anons" key={`kit-anons-${index + id}`}>
            {string.slice(0, -1)}
          </span>
        );
      });
    }
  };

  return (
    <Link
      to={`/kit/${slug}`}
      className="home-section cap-parent"
      key={`kit-${id}`}
    >
      <div className="cap" />
      <div className="home-section__bg-container">
        <div
          className="home-section__bg"
          style={{ backgroundImage: `url(${mainImage})` }}
        />
      </div>
      <div className="home-section__content-container">
        <h3 className="home-section__title">{name}</h3>
        {descriptionParse()}
        <Arrow />
      </div>
    </Link>
  );
};

export default withTranslation()(ContentHome);

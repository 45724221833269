import React from 'react'
import { StaticRouter, BrowserRouter } from 'react-router-dom'
import Container from './partials/Container'
import Loader from './partials/Loader'
import logo from '../media/logos/logo.svg'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const App = ({
    locale,
    seo_tags,
    location,
    distributors,
    articles,
    products,
    accessories,
    kits,
    settings,
}) => {
    if (typeof window === 'undefined') {
        return (
          <I18nextProvider i18n={i18n}>
            <StaticRouter location={location}>
                <Container
                    location={location}
                    seo_tags={seo_tags}
                    distributors={distributors}
                    articles={articles}
                    products={products}
                    accessories={accessories}
                    kits={kits}
                    locale={locale}
                    settings={settings}
                />
                <div className="loader">
                    <div className="loader-logo">
                        <div className="loader-logo-cap"></div>
                        <img className="icon-logo__white" src={logo} />
                    </div>
                </div>
            </StaticRouter>
          </I18nextProvider>
        )
    } else {
        return (
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Container
                    location={location}
                    seo_tags={seo_tags}
                    distributors={distributors}
                    articles={articles}
                    products={products}
                    accessories={accessories}
                    kits={kits}
                    locale={locale}
                    settings={settings}
                />
                <Loader />
            </BrowserRouter>
          </I18nextProvider>
        )
    }
}

export default App

import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { itemReavealDuration, itemReavealDelay } from '../../variables'

class ProductSpecification extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            properties: this.props.data.properties,
        }
    }

    renderTableStroke = () => {
        return this.state.properties.map((property, index) => {
            return (
                <tr key={`property-${index}`}>
                    <td>{property.name}</td>
                    <td>{property.value}</td>
                </tr>
            )
        })
    }

    render() {
        const { properties } = this.state
        const { data } = this.props

        return (
            <section
                id="specification"
                className="section-screen j-anchor-container"
            >
                <div className="product-specification">
                    <div className="product-specification__left">
                        <Fade
                            duration={itemReavealDuration}
                            delay={itemReavealDelay}
                            bottom
                        >
                            <h2 className="h1">{this.props.t('products.sections.technicalData.h1')}</h2>
                            <table className="product-table">
                                <tbody>
                                    {properties
                                        ? this.renderTableStroke()
                                        : null}
                                </tbody>
                            </table>
                        </Fade>
                    </div>
                    {data.mainImage ? (
                        <Fade
                            duration={itemReavealDuration}
                            delay={itemReavealDelay}
                            bottom
                        >
                            <div className="product-specification__right">
                                <p>
                                    <img src={data.mainImage} />
                                </p>
                            </div>
                        </Fade>
                    ) : null}
                </div>
            </section>
        )
    }
}

export default withTranslation()(ProductSpecification);

import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const [t] = useTranslation();

  return (
    <section className="grid__sidebar grid__sidebar-main">
      <div className="sidebar__footer">
        <ul className="sidebar-list">
          <li>
            <AnchorLink
              offset="200"
              href="#case-about"
              className="sidebar-link j-anchor-link"
            >
              {t('cases.sidebarLinks.link1')}
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              href="#challenge"
              className="sidebar-link j-anchor-link"
            >
              {t('cases.sidebarLinks.link2')}
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              href="#case-results"
              className="sidebar-link j-anchor-link"
            >
              {t('cases.sidebarLinks.link3')}
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              href="#case-more"
              className="sidebar-link j-anchor-link"
            >
              {t('cases.sidebarLinks.link4')}
            </AnchorLink>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SideBar;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  es: {
    translation: {
      'localeLabel': 'Spain',
      'header': {
        'products': 'Productos',
        'about': 'Acerca de',
        'blog': 'Blog',
        'contacts': 'Contactos',
        'service': 'Servicios',
        'forDistributors': 'Para\u00a0distribuidores'
      },
      'breadcrumbs': {
        'main': 'Principal',
        'products': 'Productos',
        'about': 'Acerca de',
        'cases': 'Casos de artículos',
        'contacts': 'Contactos',
        'service': 'Servicios',
        'forDistributors': 'Para distribuidores'
      },
      'link': 'Link',
      'requestQeote': 'SOLICITE UNA COTIZACIÓN',
      'see-more': 'VER MÁS',
      'distributors_form': {
        'terms': 'términos & condiciones',
        'privacy': 'política de privacidad',
        'and': 'y',
        'inputs': {
          'name': {
            'label': 'Nombre',
            'validators': {
              'text-length': 'El nombre es demasiado corto'
            },
          },
          'country': {
            'label': 'País',
            'validators': {
              'text-length': 'El País de demasiado corto'
            },
          },
          'city': {
            'label': 'Ciudad',
          },
          'company': {
            'label': 'Nombre de la compañía',
            'validators': {
              'text-length': 'El nombre de la compañía es muy corto',
            },
          },
          'phone': {
            'label': 'Teléfono de contacto',
            'validators': {
              'phone-pattern': 'El número de teléfono no es válido'
            },
          },
          'email': {
            'label': 'Correo electrónico',
            'validators': {
              'email-required': 'El correo no es válido',
              'email-pattern': 'El correo no es válido',
            },
          },
          'os': {
            'label': 'Sistema operativo',
          },
          'software': {
            'label': 'Versión de software de VLSAT',
          },
          'sensors': {
            'label': 'Versión de sensores de VLMID, VLSID',
          },
          'message': {
            'label': 'Descripción',
          },
          'files': {
            'label': 'VIDEOS Y FOTOS DEL PROBLEMA',
          },
          'agreement': {
            'label': 'Sí. Acepto los términos y condiciones y la política de privacidad',
            'validators': {
              'terms-required': 'You must agree before submitting',
            },
          },
        },
        'types': {
          'customers': 'Para clientes',
          'distributors': 'Para distribuidores',
          'support': 'Soporte técnico'
        },
        'h1': "¡Construyamos juntos algo increíble!",
        'forCustomers': 'Para clientes',
        'forDistributors': 'Para distribuidores',
        'technicalSupport': 'Soporte técnico',
        'send': 'SEND enviar una solicitud',
        'response': {
          'h1': '¡Tu solicitud ha sido enviada con éxito!',
          'text': 'Le responderemos dentro de las 24 horas.'
        }
      },
      'footer': {
        'letsTolk': 'Hablemos',
        'products': 'Productos',
        'about': 'Acerca\u00a0de',
        'articles': 'Casos de artículos',
        'distributorPortal': 'Distributor Portal',
        'forDistributors': "Para\u00a0distribuidores",
        'service': 'Servicios',
        'privacy': 'Privacy Policy and License Agreement',
        'rights': 'Todos los Derechos Reservados',
        'madeIn': 'Made in Molinos',
        'vibro': 'VIBRO-LASER',
      },
      'main': {
        'h1': 'Desarrollamos los sistemas de medición de precisión más fáciles de usar del mundo que mejoran el rendimiento de sus activos',
        'home-anons': 'La innovación constante en la industria de la medición láser de precisión es nuestro único propósito ... y lo ofrecemos',
        'link-about': 'COME CLOSER',
        'products-title': 'Productos',
        'products-anons': "Ofrecemos el sistema de medición láser de precisión con más funciones del mundo por precio",
        'cases-title': 'Casos',
        'cases-anons': 'Hacemos que las grandes ideas cobren vida creando marcas que inspiran y elaborando productos que revolucionan',
        'distributors-title': 'Para distribuidores',
        'distributors-anons': 'Tenemos la intención de establecer asociaciones a largo plazo basadas en nuestro éxito mutuo.',
        'products': {
          'h1': 'Productos',
          'anons': 'Elija el mejor producto para que sus máquinas sean más eficientes y duren más'
        },
        'references': {
          'h1': 'Referencias',
          'anons': 'Que tienen que decir nuestros clientes',
          'cards': {
            'Enerflex': {
              'h3': 'Enerflex',
              'anons': 'Nunca debo preocuparme si mi equipo necesita servicio o soporte porque el equipo siempre está listo para ayudar'
            },
            'BarneyPumps': {
              'h3': 'Bombas Barney',
              'anons': 'Me encanta la filosofía multiplataforma, su sistema se puede enviar a cualquiera de mis técnicos en cualquier lugar'
            },
            'USAABank': {
              'h3': 'Banco USAA',
              'anons': 'El VLSAT asume las alineaciones de trenes de máquinas y cardán más complejas con facilidad'
            },
          }
        },
        'advantages': {
          'h2': 'Ventajas',
          'cards': {
            'MultiPlatformSolution': {
              'title': 'Solución multiplataforma',
              'text': 'Somos los primeros en ofrecer un sistema con todas las funciones que funciona en cualquier dispositivo iOS, Android o Windows.'
            },
            'WeProvideTraining': {
              'title': 'Proporcionamos entrenamiento',
              'text': 'Si bien sabemos que nuestro producto es fácil de usar. Si desea una formación avanzada, puede aprender de nosotros en nuestras instalaciones de Nueva York.',
            },
            'RemoteUpdate': {
              'title': 'Actualización remota',
              'text': 'Obtenga las últimas actualizaciones a través de una fácil descarga web o en las tiendas de aplicaciones. Agregue funciones con la misma facilidad simplemente actualizando su código de licencia'
            },
            'ResourceSavings': {
              'title': 'Ahorro de recursos',
              'text': 'Hemos acelerado el proceso de alineación para que sus máquinas sean más rentables y duren más'
            },
            'InternationalTeam': {
              'title': 'Equipo internacional',
              'text': 'Nuestro equipo está formado por algunas de las mentes líderes del mundo en alineación de precisión, monitoreo de condiciones, tecnología láser y espíritu empresarial.'
            },
            'BestOfferontheMarket': {
              'title': 'Mejor oferta del mercado',
              'text': 'Nuestros productos cumplen con los más altos estándares de calidad y equilibran el mejor precio y conjunto de características. ¡La tecnología se ha vuelto menos costosa!'
            },
          }
        },
        'SuccessisMeasurable': {
          'h1': 'El éxito es medible',
          'cards': {
            'IndustryLeading': {
              'title': 'Garantía de por vida líder en la industria',
              'text': 'El sistema debe calibrarse y revisarse anualmente para su aplicación',
            },
            'NearlyaDecade': {
              'title': 'Casi una década',
              'text': 'Experiencia global',
            },
            'countries': {
              'title': '57 países',
              'text': 'Nuestros Clientes',
            }
          }
        }
      },
      'map': {
        'title': 'Donde comprar',
        'switch_label': 'Mapa de su país',
        'autoComplete': {
          'label': 'Mapa de su país',
        }
      },
      'accessory': {
        'brochure': 'BROCHURE',
        'webQuickStart': 'WEB QUICK START',
        'manual': 'MANUAL',
        'catalog': 'Catálogo',
        'requestQeote': 'SOLICITE UNA COTIZACIÓN',
        'trustedBy': 'Con la confianza de los equipos de ingeniería en',
        'formTitle': '¡Construyamos juntos algo increíble!',
      },
      'products': {
        'h1': 'Productos',
        'address': '52 Main Street, Queensbury, NY 12804 USA',
        'intro_video': 'Intro video watch',
        'formTitle': '¡Construyamos juntos algo increíble!',
        'feature1': ' Utilice todas las funciones gratis durante 30 días',
        'feature2': ' Actualización simple de la clave de licencia',
        'featuredAccessories': 'Accesorios destacados',
        'sections': {
          'hawItWorks': {
            'h1': 'Intro video'
          },
          'whatInCase': {
            'h1': '¿Qué hay en la maleta de accesorios?'
          },
          'software': {
            'h1': 'Software',
          },
          'technicalData': {
            'h1': 'Datos técnicos'
          }
        },
        'left_links': {
          'equipment': 'Productos principales',
          'accessories': 'Accesorios para equipos',
          'intro_video': 'Intro video',
          'software': 'Software',
          'technicalData': 'Datos técnicos',
          'set': 'Set',
          'whereToBuy': 'Where to buy',
        },
      },
      'about': {
        'about': 'Acerca de',
        'VibroLaserIsember': 'VIBRO-LASER es miembro',
        'contacts_link': 'CONTACTS',
        'partners': 'Socios',
        'whatWedo': 'Que hacemos',
        'faces': 'Caras',
        'intro': { // TODO
          'h1': 'Siempre hay una mente humana detrás de nuestro producto',
          'text': 'Nos inspiran las nuevas ideas y las tecnologías innovadoras para hacer que los productos asombrosos cobren vida.',
        },
        'community': {
          'h1': 'Nosotros construimos la comunidad',
          'text1': 'En pocas palabras ... aspiramos a ser The Beatles of Precision Alignment. Nuestro equipo reúne a personas de ideas afines y les brinda a todos la oportunidad de crecer debido a sus conocimientos, habilidades y habilidades únicos. Cada miembro de nuestro equipo, desde el ingeniero hasta el director ejecutivo, es un profesional 100% comprometido con superar las expectativas de nuestros clientes todos los días',
          'text2': 'Nos inspiran las nuevas ideas y tecnologías innovadoras. Esto se refleja en nuestro trabajo y nuestros valores. Y al igual que las piezas de un rompecabezas, todos tenemos nuestro lugar y propósito.',
        },
        'whatWedoCards': {
          'card_1': {
            'title': 'Garantía de 2 años líder en la industria',
            'text': 'Garantizamos la calidad del producto y ofrecemos servicio, soporte y servicios de calibración en todo el mundo',
          },
          'card_2': {
            'title': 'Joven & Innovador',
            'text': 'Con la juventud viene el vigor, la innovación, el espíritu y la tenacidad. Y resolvemos algunos de los problemas de alineación más complejos del mundo con facilidad',
          },
          'card_3': {
            'title': 'Solución multiplataforma',
            'text': 'Somos los primeros en ofrecer un sistema con todas las funciones que funciona en cualquier dispositivo iOS, Android o Windows',
          },
          'card_4': {
            'title': 'Proporcionamos entrenamiento',
            'text': 'Si bien sabemos que nuestro producto es fácil de usar ... si desea capacitación avanzada, puede aprender de nosotros en nuestras instalaciones de Nueva York o de nuestros distribuidores en todo el mundo.',
          },
          'card_5': {
            'title': 'Soporte las 24 horas',
            'text': 'Independientemente de su zona horaria, recibirá la ayuda que necesite',
          },
          'card_6': {
            'title': 'Actualización remota',
            'text': 'Obtenga las últimas actualizaciones a través de una fácil descarga web o en las tiendas de aplicaciones. Agregue funciones con la misma facilidad simplemente actualizando su código de licencia para desbloquear nuevas funciones',
          },
        },
        'aboutValues': {
          'h1': 'Our values',
          'softwareSection': {
            'item1': 'Diferentes pero juntos',
            'item2': 'Libre para cooperar',
            'item3': 'Consciente del medio ambiente',
            'item4': 'Desarrollo personal & Pruebas de campo',
            'item5': 'Solución todo en uno',
            'item5': 'Ahorro de recursos',
          },
          'softwareContainer': {
            'item1': {
              'title': 'Diferentes pero juntos',
              'text': 'Creemos que nuestra empresa puede unir a todo tipo de personas. Nuestras diferencias son las que mejoran tanto nuestros productos como la experiencia del cliente. Ésta es nuestra fuerza.',
            },
            'item2': {
              'title': 'Libre para cooperar',
              'text': 'Nos asociamos con distribuidores, vendedores y clientes en todo el mundo. Contamos con un equipo internacional, clientes en los cinco continentes y una red de distribución mundial. Este es nuestro poder. Ésta es nuestra fuerza',
            },
            'item3': {
              'title': 'Consciente del medio ambiente',
              'text': 'Nuestras soluciones ayudan a ahorrar recursos para que podamos sostener nuestro planeta y salvarlo para las generaciones futuras. Ésta es nuestra fuerza',
            },
            'item4': {
              'title': 'Desarrollo personal & Pruebas de campo',
              'text': 'Siempre hay una mente humana detrás de nuestros productos. Desarrollamos nuestros productos en función de las necesidades y solicitudes de nuestros clientes ... lo que es más importante, entregamos más allá de las expectativas.',
            },
            'item5': {
              'title': 'Solución todo en uno',
              'text': 'Nuestros productos facilitan su trabajo. Nuestra interfaz de usuario es simple, pero elegante y lo guía a través de la alineación de precisión con facilidad. La alineación mejora el rendimiento de los equipos y tiene un impacto positivo general en los resultados de su organización.',
            },
            'item6': {
              'title': 'Ahorro de recursos',
              'text': 'Nuestro equipo ayuda a ahorrar tiempo y dinero. Hemos acelerado el proceso de alineación para que sus máquinas sean más eficientes y duren más'
            }
          }
        }
      },
      'cases': {
        'h1': 'Hacemos que las grandes ideas cobren vida',
        'text': 'Hacemos que las grandes ideas cobren vida creando marcas que inspiran y elaborando productos que revolucionan.',
        'products': 'PRODUCTOS',
        'formTitle': '¡Construyamos juntos algo increíble!',
        'checkItOut': 'CHECK IT OUT',
        'filters': {
          'products': 'Producto',
          'industries': 'Industria',
        },
        'sidebarLinks': {
          'link1': 'About the client',
          'link2': 'The challenge',
          'link3': 'Results',
          'link4': 'More cases',
        },
      },
      'contacts': {
        'formTitle': '¡Construyamos juntos algo increíble!',
      },
      'distributorsIntro': {
        'h1': 'Ayudas a los negocios a ser mejores',
        'intro': 'Para que podamos sostener nuestro planeta y salvarlo para las generaciones futuras.',
        'cards': {
          'card1': {
            'title': '5 años - maduro y siempre en evolución',
            'text': 'Somos una empresa joven, de rápido crecimiento y con metas ambiciosas'
          },
          'card2': {
            'title': 'Equipo Internacional',
            'text': 'Nuestro equipo está formado por algunas de las mentes líderes del mundo en alineación de precisión, monitoreo de condiciones, tecnología láser y espíritu empresarial'
          },
          'card3': {
            'title': 'Capacitación y soporte',
            'text': 'Organizamos capacitación y brindamos soporte técnico a nuestros socios 24/7/365'
          },
          'card4': {
            'title': 'Mejor oferta en el mercado',
            'text': '¡La tecnología se ha vuelto menos costosa y los usuarios finales lo saben!. Nuestros productos cumplen con los más altos estándares de calidad y equilibran el mejor precio y conjunto de características del mercado'
          },
          'card5': {
            'title': 'Condiciones Beneficiosas',
            'text': 'Tenemos la intención de establecer asociaciones a largo plazo basadas en la confianza mutua y nuestro éxito mutuo'
          },
          'card6': {
            'title': 'Soluciones innovadoras',
            'text': 'Desarrollamos productos que se adaptan a las necesidades del mercado moderno'
          },
        }
      },
      'distributors': {
        'requestQeote': 'SOLICITAR PRESUPUESTO',
        'formTitle': '¡Construyamos juntos algo increíble!',
        'aboutCompany': 'ABOUT COMPANY',
        'text1': 'Nuestros clientes valoran mucho los productos VIBRO-LASER.',
        'text2': 'Durante los últimos 5 años hemos duplicado nuestras ventas año tras año.',
        'sections': {
          'enterpreneur': {
            'h1': 'Cómo convertirse en un buen emprendedor',
            'item1': {
              'label': 'Cree en tu producto',
              'text': 'Nos ponemos en contacto contigo, negociamos condiciones y concluimos un acuerdo',
            },
            'item2': {
              'label': 'Ser honesto y valiente',
              'text': 'You start your business, we give some advice, invite you to the community and hold meetings',
            },
            'item3': {
              'label': 'No dejes de aprender',
              'text': 'Haces del mundo un lugar mejor y te pagan por ello. Mejoramos el producto para hacer felices a tus clientes',
            },
          },
          'howItWork': {
            'h1': 'Easy to\u00a0Start',
            'item1': {
              'label': 'Primer paso',
              'text': 'Rellene el formulario en la parte inferior de la pantalla',
            },
            'item2': {
              'label': 'Discusión',
              'text': 'Nos pondremos en contacto con usted para discutir nuestros intereses mutuos.',
            },
            'item3': {
              'label': 'Empezar',
              'text': 'Después de finalizar los términos, comenzamos nuestro proceso de incorporación de 30 días que asegura su éxito',
            },
          },
          'persons': [
            {
              'name': "Scott Jones",
              'avatar': "./distributors/scott-jones-2.jpg",
              'videoContent': {
                'previewSmall': "./product-card/video_prev-small.jpg",
                'preview': "/images/distributors/scott-jones-1.jpg",
                'src': "https://youtu.be/qD7b6qQxkyw",
              },
              'text': "Durante años, he tenido y usado los equipos de otros muchachos y comencé a buscar soluciones de energía que no rompieran el presupuesto",
              'experienceDate': "Scott Jones, Chief Executive Officer",
              'link': "https://www.linkedin.com/in/conditionmonitoringanalytics/",
              },
            {
              'name': "Ahmed El-Shafie",
              'avatar': "./distributors/ahmed-el-shafie-2.jpg",
              'videoContent': {
                'previewSmall': "./product-card/video_prev-small.jpg",
                'preview': "/images/distributors/ahmed-el-shafie-1.jpg",
                'src': "https://youtu.be/pjou6G7fogQ",
              },
              'text': "El cliente nos pidió que verificáramos el trabajo del otro contratista y terminamos haciendo correcciones adicionales porque aún no cumplían con las especificaciones",
              'experienceDate': "Ahmed El-Shafie, Marketing Manager",
              'link': "https://www.linkedin.com/in/ahmed-a-el-shafei-02800735/",
            },
          ],
        },
        'leftLinks': {
          'link1': 'The Story',
          'link2': 'Fácil de comenzar',
          'link3': 'Tips',
          'link4': 'Contactos',
        }
      },
      'service': {
        'text': 'Faced with difficulties? Perhaps, we have already solved your problem! Check our support video channel.',
        'formTitle': '¡Construyamos juntos algo increíble!',
        'watchVideo': 'WATCH VIDEO',
        'serviceCertifications': 'Service certifications',
        'products': 'PRODUCTOS',
        'quote': {
          'name': 'Steve Chwyl',
          'avatar': './distributors/steve-chwyl.jpg',
          'text': 'With Vibro-Laser you are never alone, just pick up the phone and they are there to support you',
          'experienceDate': 'Steve Chwyl, Rotating and Reliability Specialist',
          'link': 'https://www.linkedin.com/in/steve-chwyl-b5a37579',
        },
        'certificates': [
          {
            'logo': './service/wrestling.svg',
            'title': 'Calibration Certificate',
            'text': 'We guarantee product quality and offer service, support, and calibration services worldwide',
          },
          {
            'logo': './service/warranty.png',
            'title': 'Industry Leading 2-Year Warranty',
            'text': 'We guarantee product quality and offer a warranty which is valid all over the world',
          },
          {
            'logo': './service/resource_savings.png',
            'title': 'Lifetime Warranty',
            'text': 'System must be calibrated and serviced annually to apply',
          },
        ],
        'partners': [
          {
            'logo': './logos/enerflex.png',
            'text': '"The VIBRO-LASER team offers simply the best service and support of any laser alignment system company we have every used. I never need to worry if my team needs service or support because the team is always ready to help"',
            'person': 'Ricky B.',
          },
          {
            'logo': './logos/barneys-pumps.png',
            'text': '"I love the 3 operating system philosophy of VIBRO-LASER their system can be easily sent out to any of my technicians anywhere and with a simple app install the technicians are using the software and accurately aligning customer machines without issue"',
            'person': 'John C.',
          },
          {
            'logo': './logos/usaa.png',
            'text': '"Maintaining our campuses cooling systems is critical to our customers. The VLSAT takes on the most complex Cardan and Machine train alignments with ease."',
            'person': 'Randall D.',
          },
        ],
        'persons': [
          {
            'avatar': './distributors/klim.jpg',
            'videoContent': {
              'previewSmall': '',
              'preview': '',
              'src': '',
              'text': 'How our service works',
            },
            'text': 'We say no bureaucracy. This is why you can always contact us directly, send your feedback or ask questions. We value your opinion & trust in our products & our company.',
            'experienceDate': 'Klim, Chairman & CEO',
            'facebook': 'https://www.facebook.com/klim.kiforenko',
            'link': 'https://www.linkedin.com/',
          },
        ],
        'faq': {
          'tabs': {
            'tab1': 'PURCHASE',
            'tab2': 'SERVICE',
            'tab3': 'HOW TO USE',
          },
          'items': [
            [
              {
                question: "How can I buy a system if there is no representative in my country?",
                answer: "VIBRO-LASER is happy to sell directly if there is no representative in your area."
              },
              {
                question: "Can I trade-in my used competitor system when purchasing your system?",
                answer: "We are always happy to consider taking in a competitors equipment in on trade. They fit nicely in our dumpster."
              },
              {
                question: "How to purchase a paid software subscription?",
                answer: "Contact your local reseller or VIBRO-LASER directly to purchase a subscription license or feature license."
              },
              {
                question: "Is there any price difference between the iOS, Android, and Windows versions of VIBRO-LASER software?",
                answer: "No the software is the same across all operating systems thus maximizing the usability and insuring repeatability with VIBRO-LASER."
              }
            ],
            [
              {
                question: "How often should I calibrate?",
                answer: "We recommend annual calibration of your VIBRO-LASER device."
              },
              {
                question: "How often should I have my equipment serviced?",
                answer: "We recommend annual service which if you are part of our lifetime warranty program your annual calibration includes a full operational check and service as required."
              },
              {
                question: "Where do I service the system if there is no representative in my country?",
                answer: "VIBRO-LASER has service centers in North America, South America, and Russia."
              },
              {
                question: "Can I change my own battery?",
                answer: "VIBRO-LASER systems are built to the highest quality standards and should only be serviced by our factory or authorized service centers."
              }
            ],
            [
              {
                question: "Can I install VL software on my device?",
                answer: "Yes VL software can be installed on any modern Windows, iOS, or Android tablet."
              },
              {
                question: "How to add additional features?",
                answer: "Simply contact your local representative or VL directly to purchase license key unlock codes for any new features you want to purchase or short term rent."
              },
              {
                question: "Is it possible to use a system with several devices?",
                answer: "Yes In fact you can installed the software/application on as many devices as you would like however the system is only fully functional when the heads are connected."
              },
              {
                question: "Can I use the same system on different platforms?",
                answer: "Yes"
              },
              {
                question: "Is it possible to do geometric alignment with VIBRO-LASER?",
                answer: "We are actively working to add new and exciting features to the VLSAT platform. New features including Geometry are always under development."
              }
            ]
          ],
        },
      },
    }
  },
  en: {
    translation: {
      'localeLabel': 'English',
      'header': {
        'products': 'Products',
        'about': 'About',
        'blog': 'Blog',
        'contacts': 'Contacts',
        'service': 'Service',
        'forDistributors': 'For\u00a0distributors'
      },
      'breadcrumbs': {
        'main': 'Main',
        'products': 'Products',
        'about': 'About',
        'cases': 'Cases',
        'contacts': 'Contacts',
        'service': 'Service',
        'forDistributors': 'For distributors'
      },
      'link': 'Link',
      'requestQeote': 'REQUEST A QUOTE',
      'see-more': 'SEE MORE',
      'distributors_form': {
        'terms': 'terms & conditions',
        'privacy': 'privacy policy',
        'and': 'and',
        'inputs': {
          'name': {
            'label': 'Name',
            'validators': {
              'text-length': 'Name is too short'
            },
          },
          'country': {
            'label': 'Country',
            'validators': {
              'text-length': 'Сountry name is too short'
            },
          },
          'city': {
            'label': 'City',
          },
          'company': {
            'label': 'Company\'s name',
            'validators': {
              'text-length': 'Company\'s name is too short'
            },
          },
          'phone': {
            'label': 'Contact phone',
            'validators': {
              'phone-pattern': 'Phone number is not valid'
            },
          },
          'email': {
            'label': 'Email',
            'validators': {
              'email-required': 'Email is empty',
              'email-pattern': 'Email is not valid',
            },
          },
          'os': {
            'label': 'Os',
          },
          'software': {
            'label': 'Vibro-Laser Software version',
          },
          'sensors': {
            'label': 'Sensors version of VLMID, VLSID',
          },
          'message': {
            'label': 'Description',
          },
          'files': {
            'label': 'Video and photo of issue',
          },
          'agreement': {
            'label': 'Yes, I do. I agree to the',
            'validators': {
              'terms-required': 'You must agree before submitting',
            },
          },
        },
        'types': {
          'customers': 'For customers',
          'distributors': 'For Distributors',
          'support': 'Technical Support'
        },
        'h1': "Let's build something awesome together!",
        'forCustomers': 'For customers',
        'forDistributors': 'For Distributors',
        'technicalSupport': 'Technical Support',
        'send': 'SEND REQUEST',
        'response': {
          'h1': 'Your request has been sent successfully!',
          'text': 'We will answer you within 24 hours.'
        }
      },
      'footer': {
        'letsTolk': 'Let’s talk',
        'products': 'Products',
        'about': 'About\u00a0company',
        'articles': 'articles',
        'distributorPortal': 'Distributor Portal',
        'forDistributors': "For\u00a0distributors",
        'service': 'Service',
        'privacy': 'Privacy Policy and License Agreement',
        'rights': 'All rights reserved',
        'madeIn': 'Made in Molinos',
        'vibro': 'VIBRO-LASER',
      },
      'main': {
        'h1': 'We develop the world’s easiest to use precision measurement systems that improve your assets performance',
        'home-anons': 'Constant Innovation in the precision laser measurement industry is in our sole purpose … and we deliver it',
        'link-about': 'COME CLOSER',
        'products-title': 'Products',
        'products-anons': "We deliver the world’s most feature <br /> rich precision laser measurement <br /> system for the price",
        'cases-title': 'Cases',
        'cases-anons': "We make big ideas come to life by creating brands that inspire and crafting products that revolutionize",
        'distributors-title': 'For distributors',
        'distributors-anons': "We intend to establish long-term <br /> partnerships based upon <br /> our mutual success",
        'products': {
          'h1': 'Products',
          'anons': 'Choose the best product to make your machines more efficient and last longer'
        },
        'references': {
          'h1': 'References',
          'anons': 'What our Customers have to say',
          'cards': {
            'Enerflex': {
              'h3': 'Enerflex',
              'anons': 'I never need to worry if my team needs service or support because the team is always ready to help'
            },
            'BarneyPumps': {
              'h3': 'Barney Pumps',
              'anons': 'I love multi-platform philosophy their system <br /> can be sent out to any of my technicians anywhere'
            },
            'USAABank': {
              'h3': 'USAA Bank',
              'anons': 'The VLSAT takes on the most complex Cardan and Machine train alignments with easy'
            },
          }
        },
        'advantages': {
          'h2': 'Advantages',
          'cards': {
            'MultiPlatformSolution': {
              'title': 'Multi-Platform Solution',
              'text': 'We are the first to offer a full-featured system that works on any iOS, Android, or Windows device'
            },
            'WeProvideTraining': {
              'title': 'We Provide Training',
              'text': 'Get the latest updates via easy web download or the app stores. Add features just as easily by simply updating your license code',
            },
            'RemoteUpdate': {
              'title': 'Remote Update',
              'text': 'Get the latest updates via easy web download or the app stores. Add features just as easily by simply updating your license code'
            },
            'ResourceSavings': {
              'title': 'Resource Savings',
              'text': 'We have accelerated the alignment process to make your machines more cost-efficient and last longer'
            },
            'InternationalTeam': {
              'title': 'International Team',
              'text': 'Our team consists of some of the world’s leading minds in precision alignment, condition monitoring, laser technology, and entrepreneurship'
            },
            'BestOfferontheMarket': {
              'title': 'Best Offer on the Market',
              'text': 'Our products meet the highest quality standards and balance the best price and feature set. Technology has become less expensive!'
            },
          }
        },
        'SuccessisMeasurable': {
          'h1': 'Success is Measurable',
          'cards': {
            'IndustryLeading': {
              'title': 'Industry Leading Lifetime Warranty',
              'text': 'System must be calibrated and serviced annually to apply',
            },
            'NearlyaDecade': {
              'title': 'Nearly a Decade',
              'text': 'Global Experience',
            },
            'countries': {
              'title': '57 countries',
              'text': 'Our Customers',
            }
          }
        }
      },
      'map': {
        'title': 'Where to buy',
        'switch_label': 'Map',
        'autoComplete': {
          'label': 'Your country'
        }
      },
      'accessory': {
        'brochure': ' BROCHURE',
        'webQuickStart': ' WEB QUICK START',
        'manual': ' MANUAL',
        'catalog': 'Catalog',
        'trustedBy': 'Trusted by engereering teams at:',
        'formTitle': 'Let\'s build something awesome together!',
      },
      'products': {
        'h1': 'Products',
        'address': '52 Main Street, Queensbury, NY 12804 USA',
        'formTitle': 'Let\'s build something awesome together!',
        'feature1': ' Use all features for free 30 days',
        'feature2': ' Simple license key upgrade',
        'featuredAccessories': 'Featured accessories',
        'sections': {
          'hawItWorks': {
            'h1': 'Intro video'
          },
          'whatInCase': {
            'h1': 'What’s in case'
          },
          'software': {
            'h1': 'Software',
          },
          'technicalData': {
            'h1': 'Technical Data'
          }
        },
        'left_links': {
          'equipment': 'Equipment',
          'accessories': 'Accessories',
          'intro_video': 'Intro video',
          'software': 'Software',
          'technicalData': 'Technical data',
          'set': 'Set',
          'whereToBuy': 'Where to buy',
        },
      },
      'about': {
        'about': 'About',
        'VibroLaserIsember': 'VIBRO-LASER is a member',
        'contacts_link': 'CONTACTOS',
        'partners': 'Partners',
        'whatWedo': 'What we do',
        'faces': 'Faces',
        'intro': {
          'h1': 'There always is a human mind behind our products',
          'text': ' We are inspired by the new ideas and breakthrough technologies to make awesome products come to life.',
        },
        'community': {
          'h1': 'We\u00a0Build the Community',
          'text1': 'To\u00a0put it\u00a0simply...we aspire to\u00a0be\u00a0The Beatles of\u00a0Precision Alignment. Our team brings together like-minded people and gives everyone the opportunity to\u00a0grow given their unique knowledge, skills, and abilities. Each member of\u00a0our team, from engineer to\u00a0CEO, is\u00a0a\u00a0100% professional committed to\u00a0exceeding our customers&rsquo; expectations every day.',
          'text2': 'We\u00a0are inspired by\u00a0the new ideas and breakthrough technologies. This is\u00a0reflected in\u00a0our work and our values. And just like the pieces of\u00a0a\u00a0puzzle, we\u00a0all have our place and purpose.',
        },
        'whatWedoCards': {
          'card_1': {
            'title': 'Industry Leading 2-Year Warranty',
            'text': 'We\u00a0guarantee product quality and offer service, support, and calibration services worldwide',
          },
          'card_2': {
            'title': 'Young \u0026\u00a0Innovative',
            'text': ' With youth comes vigor, innovation, spirit, and tenacity. And we solve some of the  world’s most complex alignment problems with ease',
          },
          'card_3': {
            'title': 'Multi-Platform Solution',
            'text': 'We\u00a0are the first to\u00a0offer a\u00a0full-featured system that works on\u00a0any iOS, Android, or\u00a0Windows device',
          },
          'card_4': {
            'title': 'We\u00a0Provide Training',
            'text': 'While we\u00a0know our product is\u00a0easy-to-use...if you would like advanced training you can learn from\u00a0us in\u00a0our New York facility or\u00a0from our distributors across the globe',
          },
          'card_5': {
            'title': '24-Hour Support',
            'text': 'Regardless of\u00a0your time zone, you will receive any help you need',
          },
          'card_6': {
            'title': 'Remote Update',
            'text': 'Get the latest updates via easy web download or\u00a0the app stores. Add features just as\u00a0easily by\u00a0simply updating your license code to\u00a0unlock new features',
          },
        },
        'aboutValues': {
          'h1': 'Our values',
          'softwareSection': {
            'item1': 'Different Together',
            'item2': 'Free to\u00a0Cooperate',
            'item3': 'Environmentally Conscious',
            'item4': 'Personal Development \u0026\u00a0Field Tested',
            'item5': 'All-in-One Solution',
            'item6': 'Resource Savings',
          },
          'softwareContainer': {
            'item1': {
              'title': 'Different Together',
              'text': 'We\u00a0believe that our company can unite all kinds of\u00a0people. Our differences are what make both our products and the customer experience better. This is\u00a0our strength.',
            },
            'item2': {
              'title': 'Free to\u00a0Cooperate',
              'text': 'We\u00a0partner with distributors, resellers, and customers worldwide. We\u00a0have an\u00a0international team, clients on\u00a0five continents and a\u00a0worldwide distribution network. This is\u00a0our Power.',
            },
            'item3': {
              'title': 'Environmentally Conscious',
              'text': 'Our solutions help save resources so\u00a0that we\u00a0can sustain our planet and save it\u00a0for future generations. This strengthens\u00a0us.',
            },
            'item4': {
              'title': 'Personal Development \u0026\u00a0Field Tested',
              'text': 'There always is\u00a0a\u00a0human mind behind our products. We\u00a0develop our products based upon the needs and requests of\u00a0our customers...more importantly we\u00a0deliver above and beyond expectations.',
            },
            'item5': {
              'title': 'All-in-One Solution',
              'text': 'Our products make your work easier. Our user interface is\u00a0simple, yet elegant and walks you through precision alignment with ease. Alignment improves equipment performance and has an\u00a0overall positive impact on\u00a0the results of\u00a0your organization.',
            },
            'item6': {
              'title': 'Resource Savings',
              'text': 'Our equipment helps save both money and time. We\u00a0have accelerated the alignment process to\u00a0make your machines more efficient and last longer.'
            }
          }
        }
      },
      'cases': {
        'h1': 'We make big ideas come to life',
        'text': 'We make big ideas come to life by creating brands that inspire and crafting products that revolutionize.',
        'products': 'PRODUCTS',
        'formTitle': 'Let\'s build something awesome together!',
        'checkItOut': 'CHECK IT OUT',
        'filters': {
          'products': 'All products',
          'industries': 'All industries',
        },
        'sidebarLinks': {
          'link1': 'About the client',
          'link2': 'The challenge',
          'link3': 'Results',
          'link4': 'More cases',
        },
      },
      'contacts': {
        'formTitle': 'Let\'s build something awesome together!',
      },
      'distributorsIntro': {
        'h1': 'You help business be better',
        'intro': 'So that we can sustain our planet and save it for future generations.',
        'cards': {
          'card1': {
            'title': '5-Years\u00A0\u2014 Mature \u0026\u00A0Always Evolving',
            'text': 'We\u00A0are a\u00A0young, fast-growing company with ambitious goals'
          },
          'card2': {
            'title': 'International Team',
            'text': 'Our team consists of\u00A0some of\u00A0the world`s leading minds in\u00A0precision alignment, condition monitoring, laser technology, and entrepreneurship'
          },
          'card3': {
            'title': 'Training and Support',
            'text': 'We arrange training and provide technical support to our partners 24/7/365'
          },
          'card4': {
            'title': 'Best Offer on\u00A0the Market',
            'text': 'Technology has become less expensive and end users know\u00A0it!. Our products meet the highest quality standards and balance the best price and feature set on\u00A0the market'
          },
          'card5': {
            'title': 'Beneficial Conditions',
            'text': 'We\u00A0intend to\u00A0establish long-term partnerships based upon mutual trust and our mutual success'
          },
          'card6': {
            'title': 'Innovative Solutions',
            'text': 'We\u00A0develop products that suit the needs of\u00A0the modern market'
          },
        }
      },
      'distributors': {
        'requestQeote': 'REQUEST A QUOTE',
        'formTitle': 'Let\'s build something awesome together!',
        'aboutCompany': 'ABOUT COMPANY',
        'text1': 'Our clients highly value VIBRO-LASER products.',
        'text2': 'For the past 5\u00a0years we\u00a0have doubled our sales year-over-year.',
        'sections': {
          'enterpreneur': {
            'h1': 'How to become a good enterpreneur',
            'item1': {
              'label': 'Believe in your product',
              'text': 'We contact with you, negotiate conditions and conclude an agreement',
            },
            'item2': {
              'label': 'Be honest and brave',
              'text': 'You start your business, we give some advice, invite you to the community and hold meetings',
            },
            'item3': {
              'label': 'Don’t stop learning',
              'text': 'You make the world a better place and get paid for it. We improve the product to make your clients happy',
            },
          },
          'howItWork': {
            'h1': 'Easy to\u00a0Start',
            'item1': {
              'label': 'First step',
              'text': 'Fill out the form at\u00a0the bottom of\u00a0the screen',
            },
            'item2': {
              'label': 'Discussion',
              'text': 'We\u00a0will contact you to\u00a0discuss our mutual interests',
            },
            'item3': {
              'label': 'Get Started',
              'text': 'After finalizing terms we\u00a0start our 30\u00a0day onboarding process that insures your success',
            },
          },
          'persons': [
            {
              'name': "Scott Jones",
              'avatar': "./distributors/scott-jones-2.jpg",
              'videoContent': {
                'previewSmall': "./product-card/video_prev-small.jpg",
                'preview': "/images/distributors/scott-jones-1.jpg",
                'src': "https://youtu.be/qD7b6qQxkyw",
              },
              'text': "I’ve owned and used the other guys equipment " +
                "over the years and began looking for power " +
                "solutions that wouldn’t break a budget.",
              'experienceDate': "Scott Jones, Chief Executive Officer",
              'link': "https://www.linkedin.com/in/conditionmonitoringanalytics/",
              },
            {
              'name': "Ahmed El-Shafie",
              'avatar': "./distributors/ahmed-el-shafie-2.jpg",
              'videoContent': {
                'previewSmall': "./product-card/video_prev-small.jpg",
                'preview': "/images/distributors/ahmed-el-shafie-1.jpg",
                'src': "https://youtu.be/pjou6G7fogQ",
              },
              'text': "Client asked for us to verify the work of the other " +
                "contractor and we ended up doing additional corrections " +
                "as they were still out of spec.",
              'experienceDate': "Ahmed El-Shafie, Marketing Manager",
              'link': "https://www.linkedin.com/in/ahmed-a-el-shafei-02800735/",
            },
          ],
        },
        'leftLinks': {
          'link1': 'The Story',
          'link2': 'Easy to start',
          'link3': 'Tips',
          'link4': 'Contacts',
        }
      },
      'service': {
        'text': 'Faced with difficulties? Perhaps, we have already solved your problem! Check our support video channel.',
        'formTitle': 'Let\'s build something awesome together!',
        'watchVideo': 'WATCH VIDEO',
        'serviceCertifications': 'Service certifications',
        'products': 'PRODUCTS',
        'quote': {
          'name': 'Steve Chwyl',
          'avatar': './distributors/steve-chwyl.jpg',
          'text': 'With Vibro-Laser you are never alone, just pick up the phone and they are there to support you',
          'experienceDate': 'Steve Chwyl, Rotating and Reliability Specialist',
          'link': 'https://www.linkedin.com/in/steve-chwyl-b5a37579',
        },
        'certificates': [
          {
            'logo': './service/wrestling.svg',
            'title': 'Calibration Certificate',
            'text': 'We guarantee product quality and offer service, support, and calibration services worldwide',
          },
          {
            'logo': './service/warranty.png',
            'title': 'Industry Leading 2-Year Warranty',
            'text': 'We guarantee product quality and offer a warranty which is valid all over the world',
          },
          {
            'logo': './service/resource_savings.png',
            'title': 'Lifetime Warranty',
            'text': 'System must be calibrated and serviced annually to apply',
          },
        ],
        'partners': [
          {
            'logo': './logos/enerflex.png',
            'text': '"The VIBRO-LASER team offers simply the best service and support of any laser alignment system company we have every used. I never need to worry if my team needs service or support because the team is always ready to help"',
            'person': 'Ricky B.',
          },
          {
            'logo': './logos/barneys-pumps.png',
            'text': '"I love the 3 operating system philosophy of VIBRO-LASER their system can be easily sent out to any of my technicians anywhere and with a simple app install the technicians are using the software and accurately aligning customer machines without issue"',
            'person': 'John C.',
          },
          {
            'logo': './logos/usaa.png',
            'text': '"Maintaining our campuses cooling systems is critical to our customers. The VLSAT takes on the most complex Cardan and Machine train alignments with ease."',
            'person': 'Randall D.',
          },
        ],
        'persons': [
          {
            'avatar': './distributors/klim.jpg',
            'videoContent': {
              'previewSmall': '',
              'preview': '',
              'src': '',
              'text': 'How our service works',
            },
            'text': 'We say no bureaucracy. This is why you can always contact us directly, send your feedback or ask questions. We value your opinion & trust in our products & our company.',
            'experienceDate': 'Klim, Chairman & CEO',
            'facebook': 'https://www.facebook.com/klim.kiforenko',
            'link': 'https://www.linkedin.com/',
          },
        ],
        'faq': {
          'tabs': {
            'tab1': 'PURCHASE',
            'tab2': 'SERVICE',
            'tab3': 'HOW TO USE',
          },
          'items': [
            [
              {
                question: "How can I buy a system if there is no representative in my country?",
                answer: "VIBRO-LASER is happy to sell directly if there is no representative in your area."
              },
              {
                question: "Can I trade-in my used competitor system when purchasing your system?",
                answer: "We are always happy to consider taking in a competitors equipment in on trade. They fit nicely in our dumpster."
              },
              {
                question: "How to purchase a paid software subscription?",
                answer: "Contact your local reseller or VIBRO-LASER directly to purchase a subscription license or feature license."
              },
              {
                question: "Is there any price difference between the iOS, Android, and Windows versions of VIBRO-LASER software?",
                answer: "No the software is the same across all operating systems thus maximizing the usability and insuring repeatability with VIBRO-LASER."
              }
            ],
            [
              {
                question: "How often should I calibrate?",
                answer: "We recommend annual calibration of your VIBRO-LASER device."
              },
              {
                question: "How often should I have my equipment serviced?",
                answer: "We recommend annual service which if you are part of our lifetime warranty program your annual calibration includes a full operational check and service as required."
              },
              {
                question: "Where do I service the system if there is no representative in my country?",
                answer: "VIBRO-LASER has service centers in North America, South America, and Russia."
              },
              {
                question: "Can I change my own battery?",
                answer: "VIBRO-LASER systems are built to the highest quality standards and should only be serviced by our factory or authorized service centers."
              }
            ],
            [
              {
                question: "Can I install VL software on my device?",
                answer: "Yes VL software can be installed on any modern Windows, iOS, or Android tablet."
              },
              {
                question: "How to add additional features?",
                answer: "Simply contact your local representative or VL directly to purchase license key unlock codes for any new features you want to purchase or short term rent."
              },
              {
                question: "Is it possible to use a system with several devices?",
                answer: "Yes In fact you can installed the software/application on as many devices as you would like however the system is only fully functional when the heads are connected."
              },
              {
                question: "Can I use the same system on different platforms?",
                answer: "Yes"
              },
              {
                question: "Is it possible to do geometric alignment with VIBRO-LASER?",
                answer: "We are actively working to add new and exciting features to the VLSAT platform. New features including Geometry are always under development."
              }
            ]
          ],
        },
      },
    },
  },
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    resources,
    supportedLngs: ['en', 'es', 'ru'],

    detection: {
      lookupQuerystring: 'locale',
      lookupCookie: 'locale',
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });


export default i18n;

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { throttle } from 'lodash'
import { Footer } from '../../partials/Footer'
import {
    Arrow,
    Eye,
    Facebook,
    Instagram,
    LinkedIn,
    Youtube,
} from '../../partials/Icons'
import ItemReveal from '../../ItemReveal'
import {
    easeName,
    animationDuration,
    laptop,
    itemReavealDuration,
} from '../../variables'
import Slide from 'react-reveal/Slide'
import { Helmet } from 'react-helmet'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withTranslation, WithTranslation } from 'react-i18next'
const Reveal = new ItemReveal()
let gsap = null
let TouchEvents = null

const groupBy = (arr, criteria) => {
    return arr.reduce(function (obj, item) {
        // Check if the criteria is a function to run on the item or a property of it
        var key =
            typeof criteria === 'function' ? criteria(item) : item[criteria]

        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
            obj[key] = []
        }

        // Push the value to the object
        obj[key].push(item)

        // Return the object to the next item in the loop
        return obj
    }, {})
}

interface Props extends WithTranslation {
    data: {
        name: string
        kits: any[]
        distributors: any[]
        settings: any
        products: any[]
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

interface State {
    allProducts: any[]
    activeTypes: string[]
}
class Shop extends PureComponent<Props, State> {
    title: string
    filter: any
    sidebar: any
    touches: any
    constructor(props) {
        super(props)

        this.state = {
            allProducts: this.props.data.products,
            activeTypes: ['kit', 'accessory'],
        }

        if (typeof window !== 'undefined') {
            gsap = require('gsap')
            TouchEvents = require('../../TouchEvents').default
        }
    }

    componentDidUpdate() {
        const scrollToAnchor = () => {
            const hash =
                document.URL.split('#').length > 1
                    ? document.URL.split('#')[1]
                    : null

            if (hash) {
                document.querySelector(`#${hash}`).scrollIntoView()
            }
        }
        scrollToAnchor()
    }

    componentDidMount() {
        this.addEvents()
        Reveal.init()
        window.addEventListener('resize', this.addEvents)
    }

    componentWillUnmount() {
        document.body.classList.remove('no-scroll')

        if (window.innerWidth >= laptop) {
            this.removeDebouncedListener()
        }
        window.removeEventListener('resize', this.addEvents)
        Reveal.removeListener()
    }

    getTitleRef = (node) => (this.title = node)
    getFilterRef = (node) => (this.filter = node)
    getSidebarRef = (node) => (this.sidebar = node)

    addEvents = () => {
        gsap.TweenLite.to(this.title, animationDuration, {
            x: this.sidebar.clientWidth,
            ease: easeName,
        })

        if (window.innerWidth < laptop) {
            this.touches = new TouchEvents()
            document.body.classList.remove('no-scroll')
            window.removeEventListener('wheel', this.debouncedScrollListener)
        } else {
            document.body.classList.add('no-scroll')
            window.addEventListener('wheel', this.debouncedScrollListener)
        }
    }

    removeDebouncedListener = () => {
        window.removeEventListener('wheel', this.debouncedScrollListener)
    }

    moveTitle = (event) => {
        if (document.body.classList.contains('no-scroll') && event.deltaY > 0) {
            // скролл вниз
            gsap.TweenLite.to('.j-breadcrumbs', animationDuration, {
                opacity: 0,
                ease: easeName,
            })
            gsap.TweenLite.to(this.filter, animationDuration, {
                opacity: 1,
                ease: easeName,
            })
            gsap.TweenLite.to(this.title, animationDuration, {
                x: 0,
                ease: easeName,
                onComplete: () => {
                    document.body.classList.remove('no-scroll')
                    window.scrollTo({
                        top: 150,
                        behavior: 'smooth',
                    })
                },
            })
            gsap.TweenLite.to('.sidebar__footer', animationDuration, {
                opacity: 0,
                ease: easeName,
            })
        }

        if (
            !document.body.classList.contains('no-scroll') &&
            window.pageYOffset < 150 &&
            event.deltaY < 0
        ) {
            // скролл вверх
            gsap.TweenLite.to('.j-breadcrumbs', animationDuration, {
                opacity: 1,
                ease: easeName,
            })
            gsap.TweenLite.to(this.filter, animationDuration, {
                opacity: 0,
                ease: easeName,
            })
            gsap.TweenLite.to(this.title, animationDuration, {
                x: this.sidebar.clientWidth,
                ease: easeName,
                onComplete: () => {
                    document.body.classList.add('no-scroll')
                },
            })
            gsap.TweenLite.to('.sidebar__footer', animationDuration, {
                opacity: 1,
                ease: easeName,
            })
        }
    }

    debouncedScrollListener = throttle(this.moveTitle, 400)

    filteritemTypes = (type) => {
        this.setState((prevState) => {
            if (prevState.activeTypes.includes(type)) {
                // Проверка на удаление/добавление
                return {
                    activeTypes: [
                        ...prevState.activeTypes.filter((tag) => tag !== type),
                    ],
                }
            } else {
                // Проверяем, если это последний нажатый элемент
                return { activeTypes: [...prevState.activeTypes, type] }
            }
        })
    }

    renderItems = (item, index) => {
        if (this.state.activeTypes.includes(item.type)) {
            return (
                <ShopItem
                    id={item.id}
                    type={item.type}
                    link={item.slug}
                    title={item.name}
                    image={item.mainImage}
                    status={<Arrow />}
                    anons={item.description}
                    key={`shopItem-${index}`}
                />
            )
        }
    }

    render() {
        const groupedData = groupBy(this.state.allProducts, 'type')

        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:url" content="/products" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <main
                    className="main container flex-nowrap main__theme_margin_top"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <section
                        className="grid__sidebar grid__sidebar-main"
                        ref={this.getSidebarRef}
                    >
                        <h1
                            className="h1 h1__theme_fixed"
                            ref={this.getTitleRef}
                        >
                            {this.props.seo.h1 ? this.props.seo.h1 : this.props.t('products.h1')}
                        </h1>
                        <ul className="shop-filter" ref={this.getFilterRef}>
                            {/* comment/uncomment depends on dehaviour needed */
                            /* Buttons for scroll to section */}
                            <li>
                                <AnchorLink
                                    offset="200"
                                    href="#kit"
                                    className="shop-filter__btn-scroll"
                                >
                                    {this.props.t('products.left_links.equipment')}
                                </AnchorLink>
                            </li>
                            <li>
                                <AnchorLink
                                    href="#accessory"
                                    className="shop-filter__btn-scroll"
                                >
                                  {this.props.t('products.left_links.accessories')}
                                </AnchorLink>
                            </li>
                            {/* Buttons for filter sections */}
                            {/* <li>
                                <button
                                    className={`shop-filter__btn ${
                                        this.state.activeTypes.includes('kit')
                                            ? 'is-active'
                                            : ''
                                    }`}
                                    onClick={() => this.filteritemTypes('kit')}
                                >
                                    <Eye /> Equipment
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`shop-filter__btn ${
                                        this.state.activeTypes.includes(
                                            'accessory'
                                        )
                                            ? 'is-active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        this.filteritemTypes('accessory')
                                    }
                                >
                                    <Eye /> Accessories
                                </button>
                            </li> */}
                        </ul>
                        <div className="sidebar__footer">
                            <ul className="sidebar__socials">
                                <li>
                                    <a href="#" className="sidebar__icon">
                                        <Facebook />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="sidebar__icon">
                                        <LinkedIn />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="sidebar__icon">
                                        <Youtube />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="sidebar__icon">
                                        <Instagram />
                                    </a>
                                </li>
                            </ul>
                            <address className="sidebar__address">
                              {this.props.t('products.address')}
                            </address>
                        </div>
                    </section>
                    <section className="grid__content">
                        <nav
                            className="breadcrumbs breadcrumbs_theme_fixed j-breadcrumbs"
                            itemScope
                            itemType="https://schema.org/BreadcrumbList"
                        >
                            <Link
                                to="/"
                                className="breadcrumb-link"
                                itemProp="itemListElement"
                                itemScope
                                itemType="https://schema.org/ListItem"
                            >
                                <span itemProp="name">{this.props.t('breadcrumbs.main')}</span>
                            </Link>
                            <span className="breadcrumb__current">
                                <span itemProp="name">{this.props.t('breadcrumbs.products')}</span>
                            </span>
                        </nav>

                        <div className="shop-items-wrap">
                            {Object.keys(groupedData).map((key) => (
                                <section
                                    className="shop-items-types"
                                    key={key}
                                    id={key}
                                >
                                    {groupedData[key].map(this.renderItems)}
                                </section>
                            ))}
                        </div>
                        {this.props.seo.seoText && (
                            <p className="shop-seotext">
                                {this.props.seo.seoText}
                            </p>
                        )}
                    </section>
                </main>
                <Footer settings={this.props.data.settings} />
            </React.Fragment>
        )
    }
}

export const ShopItem = ({ id, link, type, title, image, status, anons }) => {
    const renderAnons = () => {
        if (anons) {
            return anons.split('\n').map((string, index) => {
                return (
                    <span key={`shopitem-anons-${index + id}`}>{string}</span>
                )
            })
        }
    }

    return (
        <Link to={`/${type}/${link}`} className="shop-item">
            <Slide duration={itemReavealDuration} bottom>
                <div className="shop-item__wrap">
                    <div className="shop-item__layer" />
                    <div className="shop-item__content">
                        <div
                            className="shop-item__img-wrap"
                            style={{ backgroundImage: `url( ${image} )` }}
                        ></div>
                        <span className="shop-item__title">{title}</span>
                        <div className="shop-item__anons">{renderAnons()}</div>
                        {typeof status === 'string' ? (
                            <div className="shop-item__availability">
                                <span className="shop-item__status">
                                    {status}
                                </span>
                            </div>
                        ) : (
                            status
                        )}
                    </div>
                </div>
            </Slide>
        </Link>
    )
}

export default withTranslation()(Shop);

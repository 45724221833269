export const parseOnlyLetterAndSpace = expression =>
  expression.replace(/[^A-Za-z ]/g, "");

export const parseLength = (expression, length) =>
  expression.substring(0, length);

export const checkAtLeastLength = (expression, length) =>
  expression && expression.trim().length >= length;

export const checkIsfilled = expression => expression && expression.length > 0;

export const checkIsTrue = expression => expression;

export const checkEmailPattern = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const checkPhonePattern = phone => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  return regex.test(phone)
}

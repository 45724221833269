import React, { PureComponent } from 'react'
import { ReactSVG } from 'react-svg'
import Fade from 'react-reveal/Fade'
import { laptop, itemReavealDuration } from '../../variables'
import VideoPopup from '../../VideoPopup'
import { Triangle } from '../../partials/Icons'
import { withTranslation } from 'react-i18next'

const firstItem = 0

class ProductSoftware extends PureComponent {
    componentDidMount() {
        this.init()
        window.addEventListener('resize', this.setBlockClass)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setBlockClass)
    }

    listItems = []
    contentItems = []
    getListItemRefs = (node) => {
        this.listItems.push(node)
    }
    getContentItemRefs = (node) => {
        this.contentItems.push(node)
    }
    getSoftwareContainerRef = (node) => (this.softwareContainer = node)

    init = () => {
        this.setBlockClass()
        this.listItems.forEach((item) => {
            item.addEventListener('mouseover', () => {
                this.setActiveItem(item.dataset.softwareItem)
            })
        })
        setTimeout(() => {
            this.setActiveItem(this.listItems[firstItem].dataset.softwareItem)
        }, 800)
    }

    setBlockClass = () => {
        const blockClass =
            window.innerWidth < laptop ? 'is-initial' : 'is-absolute'

        this.contentItems.forEach((item) => {
            item.classList.remove('is-initial')
            item.classList.remove('is-absolute')
            item.classList.add(blockClass)
        })
    }

    setActiveItem = (id) => {
        this.listItems.forEach((item) => {
            item.classList.remove('is-active')

            if (item.dataset.softwareItem === id) {
                item.classList.add('is-active')
            }
        })
        this.contentItems.forEach((item) => {
            item.classList.remove('is-active')

            if (item.id === `content-${id}`) {
                item.classList.add('is-active')
                this.softwareContainer.style.minHeight = `${item.offsetHeight}px`
            }
        })
    }

    itemListTemplate = (data) => {
        return data.map(({ id, icon }) => {
            return (
                <li
                    className="software-item"
                    data-software-item={id}
                    key={`item-list-${id}`}
                    ref={this.getListItemRefs}
                >
                    <ReactSVG
                        src={icon}
                        beforeInjection={(svg) =>
                            svg.classList.add('icon-software')
                        }
                    />
                </li>
            )
        })
    }

    itemContentTemplate = (data) => {
        return data.map(({ id, name, description, youtube }) => {
            return (
                <div
                    id={`content-${id}`}
                    className={`software-content`}
                    key={`item-content-${id}`}
                    ref={this.getContentItemRefs}
                >
                    <div className="software-content__left-part">
                        <span className="h2 software-content__title">
                            {name}
                        </span>
                        {description}
                    </div>
                    <div className="software-content__right-part">
                        {youtube.src ? <VideoPopup content={youtube} /> : null}
                    </div>
                </div>
            )
        })
    }

    render() {
        const { data } = this.props

        return (
            <section
                id="software"
                className="section-screen j-anchor-container"
            >
                <Fade duration={itemReavealDuration} bottom>
                    <div className="section-screen-center">
                        <h2 className="h1">{this.props.t('products.sections.software.h1')}</h2>
                        <div className="software-section">
                            <div className="software-items-wrap">
                                <ul className="software-items">
                                    {this.itemListTemplate(data)}
                                </ul>
                                <div className="software-slider">
                                    <Triangle />
                                    <Triangle />
                                </div>
                            </div>
                            <div
                                className="software-container"
                                ref={this.getSoftwareContainerRef}
                            >
                                {this.itemContentTemplate(data)}
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
        )
    }
}

export default withTranslation()(ProductSoftware);

import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import ItemReveal from '../../ItemReveal'
import { Footer } from '../../partials/Footer'
import { laptop, itemReavealDuration, gMapsStyle } from '../../variables'
import AutoComplete from '../../partials/Autocomplete'
import { Helmet } from 'react-helmet'
import { DistributorsForm } from '../distributors/DistributorsForm'
import { withTranslation, WithTranslation } from 'react-i18next'

const Reveal = new ItemReveal()

interface Props extends WithTranslation {
    data: {
        name: string
        kits: any[]
        distributors: any[]
        settings: any
        articles: any[]
    }
    match: any
    seo: {
        h1: string
        seoText: string
        description: string
    }
}

interface State {
    distributors: any[]
    countries: any[]
    center: { lat: number; lng: number }
    zoom: number
}
class Contacts extends PureComponent<Props, State> {
    touches: any
    constructor(props) {
        super(props)

        const data = this.props.data.distributors

        const distributors = data

        let countries = []

        distributors.map((distributor) => {
            if (countries.includes(distributor.country)) return
            countries.push(distributor.country)
        })

        countries.sort()

        this.state = {
            distributors: distributors,
            countries: countries,
            center: { lat: 43.300562, lng: -73.667922 },
            zoom: 2,
        }
    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            const TouchEvents = require('../../TouchEvents').default

            if (window.innerWidth < laptop) {
                this.touches = new TouchEvents()
            }

            Reveal.init()
            this.setMap()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.center !== this.state.center) {
            this.setMap()
        }
    }

    componentWillUnmount() {
        Reveal.removeListener()
    }

    setMap = () => {
        const gMaps = require('google-maps')
        gMaps.KEY = 'AIzaSyA7DyHhK6TG_bJ9Bla5oQkjzqMWnxk4aTg'


        gMaps.load(async (google) => {
            const map = await new google.maps.Map(
                document.getElementById('map'),
                {
                    center: this.state.center,
                    zoom: this.state.zoom,
                    styles: gMapsStyle,
                    disableDefaultUI: true,
                }
            )

            const infowindow = new google.maps.InfoWindow({})

            this.state.distributors.map((distributor) => {
                const marker = new google.maps.Marker({
                    position: {
                        lat: distributor.latitude,
                        lng: distributor.longitude,
                    },
                    icon: '/map_elements/map-marker.svg',
                    title: distributor.address,
                })

                marker.addListener('click', () => {
                    infowindow.setContent(distributor.address)
                    infowindow.open(map, marker)
                })

                marker.setMap(map)
            })
        })
    }

    setCenter = (center) => {
        this.setState({
            center,
        })
    }

    setZoom = (zoom) => {
        this.setState({
            zoom,
        })
    }

    searchCountry = (input) => {
        const { countries, distributors } = this.state
        const request = input.toString()
        const country = countries.find((c) => c === request)
        const distributor = distributors.find(
            (distributor) => distributor.country === country
        )
        if (distributor) {
            const center = {
                lat: distributor.latitude,
                lng: distributor.longitude,
            }
            this.setCenter(center)
            this.setZoom(4)
            this.setMap()
        }
    }

    MapContainer = () => {
        if (typeof window === 'undefined') {
            return <></>
        }

        return (
            <>
                <div className="map-contacts-wrap">
                    <div className="map-contacts-search">
                        <AutoComplete
                            suggestions={this.state.countries}
                            changed={this.searchCountry}
                        />
                    </div>
                    <div
                        id="map"
                        className="map-container__theme__contacts"
                    ></div>
                </div>
                <p className="contacts-seotext">
                    {this.props.seo.seoText && this.props.seo.seoText}
                </p>
            </>
        )
    }

    render() {
        const { distributors, countries } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:url" content="/contacts" />
                </Helmet>
                <main
                    className="main"
                    onTouchStart={(event) =>
                        this.touches.handleTouchStart(event)
                    }
                    onTouchMove={(event) => this.touches.handleTouchMove(event)}
                >
                    <div className="container flex-nowrap main__theme_margin_top">
                        <section className="grid__sidebar grid__sidebar-main"></section>
                        <section className="grid__content grid__content-main">
                            <Fade duration={itemReavealDuration} bottom>
                                <nav
                                    className="breadcrumbs"
                                    itemScope
                                    itemType="https://schema.org/BreadcrumbList"
                                >
                                    <Link
                                        to="/"
                                        className="breadcrumb-link"
                                        itemProp="itemListElement"
                                        itemScope
                                        itemType="https://schema.org/ListItem"
                                    >
                                        <span itemProp="name">{this.props.t('breadcrumbs.main')}</span>
                                    </Link>
                                    <span
                                        className="breadcrumb__current"
                                        itemProp="itemListElement"
                                        itemScope
                                        itemType="https://schema.org/ListItem"
                                    >
                                        <span itemProp="name">{this.props.t('breadcrumbs.contacts')}</span>
                                    </span>
                                </nav>
                                <div className="contacts-intro">
                                    <div className="contacts-intro__column">
                                        <h1 className="h1">
                                            {this.props.seo.h1
                                                ? this.props.seo.h1
                                                : 'United States'}
                                        </h1>
                                    </div>
                                </div>
                                <DistributorsForm options={{
                                              title: this.props.t('contacts.formTitle'),
                                              additionalClassName: null }
                                            }
                                />
                            </Fade>

                            <Fade duration={itemReavealDuration}>
                                {this.MapContainer()}
                            </Fade>
                            <h2 className="h1">We are around the world!</h2>
                            <div className="contacts-addresses">
                                {countries.length ? (
                                    <DistributorInfo
                                        countries={countries}
                                        distributors={distributors}
                                    />
                                ) : null}
                            </div>
                        </section>
                    </div>
                </main>
                <Footer settings={this.props.data.settings} />
            </React.Fragment>
        )
    }
}

export const DistributorInfo = ({ countries, distributors }) => {
    const cityTemplate = (country) => {
        return distributors.map((distributor, index) => {
            if (distributor.country === country) {
                return (
                    <>
                        <li className="contacts__distributor" key={index}>
                            {distributor.mainImage && (
                                <img
                                    className="contacts__distributor__logo"
                                    src={distributor.mainImage}
                                />
                            )}
                            <span className="contacts__distributor__name">
                                {distributor.name}
                            </span>
                            <span className="contacts__distributor__contact-person">
                                {distributor.contact_person}
                            </span>
                            <span className="contacts__distributor__address">
                                {distributor.address}
                            </span>
                            <span className="contacts__distributor__phone">
                                <a
                                    className={'link link__theme__color__blue'}
                                    href={`tel:${distributor.phone}`}
                                >
                                    {distributor.phone}
                                </a>
                            </span>
                            <span className="contacts__distributor__email">
                                <a
                                    className={'link link__theme__color__blue'}
                                    href={`mailto:${distributor.email}`}
                                >
                                    {distributor.email}
                                </a>
                            </span>
                            <span className="contacts__distributor__website">
                                <a
                                    className={'link link__theme__color__blue'}
                                    target="_blank"
                                    href={distributor.website}
                                >
                                    Website
                                </a>
                            </span>
                        </li>
                    </>
                )
            }
        })
    }

    const countryTemplate = () => {
        return countries.map((country, index) => {
            return (
                <div className="contacts__country" key={`country-${index}`}>
                    <span className="contacts__country-title">{country}</span>
                    <ul className="contacts__country-list">
                        {cityTemplate(country)}
                    </ul>
                </div>
            )
        })
    }

    return countryTemplate()
}

const MapContainer = () => {}

export default withTranslation()(Contacts);

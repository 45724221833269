import React, { useState, useEffect } from 'react'
import { Triangle } from './partials/Icons'

export type Option = {
    id: number
    value: string
    label?: string
    active?: boolean
}

interface Props {
    onSelect: (string) => void
    name: string
    label: string
    value: string
    defaultValue: string
    options: Option[]
}

export const Select = (props: Props) => {
    const [selectorOpened, setSelectorOpened] = useState(false)
    const [options, setOptions] = useState([])
    const { onSelect, name, label, value, defaultValue } = props

    useEffect(() => {
        props.options.map((option) => {
            option.active = false
        })
        if (!value && defaultValue) {
            props.options.find(
                (option) => option.value == defaultValue
            ).active = true
        } else {
            props.options[0].active = true
        }
        setOptions(props.options)
    }, [])

    useEffect(() => {
        if (options.length) {
            const newOptions = [...options]
            newOptions.map((o) => {
                o.active = false
                if (o.value === value) {
                    o.active = true
                }
            })
            setOptions(newOptions)
        }
    }, [value])

    const toggleSelectorOpened = () => {
        setSelectorOpened(!selectorOpened)
    }

    const selectHandler = (optionValue) => {
        const newOptions = [...options]
        newOptions.map((o) => {
            o.active = false
            if (o.value === optionValue) {
                o.active = true
                onSelect(o)
            }
        })
        setOptions(newOptions)
        setSelectorOpened(false)
    }

    return (
        <div className="form-custom-select">
            <span className="form-custom-select__label">{label}</span>
            <div
                className="form-custom-select__selected"
                onClick={toggleSelectorOpened}
            >
                {options.map((option, i) => {
                    if (option.active == true) {
                        return (
                            <div
                                className={
                                    selectorOpened
                                        ? 'form-custom-select__selected__option active'
                                        : 'form-custom-select__selected__option'
                                }
                                key={i}
                                onClick={toggleSelectorOpened}
                            >
                                {option.label}
                            </div>
                        )
                    }
                })}
                <Triangle />
            </div>
            <div
                className={
                    selectorOpened
                        ? 'form-custom-select__to-select active'
                        : 'form-custom-select__to-select'
                }
            >
                {options.map((option, i) => {
                    if (option.active == false) {
                        return (
                            <div
                                className="form-custom-select__to-select__option"
                                key={i}
                                onClick={() => selectHandler(option.value)}
                            >
                                {option.label}
                            </div>
                        )
                    }
                })}
            </div>
            <select className="form-select large-font hidden" name={name}>
                {options.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

import React from 'react'
import VideoPopup from '../../VideoPopup'
import Fade from 'react-reveal/Fade'
import { itemReavealDuration, itemReavealDelay } from '../../variables'
import { useTranslation } from 'react-i18next'

export const ProductHowItWorks = ({ data }) => {
  const [t] = useTranslation();
  return (
    <section id="howItWorks" className="section-screen j-anchor-container">
        <Fade duration={itemReavealDuration} delay={itemReavealDelay} bottom>
            <h2 className="h1">{t('products.sections.hawItWorks.h1')}</h2>
        </Fade>
        <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
            <VideoPopup content={data} />
        </Fade>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import VideoPopup from "../../VideoPopup";
import { itemReavealDuration, itemReavealDelay } from "../../variables";
import { useTranslation } from "react-i18next";
const imagesContext = require.context("../../../media", true);

const videoContent = {
  previewSmall: "/examples/case-video-example.jpg",
  preview: "/examples/case-video-example.jpg",
  src: "https://youtu.be/eB-pgWAc8sg",
};

export const CaseCardIntro = (props) => {
  const [t] = useTranslation();
  return (
    <React.Fragment>
      <section className="section-screen">
        <Fade duration={itemReavealDuration} bottom>
          <nav className="breadcrumbs">
            <Link to="/" className="breadcrumb-link">
              {t('breadcrumbs.main')}
            </Link>
            {/* <Link to="/cases" className="breadcrumb-link">Case studies</Link> */}
            <span className="breadcrumb__current">{props.case.name}</span>
          </nav>
          <div className="cases-intro">
            <div className="cases-intro__text-container">
              <h1 className="h1">
                {props.seo.title ? props.seo.title : props.case.name}
              </h1>
              <p className="cases-intro__text">{props.case.description}</p>
            </div>
            <div className="cases-intro__info-container">
              <div className="cases-intro__info-row">
                <span className="cases-intro__info-label">Client</span>
                <span className="cases-intro__info-value">
                  {props.case.client}
                </span>
              </div>
              <div className="cases-intro__info-row">
                <span className="cases-intro__info-label">Industry</span>
                <span className="cases-intro__info-value">
                  {props.case.industry}
                </span>
              </div>
              <div className="cases-intro__info-row">
                <span className="cases-intro__info-label">Equipment</span>
                <span className="cases-intro__info-value">
                  {props.case.equipment}
                </span>
              </div>
            </div>
          </div>
        </Fade>
        <Fade duration={itemReavealDuration} delay={itemReavealDelay}>
          {/* <VideoPopup content={videoContent} /> */}
          {props.case.image && (
            <img src={props.case.image} alt={props.case.title} />
          )}
        </Fade>
      </section>
    </React.Fragment>
  );
};

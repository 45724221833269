import React, { PureComponent } from "react";
export default class ErrorsHandler extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <h1>HHHHHHHHHHHHHHHAAAAAAAAAAAAAAAAAAAAA</h1>
      </React.Fragment>
    );
  }
}

import React from 'react'
import Fade from 'react-reveal/Fade'

import { itemReavealDuration } from '../../variables'

import Support from 'media/about/support.png'
import YoungAndInnovative from 'media/about/young_and_Innovative.png'
import IndustryLeading from 'media/about/industry_leading.png'
import RemoteUpdate from 'media/about/remote_update.png'
import WeProvideTraining from 'media/about/we_provide_training.png'
import MultiPlatformSolution from 'media/about/multi_platform_solution.png'
import { useTranslation } from "react-i18next";

export const AboutWeDo = ({ seo }) => {
  const [t] = useTranslation();

  return (
    <>
        <section className="section-screen">
            <div className="section-screen-center">
                <Fade duration={itemReavealDuration} bottom>
                    <span className="h1">{t('about.whatWedo')}</span>
                    <div className="distributors-cubes-wrap">
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                                {t('about.whatWedoCards.card_1.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_1.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img
                                        className="illustrate"
                                        src={IndustryLeading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                              {t('about.whatWedoCards.card_2.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_2.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img
                                        className="illustrate"
                                        src={YoungAndInnovative}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                              {t('about.whatWedoCards.card_3.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_3.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img
                                        className="illustrate"
                                        src={MultiPlatformSolution}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                              {t('about.whatWedoCards.card_4.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_4.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img
                                        className="illustrate"
                                        src={WeProvideTraining}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                              {t('about.whatWedoCards.card_5.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_5.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img className="illustrate" src={Support} />
                                </div>
                            </div>
                        </div>
                        <div className="distributors-cube cap-parent">
                            <div
                                className={
                                    typeof window === 'undefined' ? null : 'cap'
                                }
                            ></div>
                            <span className="distributors-cube__title">
                              {t('about.whatWedoCards.card_6.title')}
                            </span>
                            <div className="illustrate-wrap">
                                <p className="distributors-cube__text">
                                  {t('about.whatWedoCards.card_6.text')}
                                </p>
                                <div className="illustrate-cont">
                                    <img
                                        className="illustrate"
                                        src={RemoteUpdate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    </>
  )
}
